<!-- PAGE TITLE
    ================================================== -->
<header class="py-2 py-md-4 main-banner-in1 overlay overlay-primary overlay-70 jarallax" data-jarallax data-speed=".8"
  style="background-image: url(assets/images/);">
  <div class="rts-bread-crumbarea-1 rts-section-gap bg_image">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb-main-wrapper">
            <h1 class="title">My Profile</h1>

            <div class="pagination-wrapper">
              <a routerLink="/index">Home</a>
              <i class="fa-regular fa-chevron-right"></i>
              <a class="active" href="contact.html">My Profile</a>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Img -->

</header>

<!-- PRICING
    ================================================== -->
<div class="container-fluid my-8">
  <div class="row">
    <div class="col-md-4 col-xl-3 mb-6">


      <div class="nav flex-column nav-vertical border rounded px-6 py-4" id="v-pills-tab" role="tablist"
        aria-orientation="vertical">

        <div class="d-flex align-items-center">
          <div class="border rounded-circle d-inline-block mb-4 mb-md-0 me-4">
            <div class="p-1">
              <img src="assets/img/avatars/avatar-1.jpg" alt="..." class="rounded-circle" width="52" height="52">
            </div>
          </div>

          <div class="mb-4 mb-md-0">
            <a href="blog-single.html" class="d-block">
              <h6 class="mb-0">{{ name }} {{ surname }}</h6>
            </a>
            <span class="font-size-sm">{{ email }}</span>
          </div>
        </div>
        <!-- <a class="nav-link" id="v-pills-dashboard-tab" data-bs-toggle="pill" href="#v-pills-dashboard"
                        role="tab" aria-controls="v-pills-dashboard" aria-selected="false"><i
                            class="fas fa-chart-line text-dark"></i> Dashboard</a> -->

        <!-- <a class="nav-link" [routerLink]="['/my-profile']"><i class="fas fa-chart-line text-dark"></i> Dashboard</a> -->

        <a class="nav-link active" id="v-pills-profile-tab" data-bs-toggle="pill" href="#v-pills-profile" role="tab"
          aria-controls="v-pills-profile" aria-selected="true"><i class="fas fa-user"></i> Edit
          Profile</a>

        <a class="nav-link" id="v-pills-change-pass-tab" data-bs-toggle="pill" href="#v-pills-change-pass" role="tab"
          aria-controls="v-pills-change-pass" aria-selected="true"><i class="fas fa-user"></i>
          Change Password</a>


        <!-- <a class="nav-link" id="v-pills-subscription-tab" data-bs-toggle="pill" href="#v-pills-subscription" role="tab"
          aria-controls="v-pills-subscription" aria-selected="false"><i class="fas fa-hand-point-up"></i> My
          Subscriptions</a> -->


        <a class="nav-link" id="v-pills-reviews-tab" (click)="myReviews()" data-bs-toggle="pill" href="#v-pills-reviews"
          role="tab" aria-controls="v-pills-reviews" aria-selected="false"><i class="fas fa-star"></i> My
          Reviews</a>

        <!-- <a class="nav-link" id="v-pills-wishlist-tab" (click)="myWishlist()" data-bs-toggle="pill"
          href="#v-pills-wishlist" role="tab" aria-controls="v-pills-wishlist" aria-selected="false"><i
            class="fas fa-heart"></i>
          Wishlist</a> -->
      </div>
    </div>
    <div class="col-md-8 col-xl-9">
      <div class="tab-content" id="v-pills-tabContent">
        <!-- Dashborard Start -->
        <div class="tab-pane fade" id="v-pills-dashboard" role="tabpanel" aria-labelledby="v-pills-dashboard-tab">
          <h3 class="">1. Terms</h3>
          <p class="mb-6">Do you want to become a UI/UX designer but you don't know where to start? This
            course will allow you to develop your user interface design skills and you can add UI
            designer to your CV and start getting clients for your skills.</p>


        </div>
        <!-- Dashboard ENd -->
        <!-- Edit Profile tab Start -->
        <div class="tab-pane fade show active" id="v-pills-profile" role="tabpanel"
          aria-labelledby="v-pills-profile-tab">



          <!-- Edit Profile
                            ================================================== -->
          <div class="col-md-12 col-xl-12 mx-auto">
            <!-- Prfile -->


            <!-- Form Profile -->

            <form class="mb-5 bg-white border border-gray-200 p-5" [formGroup]="editProfile" (ngSubmit)="EditProfile()">

              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group mb-5">
                    <label for="modalSigninusername">
                      First Name
                    </label>
                    <input type="text" formControlName="name" name="name"
                      class="form-control form-control-sm border border-dark" id="modalSigninusername" placeholder="">
                    <div *ngIf="editProfile.controls['name'].invalid && editProfile.controls['name'].touched">
                      First Name is required
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group mb-5">
                    <label for="modalSigninusername">
                      Last Name
                    </label>
                    <input type="text" formControlName="surname" name="surname"
                      class="form-control form-control-sm border border-dark" id="modalSigninusername" placeholder="">
                    <div *ngIf="editProfile.controls['surname'].invalid && editProfile.controls['surname'].touched">
                      Last Name is required
                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col-12 col-md-6">
                  <!-- Email -->
                  <div class="form-group mb-5">
                    <label for="email">
                      Email
                    </label>
                    <input type="text" formControlName="email" name="email"
                      class="form-control form-control-sm border border-dark" id="email" placeholder="">
                    <div *ngIf="editProfile.get('email')?.invalid && editProfile.get('email')?.touched">
                      <span class="text-danger" *ngIf="editProfile.get('email')?.errors?.required">Email is
                          required.</span>
                      <span class="text-danger" *ngIf="editProfile.get('email')?.errors?.pattern">Email must
                          contain '&#64;' and end with '.com'.</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <!-- Mobile No -->
                  <div class="form-group mb-5">
                    <label for="modalSigninphone">
                      Mobile No
                    </label>
                    <input type="text" maxlength="10" formControlName="phoneNumber" name="phoneNumber"
                      class="form-control form-control-sm border border-dark" id="modalSigninphone" placeholder="">
                    <div
                    *ngIf="editProfile.get('phoneNumber')?.invalid && editProfile.get('phoneNumber')?.touched">
                    <span class="text-danger"
                        *ngIf="editProfile.get('phoneNumber')?.errors?.required">Number is
                        required.</span>
                    <span class="text-danger"
                        *ngIf="editProfile.get('phoneNumber')?.errors?.pattern">Only numbers are
                        allowed.</span>
                    <span class="text-danger"
                        *ngIf="editProfile.get('phoneNumber')?.errors?.maxlength">Maximum length is
                        10.</span>
                    <span class="text-danger"
                        *ngIf="editProfile.get('phoneNumber')?.errors?.minlength">Manimum length is
                        10.</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Submit -->
              <div class="d-flex justify-content-end">
                <button class="rts-btn btn-primary btn-sm btn-wide text-right " type="submit">
                  Submit
                </button>

              </div>

            </form>

            <!-- Text -->
            <!-- <p class="mb-0 font-size-sm text-center">
                                Already have an account? <a class="text-underline forgot" href="login.html">Log In</a>
                            </p> -->
          </div>


        </div>
        <!-- Profile tab end -->


        <!-- Change Password tab Start -->
        <div class="tab-pane fade" id="toggle_pwd" id="v-pills-change-pass" role="tabpanel"
          aria-labelledby="v-pills-change-pass-tab">



          <!-- Form Login -->
          <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()"
            class="mb-5 bg-white border border-gray-200 p-5">
            <!-- Email -->
            <div class="form-group mb-5">
              <label for="modalSigninEmail1">
                Old Password
              </label>

              <input [type]="showpassword ? 'text' : 'password'" formControlName="currentPassword" id="currentPassword" name="currentPassword"
                class="form-control form-control-sm border border-dark" placeholder="">
              <span id="toggle_pwdold" style="cursor: pointer;" class="fa fa-fw fa-eye field-icon"  (click)="displayPassWord()"></span>
              <div
                *ngIf="changePasswordForm.controls['currentPassword'].invalid && changePasswordForm.controls['currentPassword'].touched">
                Current Password is required
              </div>
            </div>

            <!-- Password -->
            <div class="form-group mb-5">
              <label for="modalSigninPassword1">
                New Password
              </label>
              <input [type]="shownewpassword ? 'text' : 'password'" formControlName="newPassword" id="newPassword" name="newPassword"
                class="form-control form-control-sm border border-dark" placeholder="">
              <span id="toggle_pwdnew" style="cursor: pointer;" class="fa fa-fw fa-eye field-icon" (click)="displayNewPassWord()"></span>
              <div
                *ngIf="changePasswordForm.controls['newPassword'].invalid && changePasswordForm.controls['newPassword'].touched">
                New Password is required
              </div>
            </div>

            <!-- Password -->
            <!-- <div class="form-group mb-5">
                <label for="modalSigninPassword1">
                  Confirm Password
                </label>
                <input type="password" formControlName="newPassword2" id="newPassword2" name="newPassword2"
                  class="form-control form-control-sm" placeholder="">
                  <span id="toggle_pwdconfirm" style="cursor: pointer;" class="fa fa-fw fa-eye field-icon"></span>
              </div> -->

            <!-- Submit -->
            <div class="d-flex justify-content-end">
              <button class="rts-btn btn-primary btn-sm btn-wide text-right " type="submit">
                Submit
              </button>

            </div>
          </form>


        </div>
        <!-- Change Password tab end -->

        <!-- Subscription Start -->
        <div class="tab-pane fade" id="v-pills-subscription" role="tabpanel" aria-labelledby="v-pills-subscription-tab">
          <h3 class="">My Subscription</h3>
          <ul class="list-unstyled mb-0">
            <ng-container *ngIf="subscription">
              <ng-container *ngFor="let s of subscription;let i=index">
                <li class="media mb-6 d-flex">
                  <a [routerLink]="['/lesson-details/'+ s.courseId.toString() +'']" class="w-100p d-block me-5">
                    <img src="{{ apiURL }}/{{ s.bannerImg }}" alt="..." class="avatar-img rounded-lg h-90p w-100p">
                  </a>
                  <div class="media-body flex-grow-1">
                    <a href="course-single-v5.html" class="d-block">
                      <h6 class="line-clamp-2 mb-1">{{s.courseName}}</h6>
                    </a>

                    <ins class="h6 mb-0 ">₹{{s.price}}</ins>
                    <a [routerLink]="['/lesson-details/'+ s.courseId.toString() +'']"
                      class="line-clamp-2 mb-3 badge badge-lg badge-dark text-white w-100p fw-normal ">Start
                      Course</a>
                  </div>
                </li>
              </ng-container>
            </ng-container>
            <!-- <li class="media mb-6 d-flex">
                <a href="course-single-v5.html" class="w-100p d-block me-5">
                  <img src="assets/img/photos/photo-2.jpg" alt="..." class="avatar-img rounded-lg h-90p w-100p">
                </a>
                <div class="media-body flex-grow-1">
                  <a href="course-single-v5.html" class="d-block">
                    <h6 class="line-clamp-2 mb-1">Advance Import Export Tutorial</h6>
                  </a>

                  <ins class="h6 mb-0 ">₹415.99</ins>
                  <a href="#" class="line-clamp-2 mb-3 badge badge-lg badge-dark text-white w-100p fw-normal ">Start
                    Course</a>
                </div>
              </li> -->



          </ul>
        </div>
        <!-- Subscription End -->
        <!-- Review Start for Course -->
        <div class="tab-pane fade" id="v-pills-reviews" role="tabpanel" aria-labelledby="v-pills-reviews-tab">
          <h3 class="">My Reviews:Course</h3>

          <ul class="list-unstyled pt-2">
            <ng-container *ngIf="reviews">
              <ng-container *ngFor="let r of reviews.courseComments;let i=index">

                <li class="media d-flex">
                  <div class="avatar avatar-xxl me-3 me-md-6 flex-shrink-0">
                    <img src="{{ apiURL }}/{{ r.bannerImg }}" alt="..." class="avatar-img rounded-circle">
                  </div>
                  <div class="media-body flex-grow-1">
                    <div class="d-md-flex align-items-center mb-5">
                      <div class="me-auto mb-4 mb-md-0">
                        <h5 class="mb-0">{{r.courseName}}</h5>
                        <p class="font-size-sm font-italic mb-0">{{r.categoryName}}</p>
                        <!-- <p class="font-size-sm font-italic">8 Month ago</p> -->
                      </div>

                      <div class="star-rating">

                        <div class="rating" [style.width.%]="(r.commentStar/5)*100"></div>
                      </div>
                    </div>
                    <p class="mb-6 line-height-md" [innerHTML]="r.commentReview"></p>
                  </div>

                </li>
              </ng-container>
            </ng-container>
          </ul>
          <hr>

          <!-- Video Reviews -->
          <h3 class="">My Reviews:Videos</h3>

          <ul class="list-unstyled pt-2">
            <ng-container *ngIf="reviews">
              <ng-container *ngFor="let v of reviews.videoComments;let i=index">

                <li class="media d-flex">
                  <div class="avatar avatar-xxl me-3 me-md-6 flex-shrink-0">
                    <img src="{{ apiURL }}/{{ v.bannerImg }}" alt="..." class="avatar-img rounded-circle">
                  </div>
                  <div class="media-body flex-grow-1">
                    <div class="d-md-flex align-items-center mb-5">
                      <div class="me-auto mb-4 mb-md-0">
                        <h5 class="mb-0">{{v.courseName}}</h5>
                        <p class="font-size-sm font-italic mb-0">{{v.categoryName}}</p>
                        <!-- <p class="font-size-sm font-italic">8 Month ago</p> -->
                      </div>

                      <div class="star-rating">

                        <div class="rating" [style.width.%]="(v.commentStar/5)*100"></div>
                      </div>
                    </div>
                    <p class="mb-6 line-height-md" [innerHTML]="v.commentReview"></p>
                  </div>

                </li>
              </ng-container>
            </ng-container>
          </ul>

        </div>

        <!-- Review End -->
        <!-- Wishlist Start -->

        <div class="tab-pane fade" id="v-pills-wishlist" role="tabpanel" aria-labelledby="v-pills-wishlist-tab">
          <h3 class="">Wishlist</h3>
          <div class="row row-cols-md-2 row-cols-xl-3 row-cols-wd-4 row-cols-lg-3 mb-6 mb-xl-3">
            <ng-container *ngFor="let w of wishlist;let i=index">
              <ng-container *ngFor="let c of w.mainCourse;let i=index">
                <div class="col-md pb-4 pb-md-7" data-aos="fade-in">
                  <!-- Card -->
                  <div class="card border shadow p-2 lift sk-fade">
                    <!-- Image -->
                    <div class="card-zoom position-relative">
                      <div class="badge-float sk-fade-top top-0 right-0 mt-4 me-4">
                        <a href="course-single.html"
                          class="btn btn-xs btn-dark text-white rounded-circle lift opacity-dot-7 me-1 p-2 d-inline-flex justify-content-center align-items-center w-36 h-36">
                          <!-- Icon -->
                          <i class="far fa-eye"></i>

                        </a>
                        <a href="course-single.html"
                          class="btn btn-xs btn-dark text-white rounded-circle lift opacity-dot-7 p-2 d-inline-flex justify-content-center align-items-center w-36 h-36">
                          <!-- Icon -->
                          <i class="far fa-heart"></i>

                        </a>
                      </div>

                      <a href="course-single.html" class="card-img sk-thumbnail d-block">
                        <img class="rounded shadow-light-lg" src="{{ apiURL }}/{{ c.bannerImg }}" alt="...">
                      </a>

                      <span
                        class="badge sk-fade-bottom badge-lg badge-primary badge-pill badge-float bottom-0 left-0 mb-4 ms-4">
                        <span class="text-white text-uppercase fw-bold font-size-xs">BEST
                          SELLER</span>
                      </span>
                    </div>

                    <!-- Footer -->
                    <div class="card-footer px-2 pb-2 mb-1 pt-4 position-relative">
                      <a class="d-block">
                        <div
                          class="avatar sk-fade-right avatar-xl badge-float position-absolute top-0 right-0 mt-n6 me-5 rounded-circle shadow border border-white border-w-lg">
                          <img src="{{ apiURL }}/{{ c.authorPhoto }}" alt="..." class="avatar-img rounded-circle">
                        </div>
                      </a>

                      <!-- Preheading -->
                      <a href="course-single.html"><span class="mb-1 d-inline-block text-gray-800">{{ c.categoryName
                          }}</span></a>

                      <!-- Heading -->
                      <div class="position-relative">
                        <a href="course-single.html" class="d-block stretched-link">
                          <h4 class="line-clamp-2 h-md-48 h-lg-58 me-md-6 me-lg-10 me-xl-4 mb-2">
                            {{ c.name }}</h4>
                        </a>

                        <div class="d-lg-flex align-items-end flex-wrap mb-n1">
                          <div class="star-rating mb-2 mb-lg-0 me-lg-3 w-100">
                            <div class="rating" [style.width.%]="(+c.starCount/5)*100"></div>
                          </div>

                          <div class="font-size-sm">
                            <span>{{ c.starCount }} ({{ c.reviewCount }}+ reviews)</span>
                          </div>
                        </div>

                        <div class="row mx-n2 align-items-end">


                          <div class="col-auto px-2">
                            <del class="font-size-sm">₹{{ c.actual_Price }}</del>
                            <ins class="h4 mb-0 d-block mb-lg-n1">₹{{ c.offer_Price }}</ins>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <!-- Wishlist End -->
      </div>
    </div>
  </div>
</div>

<div class="bubbles_wrap">
  <div class="bubble x1"></div>
  <div class="bubble x2"></div>
  <div class="bubble x3"></div>
  <div class="bubble x4"></div>
  <div class="bubble x5"></div>
  <div class="bubble x6"></div>
  <div class="bubble x7"></div>
  <div class="bubble x8"></div>
  <div class="bubble x9"></div>
  <div class="bubble x10"></div>
</div>
<!-- Scroll To Top Start -->
<a href="#main-banner" class="scroll-top" id="scroll-to-top">
  <i class="fa fa-arrow-up" aria-hidden="true"></i>
</a>