import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { CourseService } from 'src/app/courses/services/course.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  public cartItemList: any = []
  public productList = new BehaviorSubject<any>([])

  private cartArray: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  private coursePresent = new BehaviorSubject<boolean>(false);
  coursePresentSignal$ = this.coursePresent.asObservable();

  constructor(private ActivateRoute: ActivatedRoute, private courseService: CourseService, private toastr: ToastrService) {
    this.getCartCourseCount()
  }

  getCartCourseCount(){
    const cart = localStorage.getItem('cart');
    if (cart) {
      const parsedCart = JSON.parse(cart);
      this.cartArray.next(parsedCart.items);
    }else{
      this.cartArray.next([]);
    }
  }

  getProduct() {
    return this.productList.asObservable();
  }

  setProduct(client_id: any, courseId: any) {
    this.cartItemList.push(...client_id);
    this.productList.next(client_id)
  }

  addtoCart(client_id: any, courseId: any) {
    this.cartItemList.push(client_id, courseId);
    this.productList.next(this.cartItemList);
    // this.getTotalPrice();
    // console.log(this.cartItemList)
    return this.productList.asObservable();
  }

  // getTotalPrice(){
  //   let grandTotal=0;
  //   this.cartItemList.map((a:any)=>{
  //     grandTotal += a.total;
  //   })
  // }

  getCartCourse() {
    return this.cartArray.asObservable(); // Expose as observable
  }

  saveCart(cart: any): void {
    localStorage.setItem('cart', JSON.stringify(cart));
    let cartCourse: any = JSON.parse(localStorage.getItem('cart')!);
    this.cartArray.next(cartCourse.items); // Update the BehaviorSubject
  }

  cartService(course: any) { 
    

    if (localStorage.getItem('cart')) {
      let cart: any = JSON.parse(localStorage.getItem('cart')!);

      if (cart.items.some((ele: any) => ele.id === course.id)) {
        cart.items = cart.items.map((item: any) => {
          if (item.id === course.id) {
            return {
              ...item,
              quantity: item.quantity + 1
            };
          }
          return item;
        });
      } else {

        let newCourse = {
          id: course.id,
          quantity: 1,
          offer_price: course.offer_Price,
          actual_Price: course.actual_Price,
          name: course.name,
          image: course.bannerImg
        };
        cart.items.push(newCourse);
      }

      this.saveCart(cart);
    } else {

      let firstObject = {
        id: course.id,
        quantity: 1,
        offer_price: course.offer_Price,
        actual_Price: course.actual_Price,
        name: course.name,
        image: course.bannerImg
      };
      let cart = {
        items: [firstObject]
      };
      this.saveCart(cart);
    }

    this.checkCourseInsideCart(course)


  }


  increaseQuantity(course: any) {
    if (localStorage.getItem('cart')) {
      let cart: any = JSON.parse(localStorage.getItem('cart')!);
      cart.items = cart.items.map((item: any) => {
        if (item.id === course.id) {
          return {
            ...item,
            quantity: item.quantity + 1
          };
        }
        return item;
      });
      this.saveCart(cart);
    }
  }

  descreaseQuantity(course: any) {
    if (localStorage.getItem('cart')) {
      let cart: any = JSON.parse(localStorage.getItem('cart')!);
      // First, filter out any item with quantity === 1 and matching the course.id
      cart.items = cart.items.filter((item: any) => {
        // If item.id matches and quantity is 1, filter it out
        if (item.id === course.id && item.quantity === 1) {
          console.log(`Removing course with id: ${course.id}`);
          return false; // Remove item
        }
        return true; // Keep the rest
      });

      // Now, update the quantity for other items (if item.id matches)
      cart.items = cart.items.map((item: any) => {
        if (item.id === course.id) {
          return {
            ...item,
            quantity: item.quantity - 1
          };
        }
        return item;
      });
      this.saveCart(cart);
    }
  }

  removeCourse(course: any) {
    if (localStorage.getItem('cart')) {
      let cart: any = JSON.parse(localStorage.getItem('cart')!);
      cart.items = cart.items.filter((item: any) => item.id !== course.id);
      this.saveCart(cart);

      // After removing the course, check if it still exists in the cart
      this.checkCourseInsideCart(course);
    }
  }


  checkCourseInsideCart(course: any) {
    let cart: any = JSON.parse(localStorage.getItem('cart')!);
    if(cart){
      if (cart.items.some((ele: any) => ele.id === course.id)) {
        this.coursePresent.next(true);
      } else {
        this.coursePresent.next(false);
      }
    }
   
  }

  getCart() {
    return JSON.parse(localStorage.getItem('cart')!);
  }

  cartCoursePresent(){
    let cart: any = JSON.parse(localStorage.getItem('cart')!);
    if(cart){
      if(cart.items.length > 0){
        return true
      }else{
        return false
      }
    }else{
      return false
    }
  }

}


