import { Component, OnInit } from '@angular/core';
import { IndexService } from '../../services/index.service';
@Component({
  selector: 'app-students-feedback',
  templateUrl: './students-feedback.component.html',
  styleUrls: ['./students-feedback.component.scss']
})
export class StudentsFeedbackComponent implements OnInit {
  studentTestimonial:any=[]

  constructor(private indexService:IndexService) { }

  ngOnInit(): void {
    this.getStudentTestimonial()
  }

  getStudentTestimonial(){
    this.indexService.getTestimonials().subscribe((resStudentTestimonal:any)=>{
      if(resStudentTestimonal.items){
        this.studentTestimonial=resStudentTestimonal.items
      }
    })
  }

  truncateReview(review: string, maxLength: number): string {
    if (review.length > maxLength) {
      return review.substring(0, maxLength) + '...';
    }
    return review;
  }

}
