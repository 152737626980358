import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { IndexService } from '../../services/index.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  form!: FormGroup;
  loading = false;
  submitted = false;

  constructor(private formBuilder: FormBuilder, private toastr: ToastrService, private IndexService: IndexService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      sendmail: ['', Validators.required,Validators.email],
    });
  }

  get f() { return this.form.controls; }

  onSubmit() {

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.IndexService.sendMail(this.form.value)
      .subscribe({
        next: () => {

          this.toastr.success('Thank you for subscribe...');
          console.log(this.form.value)

        },
        error: error => {
          this.toastr.error(error);
          this.loading = false;
        }
      });
  }

}
