 <!-- PAGE TITLE
    ================================================== -->
 <!-- <header class="py-4 py-md-4 main-banner-in">
   <div class="container-fluid text-center py-xl-2">
     <h2 class="fw-semi-bold mb-0">Member Login</h2>
   </div>
 </header> -->

 <!-- LOGIN
    ================================================== -->
 <!-- <div class="container-fluid mb-8">
   <div class="row gx-0">
     <div class="col-md-7 col-xl-5 mx-auto">
     
       <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="mb-5 bg-white border border-orange p-5">
        
         <div class="form-group mb-5">
           <label for="username">
             Username
           </label>
           <input type="text" formControlName="userNameOrEmailAddress"
             [ngClass]="{ 'is-invalid': isSubmitted && f.userNameOrEmailAddress.errors }"
             class="form-control form-control-sm" id="username" placeholder="Username">
           <div *ngIf="isSubmitted && f.userNameOrEmailAddress.errors" class="invalid-feedback">
             <div *ngIf="f.userNameOrEmailAddress.errors.required">Username is required</div>
           </div>
         </div>


       
         <div class="form-group mb-5">
           <label for="password">
             Password
           </label>
           <input type="password" formControlName="password" id="password" class="form-control form-control-sm"
             placeholder="Password" [ngClass]="{ 'is-invalid': isSubmitted && f.password.errors }" />
             <span id="toggle_pwd" style="cursor: pointer;" class="fa fa-fw fa-eye field-icon"></span>
           <div *ngIf="isSubmitted && f.password.errors" class="invalid-feedback">
             <div *ngIf="f.password.errors.required">Password is required</div>
           </div>
         </div>


         <div class="d-flex align-items-center mb-5 font-size-sm">
          
           <div class="ms-auto">
             <a class="forgot" [routerLink]="['/forgot-password']">Forgot Password</a>
           </div>
         </div>

        
         <button class="btn btn-block btn-primary btn-sm" type="submit">
           Login
         </button>
       </form>


       <p class="mb-0 font-size-sm text-center">
         Don't have an account? <a class="text-underline forgot" [routerLink]="['/register']">Sign up</a>
       </p>
     </div>
   </div>
 </div> -->

 <div class="container-fluid mb-8">
  <div class="row gx-0">
    <div class="login-registration-wrapper">
      <div class="container">
          <div class="row g-0">
              <div class="col-lg-6">
                  <div class="login-page-form-area">
                      <h4 class="title">Login to Your Account👋</h4>
                      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                          <div class="single-input-wrapper">
                              <label for="email">Your Email</label>
                              <input id="email" type="email" placeholder="Enter Your Email" formControlName="userNameOrEmailAddress">
                              <div class="text-danger" *ngIf="loginForm.controls['userNameOrEmailAddress'].invalid && loginForm.controls['userNameOrEmailAddress'].touched">
                                Email is required
                              </div>
                          </div>
                          <div class="single-input-wrapper">
                            <label for="password">Your Password</label>
                            <div class="input-icon-wrapper">
                                <input id="password"  [type]="showPassword ? 'text' : 'password'"  placeholder="Password" formControlName="password">
                                <i class="fa fa-look" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'" aria-hidden="true"  (click)="displayPassWord()"></i>
                            </div>
                            <div class="text-danger" *ngIf="loginForm.controls['password'].invalid && loginForm.controls['password'].touched">
                                Password is required
                            </div>
                        </div>
                        
                          <div class="single-checkbox-filter">
                              <div class="check-box">
                                  <input type="checkbox" id="type-1">
                                  <label for="type-1">Remember Me</label><br>
                              </div>
                          </div>
                          <button type="submit" class="rts-btn btn-primary">Login</button>
    
                          <div class="google-apple-wrapper">
                              <div class="google">
                                  <img src="assets/images/contact/06.png" alt="contact" onclick="handleGoogleLogin()">
                              </div>
                              <div class="google">
                                  <img src="assets/images/contact/07.png" alt="contact" onclick="handleAppleLogin()">
                              </div>
                              
                          </div>
                          <p>Don't Have an account? <a routerLink="/register">Registration</a></p>
                      </form>
                  </div>
              </div>
              <div class="col-lg-6">
                  <div class="contact-thumbnail-login-p mt--100">
                      <img src="assets/images/banner/login-bg.png" width="600" height="495" alt="login-form">
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
 </div>


 <div class="bubbles_wrap">
   <div class="bubble x1"></div>
   <div class="bubble x2"></div>
   <div class="bubble x3"></div>
   <div class="bubble x4"></div>
   <div class="bubble x5"></div>
   <div class="bubble x6"></div>
   <div class="bubble x7"></div>
   <div class="bubble x8"></div>
   <div class="bubble x9"></div>
   <div class="bubble x10"></div>
 </div>

 <!-- Scroll To Top Start -->
 <a href="#main-banner" class="scroll-top" id="scroll-to-top">
   <i class="fa fa-arrow-up" aria-hidden="true"></i>
 </a>
 
