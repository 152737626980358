<!-- HERO
    ================================================== -->
<!-- <section class="jarallax position-relative pt-12 pt-md-8 pt-lg-12 pt-xl-12 mt-n12" data-jarallax data-speed=".8"
  style="background-image: url(assets/img/illustrations/illustration-14.svg)">
  
  <div class="container-fluid">


    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 col-xl-6 order-md-1 text-lg-right">

        
          <img src="assets/img/photos/omkar-mali1.png" class="img-fluid mb-6 mb-md-0" alt="..." data-aos="fade-right"
            data-aos-delay="100">

        </div>
        <div class="col-md-6 col-xl-6 order-md-2 pt-4 pb-10 pt-md-11 pb-md-12">
         
          <h4 class="mb-5" data-aos="fade-left" data-aos-duration="150">व्यवसाय प्रशिक्षक</h4>

         
          <h1 class="display-2 fw-bold" data-aos="fade-left" data-aos-duration="150">डॉ. ॐकार हरी माळी</h1>

        
          <p class="text-dark mb-5" data-aos="fade-up" data-aos-duration="200">
            प्रवर्तक । उद्यमी महाराष्ट्र
          </p>

        
          <a [routerLink]="['/course']" class="btn btn-primary btn-sm-wide lift text-white" data-aos="fade-up"
            data-aos-duration="200">आजच सुरू करा</a>

        </div>
      </div> 
    </div> 
  </div>
</section> -->

<section class="jarallax position-relative pt-12 pt-md-8 pt-lg-12 pt-xl-12 mt-n12" data-jarallax data-speed=".8">
  <div class="container-fluid p-0">
    <div class="rts-banner-five bg_image">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 order-xl-1 order-lg-1 order-md-2 order-sm-2 order-2">
            <!-- banner five area start -->
            <div class="rts-banner-five-content-wrapper pb--150">

              <h1 class="title-m-5">
                <span>UNLOCK YOUR POTENTIAL</span> <br>
                <div class="d-flex  gap-4">
                  <h1 class="with-span">WITH</h1>
                  <img src="assets/images/antarkar.sir (1).png" alt="banner-image">
                </div>

              </h1>
              <div class="banner-btn-author-wrapper">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <a routerLink="/course/courses" class="rts-btn btn-primary-white radious-0">My Courses</a>
                  </div>
                  <div class="col-12 col-md-4 p-0">
                    <div class="rts-wrapper-stars-area">
                      <h5 class="title" style="white-space: nowrap;">4.5 <span> Instructor Rating</span> </h5>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-regular fa-star"></i>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 p-0">
                    <p class="disc">
                      We are passionate about education and
                      dedicated to providing high-quality
                      learning for aspiring students
                    </p>
                  </div>
                </div>



              </div>
            </div>
            <!-- banner five area end -->
          </div>
          <div
            class="col-lg-6 order-xl-2 order-lg-2 order-md-1 order-sm-1 order-1  justify-content-xl-end justify-content-md-center justify-content-sm-center d-flex align-items-end">
            <!-- banner five image area start -->
            <div class="banner-five-thumbnail">
              <img src="assets/images/Banner-image.png" alt="banner-imagfe">
            </div>
            <!-- banner five image area end -->
          </div>
        </div>
      </div>
      <!-- banner- absolute-area -->
      <div class="banner-absolute-wrapper">
        <div class="sm-image-wrapper">
          <div class="images-wrap">
            <img src="assets/images/banner/shape/06.png" alt="banner">
            <img class="two" src="assets/images/banner/shape/07.png" alt="banner">
            <img class="three" src="assets/images/banner/shape/08.png" alt="banner">
          </div>
          <div class="info">
            <h6 class="title">2k students</h6>
            <span>Joint our online Class</span>
          </div>
        </div>
        <div class="review-thumb">
          <!-- single review -->
          <div class="review-single two">
            <img src="assets/images/banner/08.png" alt="banner">
            <div class="info-right">
              <h6 class="title">100+
              </h6>
              <span>Online Course</span>
            </div>
          </div>
          <!-- single review end -->
        </div>
      </div>
      <!-- banner- absolute-area end -->
    </div>
    <div class="fun-facts-area-4 mt-dec-fun-f pb--100">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="fun-facts-main-wrapper-1 style-two">
              <!-- single  -->
              <div class="single-fun-facts">
                <div class="icon">
                  <img src="assets/images/fun-facts/icon/02.svg" alt="icon">
                </div>
                <h5 class="title"><span class="counter">26</span></h5>
                <span class="enr">Years of teaching experience</span>
              </div>
              <!-- single end -->
              <!-- single  -->
              <div class="single-fun-facts">
                <div class="icon">
                  <img src="assets/images/fun-facts/icon/01.svg" alt="icon">
                </div>
                <h5 class="title"><span class="counter">2103+</span></h5>
                <span class="enr">No of students taught </span>
              </div>
              <!-- single end -->
              <!-- single  -->
              <div class="single-fun-facts">
                <div class="icon">
                  <img src="assets/images/fun-facts/icon/03.svg" alt="icon">
                </div>
                <h5 class="title"><span class="counter">20 %</span></h5>
                <span class="enr">got ranks in JEE Advanced</span>
              </div>
              <!-- single end -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="jarallax position-relative pt-12 pt-md-8 pt-lg-12 pt-xl-12 mt-n12" data-jarallax data-speed=".8">
  <div class="container-fluid">
    <div class="rts-about-area-five rts-section-gapBottom">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">

            <div class="about-left-style-five">
              <div class="title-wrapper">
                <h2 class="title-stroke">Instructor</h2>
              </div>
              <p class="disc mb--30">
                Prof. <span style="color: #131313; font-weight: 600;">Vinayak Antarkar</span> has completed
                his B. Tech. in Civil Engineering from IIT, Bombay. He has taught various subjects like Std.
                XI and XII Mathematics, Engineering Mechanics and Mathematics for IIT-JEE, AIEEE, JEE Main
                and MHT- CET.
                He has been teaching Mathematics for IIT-JEE since 1999.

              </p>
              <p class="disc mb--30">
                Prof. <span style="color: #131313; font-weight: 600;">Vinayak Antarkar</span> , B.Tech., IIT
                Bombay; has 28 years of experience of teaching mathematics and 25 years of coaching
                mathematics for JEE Advanced and other National and State level Engineering Entrance Exams.
              </p>
              <div class="call-sign-area-start">
                <div class="call-btn-area">
                  <div class="icon">
                    <i class="fa-regular fa-phone"></i>
                  </div>
                  <div style="font-size: 20px;" class="information">
                    <p>Call me</p>
                    <a href="tel:+4733378901">+91 9922992299</a>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="col-lg-6 pl--50 pl_md--15 pl_sm--10 mt_md--50  mt_sm--50">

            <div class="about-thumbnail-right-5">

              <img src="assets/images/Anarkar-sir-profile-photo.jpg" alt="about-area">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- FEATURED PRODUCT
    ================================================== -->
<!-- <section class="pt-5 pb-5 py-md-8">
  <div class="container-fluid">
    <div class="row align-items-center mb-5" data-aos="fade-up">
      <div class="col-md mb-2 mb-md-0">
        <h1 class="mb-1">Popular Courses</h1>
        <p class="font-size-lg text-capitalize">Discover your perfect program in our courses.</p>
      </div>
      <div class="col-md-auto">
        <a [routerLink]="['/course']" class="d-flex align-items-center fw-medium">
          View All
          <div class="ms-2 d-flex">
            <i class="fas fa-angle-right"></i>

          </div>
        </a>
      </div>
    </div>
    <div class="row row-cols-md-2 row-cols-xl-3 row-cols-wd-4 row-cols-lg-3 mb-6 mb-xl-3 ">
      <ng-container *ngFor="let c of courses;let i=index">
        <div class="col-md pb-4 pb-md-7" data-aos="fade-in">
          <div class="card border shadow p-2 lift sk-fade">
            <div class="card-zoom position-relative">
              <div class="badge-float sk-fade-top top-0 right-0 mt-4 me-4">
                <a [routerLink]="['/course-details/'+ c.id.toString() +'']"
                  class="btn btn-xs btn-dark text-white rounded-circle lift opacity-dot-7 me-1 p-2 d-inline-flex justify-content-center align-items-center w-36 h-36">
                  <i class="far fa-eye"></i>
                </a>
                <a [routerLink]="['/course-details/'+ c.id.toString() +'']"
                  class="btn btn-xs btn-dark text-white rounded-circle lift opacity-dot-7 p-2 d-inline-flex justify-content-center align-items-center w-36 h-36">
                  <i class="far fa-heart"></i>
                </a>
              </div>

              <a [routerLink]="['/course-details/'+ c.id.toString() +'']" class="card-img sk-thumbnail d-block">
                <img class="rounded shadow-light-lg" src="{{apiURL}}/{{c.bannerImg}}" alt="...">

              </a>

              <span
                class="badge sk-fade-bottom badge-lg badge-primary badge-pill badge-float bottom-0 left-0 mb-4 ms-4">
                <span class="text-white text-uppercase fw-bold font-size-xs">BEST SELLER</span>
              </span>
            </div>


            <div class="card-footer px-2 pb-2 mb-1 pt-4 position-relative">
              <a class="d-block">
                <div
                  class="avatar sk-fade-right avatar-xl badge-float position-absolute top-0 right-0 mt-n6 me-5 rounded-circle shadow border border-white border-w-lg">
                  <img src="{{apiURL}}/{{c.authorPhoto}}" alt="..." class="avatar-img rounded-circle">
                </div>
              </a>


              <a [routerLink]="['/course-details/'+ c.id.toString() +'']"><span
                  class="mb-1 d-inline-block text-gray-800">{{c.categoryName}}</span></a>

   
              <div class="position-relative">
                <a [routerLink]="['/course-details/'+ c.id.toString() +'']" class="d-block stretched-link">
                  <h4 class="line-clamp-2 h-md-48 h-lg-58 me-md-6 me-lg-10 me-xl-4 mb-2">{{c.name}}</h4>
                </a>

                <div class="d-lg-flex align-items-end flex-wrap mb-n1">
                  <div class="star-rating mb-2 mb-lg-0 me-lg-3">
                    <div class="rating" [style.width.%]="(c.star/5)*100"></div>
                  </div>

                  <div class="font-size-sm">
                    <span>{{c.star}} ({{c.review}}+ reviews)</span>
                  </div>
                </div>

                <div class="row mx-n2 align-items-end">
                  <div class="col px-2">
                    <ul class="nav mx-n3">
                      <li class="nav-item px-3">
                        <div class="d-flex align-items-center">
                          <div class="me-2 d-flex icon-uxs text-secondary">
                 
                            <i class="fas fa-book-reader"></i>
                          </div>
                          <div class="font-size-sm">{{c.lessonsCount}} lessons</div>
                        </div>
                      </li>
                      <li class="nav-item px-3">
                        <div class="d-flex align-items-center">
                          <div class="me-2 d-flex icon-uxs text-secondary">
                       
                            <i class="far fa-clock"></i>
                          </div>

                          <div class="font-size-sm">{{c.total_Duration | timeConvertor}}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="col-auto px-2 text-right">
                    <del class="font-size-sm">₹{{c.actual_Price}}</del>
                    <ins class="h4 mb-0 d-block mb-lg-n1">₹{{c.offer_Price}}</ins>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section> -->


<section class="pt-5 pb-5 py-md-8">
  <div class="container-fluid">
    <div class="course-area-start bg-category-2">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title-w-style-center">
              <h2 class="title">Educative Programme for Beginners</h2>
              <p>Strengthen your mathematical foundation and develop the advanced problem-solving skills</p>
            </div>
          </div>
        </div>
        <div class="row mt--20 g-5">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center">

            <div class="single-course-style-three">
              <a routerLink="/course/course-details/{{courseDisplay.id}}" class="thumbnail">
                <img [src]="apiURL + courseDisplay.bannerImg" alt="course">
                <div class="tag-thumb">
                  <span>{{courseDisplay.skillLevel ? courseDisplay.skillLevel:'NA'}}</span>
                </div>
              </a>
              <div class="body-area">
                <div class="course-top">
                  <div class="tags">Best Seller</div>
                  <div class="price"> &#8377; {{courseDisplay.offer_Price ? courseDisplay.offer_Price : 'NA'}}</div>
                </div>
                <a routerLink="/course-details">
                  <h5 class="title">{{courseDisplay.name}}</h5>
                </a>
                <div class="teacher-stars">
                  <div class="teacher"><span style="font-size: 18px;">{{courseDisplay.authorName ?
                      courseDisplay.authorName :'NA'}}</span></div>
                  <ul class="stars">
                    <li class="span">4.5</li>
                    <li><i class="fa-sharp fa-solid fa-star"></i></li>
                    <li><i class="fa-sharp fa-solid fa-star"></i></li>
                    <li><i class="fa-sharp fa-solid fa-star"></i></li>
                    <li><i class="fa-sharp fa-solid fa-star"></i></li>
                    <li><i class="fa-sharp fa-regular fa-star"></i></li>
                  </ul>
                </div>
                <div class="leasson-students">
                  <div class="lesson">
                    <i class="fa-light fa-calendar-lines-pen"></i>
                    <span>10 Lessons</span>
                  </div>
                  <div class="students">
                    <i class="fa-light fa-users"></i>
                    <span>Online Learning</span>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</section>


<app-all-course-secton></app-all-course-secton>

<app-students-feedback></app-students-feedback>


<section class="jarallax position-relative pt-12 pt-md-8 pt-lg-12 pt-xl-12 mt-n12" data-jarallax data-speed=".8">
  <div class="container-fluid">
    <div class="rts-contact-area rts-section-gap bg-category-2">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="contact-thumbnail-img">
              <img src="assets/images/Enquiry-fom.jpg" alt="contact">
            </div>
          </div>
          <div class="col-lg-6 pl--70 pl_sm--15 pl_md--15 mt_md--50 mt_sm--50">
            <!-- contact- area right template -->
            <div class="contact-area-right-5">
              <h2 class="title">Feel free to reach out to us if you have any questions.</h2>
              <form [formGroup]="contactUsForm" class="contact-form-1" (ngSubmit)="contactUs()">
                <div class="single-input">
                  <label for="name">Your Name<span class="text-danger">*</span></label>
                  <input type="text" id="name" placeholder="Enter Your Name" formControlName="name">
                  <div class="text-danger"
                    *ngIf="contactUsForm.controls['name'].invalid && contactUsForm.controls['name'].touched">
                    Name is required
                  </div>
                </div>
                <div class="single-input">
                  <label for="email">Your Email<span class="text-danger">*</span></label>
                  <input type="text" id="email" placeholder="info@gmail.com" formControlName="email">
                  <div *ngIf="contactUsForm.get('email')?.invalid && contactUsForm.get('email')?.touched">
                    <span class="text-danger" *ngIf="contactUsForm.get('email')?.errors?.required">Email is
                      required.</span>
                    <span class="text-danger" *ngIf="contactUsForm.get('email')?.errors?.pattern">Email must contain
                      '&#64;' and end with '.com'.</span>
                  </div>
                </div>
                <div class="single-input">
                  <label for="number">Your Phone Number<span class="text-danger">*</span></label>
                  <input type="text" id="number" placeholder="Enter Phone Number" formControlName="phone_Number">
                  <div class="text-danger"
                    *ngIf="contactUsForm.controls['phone_Number'].invalid && contactUsForm.controls['phone_Number'].touched">
                    Phone Number is required
                  </div>
                  <div *ngIf="contactUsForm.get('phone_Number')?.invalid && contactUsForm.get('phone_Number')?.touched">
                    <span class="text-danger" *ngIf="contactUsForm.get('phone_Number')?.errors?.required">Number is
                      required.</span>
                    <span class="text-danger" *ngIf="contactUsForm.get('phone_Number')?.errors?.pattern">Only numbers
                      are allowed.</span>
                    <span class="text-danger" *ngIf="contactUsForm.get('phone_Number')?.errors?.maxlength">Maximum
                      length is 10.</span>
                    <span class="text-danger" *ngIf="contactUsForm.get('phone_Number')?.errors?.minlength">Manimum
                      length is 10.</span>
                  </div>
                </div>
                <div class="single-input">
                  <label for="message">Message<span class="text-danger">*</span></label>
                  <textarea id="message" placeholder="Let tell us about your projects"
                    formControlName="message"></textarea>
                  <div class="text-danger"
                    *ngIf="contactUsForm.controls['message'].invalid && contactUsForm.controls['message'].touched">
                    Message is required
                  </div>
                </div>
                <button style="font-size: medium;" type="submit" class="rts-btn btn-primary radious-0">Send
                  Message</button>
              </form>
            </div>
            <!-- contact- area right template end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- About us
    ================================================== -->
<!-- <section class="position-relative overlay overlay-secondary overlay-60 jarallax" data-jarallax data-speed=".8"
  style="background-image: url(assets/img/covers/cover-24.jpg)">
  <div class="container-fluid">
    <div class="row">


      <div class="col-lg-12 order-md-1 py-8 py-md-10 py-xl-12">
        <h2 class="text-white mb-1 text-center" data-aos="fade-up">उद्यमी महाराष्ट्र नक्की काय आहे?</h2>
        <br />

        <div class="text-center" data-aos="fade-in">
          <p class="text-white" style="line-height: 35px;">उद्यमी महाराष्ट्र ही होतकरू आणि विद्यमान उद्योजकांसाठी उभी
            राहिलेली एक नवीन चळवळ आहे. ही चळवळ कोणत्याही शैक्षणिक संस्थेशी संलग्न किंवा मान्यताप्राप्त नाही. पदवी किंवा
            पदविका प्रदान करणे हे या चळवळीचे ध्येय नसून तर वास्तविक जीवनात स्वयंरोजगार निर्माण करणे आणि अनुभवात्मक
            शिक्षण देणे हे या चळवळीचे मुख्य धोरण आहे. आयात निर्यात तसेच, व्यापार आणि व्यवस्थापनाशी संबंधित वास्तविक
            उद्योजकतेविषयी योग्य मार्गदर्शन करणारी उद्यमी महाराष्ट्र ही एक तज्ञ शैक्षणिक संस्था आहे. या चळवळीत
            मार्गदर्शन करणारे कोणतेही प्राध्यापक नाहीत की पांढरपेशा शिक्षणतज्ज्ञ नाहीत, यात असे मार्गदर्शक आहेत जे कधी
            काळी स्वतः उद्योजक होते. उद्यमी महाराष्ट्रने आपल्या मजबूत, दीर्घकालीन पायावर आधारित व्यवसाय चालवला आहे
            ज्याने उद्योग क्षेत्रात विश्वास संपादन केला आहे. उद्यमी महाराष्ट्रशी संबंधित प्रत्येक ग्राहकाला अत्यंत
            समाधानासाठी प्री-आणि-पोस्ट दर्जाची मानक उत्पादने आणि सेवा वर्धित करण्यासाठी सतत प्रशिक्षण, कौशल्य आणि
            ज्ञानाच्या विकासाद्वारे आपली सर्वोत्तम उत्पादने आणि सेवा नियमितपणे राखली गेली आहेत. आज उद्यमी महाराष्ट्र हे
            ग्राहकांना धोरणे आणि ध्येय म्हणून सेवा देण्यासाठी वचनबद्ध आहे.</p>

          <a [routerLink]="['/about-us']" class="btn btn-sm btn-wide text-white btn-primary"> Read More</a>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!-- ICON BLOCKS
    ================================================== -->
<!-- <section class="pt-5 pb-5 py-md-8 bg-light1 text-center">
  <div class="container-fluid">
    <div class="mb-md-8 mb-4" data-aos="fade-up">
      <h1>Why Study With Udyami</h1>
      <p class="font-size-lg mb-0 text-capitalize"></p>
    </div>

    <div class="row">
      <div class="col-md-6 col-lg-3 col-12 mb-4 mb-md-0" data-aos="fade-up">
        <div class="p-5 d-inline-block rounded-circle mb-6" style="border: 1px solid #F8C994;">
          <div class="icon-circle icon-circle-lg" style="background-color: #f5debc; color: #EE8E00;">
       
            <i class="fas fa-camera-retro fa-3x"></i>

          </div>
        </div>

        <h4 class="mb-1">End-to-End Solution</h4>
        <p class="px-lg-7 px-xl-8">End-to-End Solution For all. Your Export Import Queries.</p>
      </div>

      <div class="col-md-6 col-lg-3 col-12 mb-4 mb-md-0" data-aos="fade-up">
        <div class="p-5 d-inline-block rounded-circle mb-6" style="border: 1px solid #B7B3F8;">
          <div class="icon-circle icon-circle-lg" style="background-color: #d3d8f8; color: #5066F5;">
         
            <i class="fas fa-certificate fa-3x"></i>
          </div>
        </div>

        <h4 class="mb-1">Service Provider </h4>
        <p class="px-lg-7 px-xl-8">Udyami Maharashtra is leading Service Provider For All Licence related to Exim Industry.</p>
      </div>

      <div class="col-md-6 col-lg-3 col-12 mb-4 mb-md-0" data-aos="fade-up">
        <div class="p-5 d-inline-block rounded-circle mb-6" style="border: 1px solid #B2F4DC;">
          <div class="icon-circle icon-circle-lg" style="background-color: #b2f4dc; color: #00C27C;">
          
            <i class="fas fa-shipping-fast fa-3x"></i>

          </div>
        </div>

        <h4 class="mb-1">Worldwide Network </h4>
        <p class="px-lg-7 px-xl-8">Worldwide Network with Suppliers and Buyers with Verification.</p>
      </div>
      <div class="col-md-6 col-lg-3 col-12 mb-4 mb-md-0" data-aos="fade-up">
        <div class="p-5 d-inline-block rounded-circle mb-6" style="border: 1px solid #aca2ce;">
          <div class="icon-circle icon-circle-lg" style="background-color: #EDEAF6; color: #4E35A3;">
           
            <i class="fas fa-diagnoses fa-3x"></i>

          </div>
        </div>

        <h4 class="mb-1">Exim</h4>
        <p class="px-lg-7 px-xl-8">Explore world with our Experts by means of Exim.</p>
      </div>
    </div>
  </div>
</section> -->

<!-- TESTIMONIAL
    ================================================== -->
<!-- <section class="pt-5 pb-5 py-md-8">
  <div class="container-fluid main-banner-in testimonial">
    <div class="text-center mb-2" data-aos="fade-up">
      <h1 class="mb-1">प्रशंसापत्र</h1>
      <p class="font-size-lg text-capitalize mb-0"></p>
    </div>

    <div class="row row-cols-md-2 row-cols-xl-3 row-cols-wd-4 row-cols-lg-3 mb-6 mb-xl-3">
      <ng-container *ngFor="let t of testimonials;let i=index">
        <div class="col-md pb-4 pb-md-7 mt-3" data-aos="fade-in">
         
          <div class="card border shadow p-6 lift-md">
            
            <div class="card-zoom">
              <div class="d-flex align-items-center">
                <div class="avatar avatar-custom me-5">
                  <img src="{{apiURL}}/{{t.clientPhoto}}" alt="..." class="avatar-img rounded-circle">
                </div>
                <div class="media-body">
                  <h5 class="mb-0">{{t.clientName}}</h5>
                  <span>{{t.designation}}</span>
                </div>
              </div>
            </div>

           
            <div class="card-footer px-0 pb-0">
              <p class="mb-0 text-capitalize">“{{t.reviews}}“</p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section> -->


<!-- EVENTS
    ================================================== -->
<!-- <section class="bg-light pt-5 pb-5 py-md-8">
  <div class="container-fluid">
    <div class="row align-items-end mb-4 mb-md-7">
      <div class="col-md mb-4 mb-md-0" data-aos="fade-up">
        <h1 class="mb-1">Upcoming Events</h1>
        <p class="font-size-lg mb-0 text-capitalize">Discover your perfect program in our courses.</p>
      </div>
      <div class="col-md-auto">
        <a href="event-list.html" class="d-flex align-items-center fw-medium">
          Browse All
          <div class="ms-2 d-flex">          
            <i class="fas fa-angle-right"></i>
          </div>
        </a>
      </div>
    </div>

    <div class="row row-cols-lg-2" data-aos="fade-up">
      <ng-container *ngFor="let zoom of zoom;let i=index">
        <div class="col-lg mb-5 mb-md-6">
         
          <div class="card border shadow p-2 lift">
            <div class="row gx-0">
             
              <a href="event-single.html" class="col-auto d-block mw-md-152" style="max-width: 120px;">
                <img class="img-fluid rounded shadow-light-lg h-100 o-f-c" src="assets/img/events/event-1.jpg"
                  alt="...">
              </a>

             
              <div class="col">
                <div class="card-body py-0 px-md-5 px-3">
                  <div class="badge badge-lg badge-orange badge-pill mb-3 mt-1 px-5 py-2">
                    <span class="text-white font-size-sm fw-normal">{{zoom.startDate| date:"mediumDate"}}</span>
                  </div>

                  <a href="event-single.html" class="d-block mb-2">
                    <h5 class="line-clamp-2 h-xl-52 mb-1">{{zoom.description}}</h5>
                  </a>

                  <ul class="nav mx-n3 d-block d-md-flex">
                    <li class="nav-item px-3 mb-3 mb-md-0">
                      <div class="d-flex align-items-center">
                        <div class="me-2 d-flex text-secondary icon-uxs">
                         
                          <i class="far fa-clock"></i>

                        </div>
                        <div class="font-size-sm">{{zoom.startDate| date:"shortTime"}}</div>
                      </div>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</section> -->

<!-- INSTRUCTORS
    ================================================== -->
<!-- <section class="pt-5 pb-5 py-md-8 jarallax" data-jarallax data-speed=".8"
  style="background-image: url(assets/img/illustrations/illustration-1.jpg)">
  <div class="container-fluid" data-aos="fade-up">
    <div class="text-center mb-2" data-aos="fade-up">
      <h1 class="mb-1">Expert Team</h1>
      <p class="font-size-lg text-capitalize mb-0"></p>
    </div>

    <div class="row row-cols-md-2 row-cols-xl-3 row-cols-wd-4 row-cols-lg-3 mb-6 mb-xl-3">
      <ng-container *ngFor="let e of experts;let i=index">
        <div class="col-md pb-4 pb-md-7 mt-3" data-aos="fade-in">
          <div class="card border shadow p-2 lift">
           
            <div class="card-zoom position-relative">
              <div class="card-float card-hover right-0 left-0 bottom-0 mb-4">
                <ul class="nav mx-n4 justify-content-center">
                  <li class="nav-item px-4">
                    <a href="#" target="_blank" class="d-block text-white">
                      <i class="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li class="nav-item px-4">
                    <a href="#" target="_blank" class="d-block text-white">
                      <i class="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li class="nav-item px-4">
                    <a href="#" target="_blank" class="d-block text-white">
                      <i class="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li class="nav-item px-4">
                    <a href="#" target="_blank" class="d-block text-white">
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <a (click)="openDetails()" class="card-img sk-thumbnail img-ratio-4 card-hover-overlay d-block"><img
                  class="rounded shadow-light-lg img-fluid" src="{{apiURL}}/{{e.photo}}" alt="..."></a>
            </div>
          
            <div class="card-footer px-3 pt-4 pb-1">
              <a class="d-block">
                <h5 class="mb-0">{{e.name}}</h5>
                <span class="font-size-d-sm">{{e.designation}}</span>
              </a>              
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section> -->

<div class="modal modal-dialog-scrollable" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
  <div class="modal-dialog" role="document" style="max-width: 650px;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><b>डॉ. ॐकार हरी माळी</b> </h5>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="closePopup()">
          <!-- <span aria-hidden="true">&times;</span> -->
        </button>
      </div>
      <div class="modal-body">
        <p>“जीवनात ठरवलेले ध्येय साध्य करण्यासाठी कौशल्य आणि संयम असावा लागतो. या दोहोंचा संगम करून त्याला अपार मेहनतीची
          साथ देत उद्योग क्षेत्रात एक यशस्वी मराठी व्यावसायिक म्हणून ज्यांनी आपल्या कक्षा रुंदावल्या असे प्रोत्साहनात्मक
          व्यक्तिमत्व म्हणजे ठाणे येथील डॉ. ओमकार हरी माळी होय.
          ठाणे येथे जन्मलेल्या-वाढलेल्या डॉ. ॐकार हरी माळी यांनी आपले उच्चशिक्षण लंडन येथून पूर्ण केले. लंडन येथील
          (IHMES International Business School) ‘एमबीए’ केल्यानंतर त्यांनी ‘इंटरनॅशनल बिझनेस’ या विषयातून डॉक्टरेट पदवी
          प्राप्त केली. उच्चविद्याविभूषित असूनही परदेशातच न थांबता ते आपल्या भूमीत परतले. मात्र महाराष्ट्रात येताना
          त्यांनी एकच ध्येय उराशी बाळगले होते.
          ते म्हणजे “आपल्या महाराष्ट्रातील नागरिकांना स्वतःचा व्यवसाय करण्यासाठी प्रोत्साहित करणं.” इम्पोर्ट एक्स्पोर्ट
          उद्योगात आपला किर्तीदायक जम बसवलेले डॉ. ॐकार हरी माळी हे मेडिकल कोलॅबोरेटर म्हणून आज जगातील दुबई, बांगलादेश,
          उझबेकिस्तान, कझाकिस्तान आणि युक्रेन यासारख्या इतर एकूण सात देशांमध्ये उद्योग करत आहेत. तर महाराष्ट्रातील विविध
          शहरांमध्ये ते ‘ई-कॉमर्स’ क्षेत्रात कार्यरत आहेत. कॉर्पोरेट जगतासह नाशिक जिल्ह्यातील शेतकऱ्यांच्या शेतमालाला
          योग्य भाव मिळावा यासाठी आपल्या इम्पोर्ट एक्सपोर्टच्या व्यवसाय व ज्ञानाची साथ देत ते शेतकऱ्यांना सहकार्य करत
          आहेत. इतकेच नव्हे तर ‘इव्हेन्ट मॅनेजमेंट’ क्षेत्रातही ते यशस्वी उद्योजक म्हणून कार्यरत आहेत. केवळ एका
          व्यवसायापुरतेच मर्यादित न राहता निरनिराळ्या क्षेत्रात आपल्या उद्योजकतेच्या कक्षा रुंदावून एक मराठी यशस्वी
          उद्योजक म्हणून डॉ. ॐकार हरी माळी यांनी आपला ठसा उमटवलेला आहे. मात्र इतक्या साऱ्या वर्षांचा विविध व्यवसाय
          क्षेत्रातील अनुभव त्यांनी स्वतःजवळच ठेवला नाही. तर त्याचा उपयोग हा मराठी तरुण तरुणींना उद्योगासाठी आवश्यक
          प्रशिक्षण आणि व्यवसाय यांचे मार्गदर्शन करून महाराष्ट्राच्या सर्वांगीण वाढीसाठी करण्याचे ठरवले आणि याच
          उद्देशाने डॉ. ॐकार हरी माळी यांनी १ मे २०२० रोजी ‘उद्यमी महाराष्ट्र’ या उपक्रमाची निर्मिती केली.
          राष्ट्रवादी काँग्रेस पक्ष प्रमुख आदरणीय शरदचंद्रजी पवार साहेबांच्या आदेशानुसार आणि मार्गदर्शनाखाली राष्ट्रवादी
          युवक काँग्रेस महाराष्ट्र प्रदेश सरचिटणीस व रोजगार/स्वयंरोजगार महाराष्ट्र प्रदेशाध्यक्ष पदावर आपली सक्रिय
          भूमिका बजावणारे डॉ. ओमकार हरी माळी हे ‘मराठी माणूस व्यवसाय करू शकत नाही’ या प्रचलित वाक्याला खोटे ठरवून
          ‘उद्यमी महाराष्ट्र’ या मोहिमेअंतर्गत सध्या अनेक यशस्वी मराठी उद्योजक घडवत आहेत. पुढच्या वर्षभरात ५०००
          उद्यमींना प्रशिक्षण देऊन त्यांना व्यवसायाच्या मुख्य प्रवाहात आणण्याचे ध्येय डॉ. ओमकार हरी माळी यांनी उराशी
          बाळगलेले आहे.
        </p>
      </div>

    </div>
  </div>
</div>

<!-- BLOG
    ================================================== -->
<!-- <section class="bg-white pt-5 pb-5 py-md-8">
        <div class="container-fluid">
            <div class="row align-items-end mb-4 mb-md-7" data-aos="fade-up">
                <div class="col-md mb-4 mb-md-0" data-aos="fade-up">
                    <h1 class="mb-1">Latest News</h1>
                    <p class="font-size-lg mb-0 text-capitalize">Discover your perfect program in our courses.</p>
                </div>
                <div class="col-md-auto">
                    <a href="blog.html" class="d-flex align-items-center fw-medium">
                        Browse All
                        <div class="ms-2 d-flex">
                           
                            <i class="fas fa-angle-right"></i>

                        </div>
                    </a>
                </div>
            </div>

            <div class="mx-n4" data-flickity='{"pageDots": true, "prevNextButtons": false, "cellAlign": "left", "wrapAround": true, "imagesLoaded": true}' data-aos="fade-up">
                <div class="col-12 col-md-6 col-xl-4 col-wd-3 pb-4 pb-md-7" data-aos="fade-up" data-aos-delay="50" style="padding-right:15px;padding-left:15px;">
                    
                    <div fclass="card border shadow p-2 lift sk-fade">
                       
                        <div class="card-zoom position-relative">
                            <a href="blog-single.html" class="card-img d-block sk-thumbnail img-ratio-3"><img class="rounded shadow-light-lg img-fluid" src="assets/img/post/post-1.jpg" alt="..."></a>
    
                            <a href="blog-single.html" class="badge sk-fade-bottom badge-lg badge-primary badge-pill badge-float bottom-0 left-0 mb-4 ms-4 px-5 me-4">
                                <span class="text-white fw-bold font-size-sm">Import Export</span>
                            </a>
                        </div>
    
                      
                        <div class="card-footer px-2 pb-0 pt-4">
                            <ul class="nav mx-n3 mb-3">
                                <li class="nav-item px-3">
                                    <a href="blog-single.html" class="d-flex align-items-center text-gray-800">
                                        <div class="me-3 d-flex">
                                         
                                            <i class="far fa-user"></i>
    
                                        </div>
                                        <div class="font-size-sm">Dr. Omkar Mali</div>
                                    </a>
                                </li>
                                <li class="nav-item px-3">
                                    <a href="blog-single.html" class="d-flex align-items-center text-gray-800">
                                        <div class="me-2 d-flex">
                                          
                                            <i class="far fa-calendar-check"></i>
    
                                        </div>
                                        <div class="font-size-sm">06 April, 2020</div>
                                    </a>
                                </li>
                            </ul>
    
                           
                            <a href="blog-single.html" class="d-block"><h5 class="line-clamp-2 h-48 h-lg-52">The Best Destinations to Begin Your Round the World Trip</h5></a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-xl-4 col-wd-3 pb-4 pb-md-7" data-aos="fade-up" data-aos-delay="100" style="padding-right:15px;padding-left:15px;">
                   
                 <div class="card border shadow p-2 lift sk-fade">
                   
                    <div class="card-zoom position-relative">
                        <a href="blog-single.html" class="card-img d-block sk-thumbnail img-ratio-3"><img class="rounded shadow-light-lg img-fluid" src="assets/img/post/post-2.jpg" alt="..."></a>

                        <a href="blog-single.html" class="badge sk-fade-bottom badge-lg badge-primary badge-pill badge-float bottom-0 left-0 mb-4 ms-4 px-5 me-4">
                            <span class="text-white fw-bold font-size-sm">Import Export</span>
                        </a>
                    </div>

                  
                    <div class="card-footer px-2 pb-0 pt-4">
                        <ul class="nav mx-n3 mb-3">
                            <li class="nav-item px-3">
                                <a href="blog-single.html" class="d-flex align-items-center text-gray-800">
                                    <div class="me-3 d-flex">
                                        
                                        <i class="far fa-user"></i>

                                    </div>
                                    <div class="font-size-sm">Dr. Omkar Mali</div>
                                </a>
                            </li>
                            <li class="nav-item px-3">
                                <a href="blog-single.html" class="d-flex align-items-center text-gray-800">
                                    <div class="me-2 d-flex">
                                       
                                        <i class="far fa-calendar-check"></i>

                                    </div>
                                    <div class="font-size-sm">06 April, 2020</div>
                                </a>
                            </li>
                        </ul>

                      
                        <a href="blog-single.html" class="d-block"><h5 class="line-clamp-2 h-48 h-lg-52">An Indigenous Anatolian Syllabic Script From 3500 Years Ago</h5></a>
                    </div>
                </div>
                </div>
                <div class="col-12 col-md-6 col-xl-4 col-wd-3 pb-4 pb-md-7" data-aos="fade-up" data-aos-delay="150" style="padding-right:15px;padding-left:15px;">
                  
                <div class="card border shadow p-2 lift sk-fade">
                    
                    <div class="card-zoom position-relative">
                        <a href="blog-single.html" class="card-img d-block sk-thumbnail img-ratio-3"><img class="rounded shadow-light-lg img-fluid" src="assets/img/post/post-3.jpg" alt="..."></a>

                        <a href="blog-single.html" class="badge badge-lg sk-fade-bottom badge-primary badge-pill badge-float bottom-0 left-0 mb-4 ms-4 px-5 me-4">
                            <span class="text-white fw-bold font-size-sm">Import Export</span>
                        </a>
                    </div>

                   
                    <div class="card-footer px-2 pb-0 pt-4">
                        <ul class="nav mx-n3 mb-3">
                            <li class="nav-item px-3">
                                <a href="blog-single.html" class="d-flex align-items-center text-gray-800">
                                    <div class="me-3 d-flex">
                                        
                                        <i class="far fa-user"></i>

                                    </div>
                                    <div class="font-size-sm">Dr. Omkar Mali</div>
                                </a>
                            </li>
                            <li class="nav-item px-3">
                                <a href="blog-single.html" class="d-flex align-items-center text-gray-800">
                                    <div class="me-2 d-flex">
                                      
                                        <i class="far fa-calendar-check"></i>

                                    </div>
                                    <div class="font-size-sm">06 April, 2020</div>
                                </a>
                            </li>
                        </ul>

                      
                        <a href="blog-single.html" class="d-block"><h5 class="line-clamp-2 h-48 h-lg-52">10 Best Countries to Visit for Beginner Travelers</h5></a>
                    </div>
                </div>
                </div>
                <div class="col-12 col-md-6 col-xl-4  col-wd-3 pb-4 pb-md-7" data-aos="fade-up" data-aos-delay="200" style="padding-right:15px;padding-left:15px;">
                    
                <div class="card border shadow p-2 lift sk-fade">
                    
                    <div class="card-zoom position-relative">
                        <a href="blog-single.html" class="card-img d-block sk-thumbnail img-ratio-3"><img class="rounded shadow-light-lg img-fluid" src="assets/img/post/post-3.jpg" alt="..."></a>

                        <a href="blog-single.html" class="badge badge-lg sk-fade-bottom badge-primary badge-pill badge-float bottom-0 left-0 mb-4 ms-4 px-5 me-4">
                            <span class="text-white fw-bold font-size-sm">Import Export</span>
                        </a>
                    </div>

                   
                    <div class="card-footer px-2 pb-0 pt-4">
                        <ul class="nav mx-n3 mb-3">
                            <li class="nav-item px-3">
                                <a href="blog-single.html" class="d-flex align-items-center text-gray-800">
                                    <div class="me-3 d-flex">
                                       
                                        <i class="far fa-user"></i>

                                    </div>
                                    <div class="font-size-sm">Dr. Omkar Mali</div>
                                </a>
                            </li>
                            <li class="nav-item px-3">
                                <a href="blog-single.html" class="d-flex align-items-center text-gray-800">
                                    <div class="me-2 d-flex">
                                       
                                        <i class="far fa-calendar-check"></i>

                                    </div>
                                    <div class="font-size-sm">06 April, 2020</div>
                                </a>
                            </li>
                        </ul>

                       
                        <a href="blog-single.html" class="d-block"><h5 class="line-clamp-2 h-48 h-lg-52">10 Best Countries to Visit for Beginner Travelers</h5></a>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </section> -->

<!-- BRAND
    ================================================== -->
<!-- <div class="pt-5 pb-5 py-md-8">
        <div class="container-fluid">
            <div class="row row-cols-3 row-cols-md-5 text-center w-xl-100 mx-xl-auto justify-content-center align-items-center" data-aos="fade-up">
                <div class="col-md mb-4 mb-md-0" data-aos="fade-up" data-aos-delay="50">
                    <div class="mw-100p mx-auto">

                        <img src="assets/img/patner/logo1.jpg" alt="">
                        
                        

                    </div>
                </div>
                <div class="col-md mb-4 mb-md-0" data-aos="fade-up" data-aos-delay="100">
                    <div class="mw-100p mx-auto">
                        
                        <img src="assets/img/patner/logo2.jpg" alt="">

                    </div>
                </div>
                <div class="col-md mb-4 mb-md-0" data-aos="fade-up" data-aos-delay="150">
                    <div class="mw-100p mx-auto">
                        
                        <img src="assets/img/patner/logo3.jpg" alt="">
                    </div>
                </div>
                <div class="col-md mb-4 mb-md-0" data-aos="fade-up" data-aos-delay="200">
                    <div class="mw-100p mx-auto">
                        
                        <img src="assets/img/patner/logo4.jpg" alt="">

                    </div>
                </div>
                <div class="col-md mb-4 mb-md-0" data-aos="fade-up" data-aos-delay="250">
                    <div class="mw-100p mx-auto">
                        
                        <img src="assets/img/patner/logo5.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div> -->



<div class="bubbles_wrap">
  <div class="bubble x1"></div>
  <div class="bubble x2"></div>
  <div class="bubble x3"></div>
  <div class="bubble x4"></div>
  <div class="bubble x5"></div>
  <div class="bubble x6"></div>
  <div class="bubble x7"></div>
  <div class="bubble x8"></div>
  <div class="bubble x9"></div>
  <div class="bubble x10"></div>
</div>
<!-- Scroll To Top Start -->
<a (click)="onActivate()" class="scroll-top" id="scroll-to-top">
  <i class="fa fa-arrow-up" aria-hidden="true"></i>
</a>