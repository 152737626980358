import { Component, HostListener, OnInit } from '@angular/core';
import { cart, mainCourse } from 'src/app/shared/modals/cart';
import { IndexService } from 'src/app/shared/services/index.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { CourseService } from 'src/app/courses/services/course.service';
import { checkout, courses } from '../modal/checkout';
import { AccountService } from 'src/app/shared/services/account.service';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';
import { CartService } from 'src/app/shared/services/cart.service';
declare var Razorpay: any;
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  Id = this.cryptojsService.decryptLocalUserId()
  checkoutCourse!: cart[];
  cartTotal!: cart;
  Math: any;
  message: any = "Not yet started";
  paymentId = "";
  error = "";
  checkoutForm!: FormGroup;
  submitted = false;
  auth_token!:any;
  checkoutdata!: checkout;
  options = {
    "key": "rzp_test_7yKRj2DDfmfz8e",
    "amount": "100",
    "name": "Sagar",
    "description": "",
    "image": "",
    "order_id": "",
    "handler": function (response: any) {
      var event = new CustomEvent("payment.success",
        {
          detail: response,
          bubbles: true,
          cancelable: true
        }
      );
      window.dispatchEvent(event);
    },
    "prefill": {
      "name": "",
      "email": "",
      "contact": ""
    },
    "notes": {
      "address": ""
    },
    "theme": {
      "color": "#3399cc"
    }
  };
  details: any;

  getCartArray:any=[]
  coursesSubtotal:number=0;
  coursesTotal:number=0;
  gstPercentage:number=18;
  gstCharge:number=0;
  paynow() {
    this.submitted = true;
    if (this.checkoutForm.invalid) {
      return;
    }
    this.paymentId = '';
    this.error = '';
    this.options.amount = (parseInt(this.cartTotal.total.toString()) * 100).toString() //paise * 100 
    this.options.name = this.checkoutForm.value.firstName + ' ' + this.checkoutForm.value.lastName;
    this.options.prefill.email = this.checkoutForm.value.email;
    this.options.prefill.contact = this.checkoutForm.value.phoneNumber;
    var rzp1 = new Razorpay(this.options);
    rzp1.open();
    rzp1.on('payment.failed', function (response: any) {
      //this.message = "Payment Failed";
      // Todo - store this information in the server
      console.log(response.error.code);
      console.log(response.error.description);
      console.log(response.error.source);
      console.log(response.error.step);
      console.log(response.error.reason);
      console.log(response.error.metadata.order_id);
      console.log(response.error.metadata.payment_id);
      //this.error = response.error.reason;
    }
    );
  };


  @HostListener('window:payment.success', ['$event'])
  onPaymentSuccess(event: any): void {
    this.message = "Success Payment";
    // console.log(event)
    this.checkout(event.detail.razorpay_payment_id);

  }

  constructor(private router: Router, private course: CourseService,private accountservice: AccountService, 
    private indexService: IndexService, private toastr: ToastrService, private formBuilder: FormBuilder,
    private cryptojsService:CryptojsService,private cartService:CartService
  ) { }

  ngOnInit(): void {
   
    this.Math = Math;
    // this.checkoutCourses();
    // this.profileDetails(this.auth_token);
  

   this.createCheckoutForm()
   this.getCartCourses()
   this.userLoginUserData()
  }

  userLoginUserData(){
    let Id = this.cryptojsService.decryptLocalUserId()
    this.accountservice.getLoginUserDetails(Id).subscribe((resGetLoginDetails: any) => {
      // console.log("get user details after login",resGetLoginDetails);
      this.checkoutForm.controls['firstName'].setValue(resGetLoginDetails.name);
      this.checkoutForm.controls['lastName'].setValue(resGetLoginDetails.surname);
      this.checkoutForm.controls['email'].setValue(resGetLoginDetails.email);
    })
  }

  getCartCourses(){
   this.getCartArray= this.cartService.getCart()
   if(this.getCartArray.items.length > 0){
   this.coursesSubtotal= this.getCartArray.items.reduce((acc:any,crr:any)=>{
      return acc + crr.offer_price
    },0)
   this.gstCharge = Math.round((this.gstPercentage / 100) * this.coursesSubtotal);
   this.coursesTotal=this.coursesSubtotal + this.gstCharge
   }
  }

  createCheckoutForm(){
    this.checkoutForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      country: ['India', Validators.required],
      state: ['',Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(10), Validators.minLength(10)]],
      email: ['', [Validators.required,  Validators.pattern(/^[^@]+@[^@]+\.[a-z]{2,}$/)]],
      additionalInfo: ['',],
      companyName: ['',],
      gstNo: ['',],
      paymentMode: ['',],
      totalOrderAmount: ['',],
    });
  }

 
  get f() { return this.checkoutForm.controls; }


  checkout(id: any) {
    // console.log(id);
    this.submitted = true;
    if (this.checkoutForm.invalid) {
      return;
    }
    let client_Id = this.cryptojsService.decryptLocalUserId()
    let payment_Id = id; 
    let Flag = 0;

    var formData = this.checkoutForm.value;
    var coursesData:courses[] = [];
    this.checkoutCourse.forEach(element => {
      var course = new courses();
      course.course_Id = element.course_Id,
        course.payment_Data_ID = '',
        course.offer_Percentage = 0,
        course.actual_Price = element.subTotal,
        course.offer_Price = element.subTotal;
      coursesData.push(course);
    });
    formData['payment_Id'] = id;
    formData['courses'] = coursesData;

    formData['ibC_Id'] = null;
    formData['client_Id'] = this.cryptojsService.decryptLocalUserId()

    this.indexService.checkout(formData).subscribe(result => {
      
      Swal.fire({
        position: 'top',
        icon: 'success',
        title: 'Thank you for buying Course.' + ' ' + 'Total Amount You Paid' + ' ' + ' ' + this.cartTotal.total,
        showConfirmButton: true,
        timer: 3000,
      })
      coursesData.forEach(element => {
        this.indexService.removeFromCart(element.course_Id,client_Id,Flag)
        .subscribe(results => {
        });
      });
 
      this.router.navigate(['/course']);
      
    }, (err) => Swal.fire({
      position: 'top',
      icon: 'warning',
      title: err,
      showConfirmButton: true,
      timer: 3000,
    }));
  }

  checkoutCourses() {
    this.course.cartCourse(this.Id).subscribe(view => {
      this.checkoutCourse = view;
      // console.log(this.checkoutCourse,"ddddddd")

      this.cartTotal = view[0];
      this.checkoutForm.controls.totalOrderAmount.setValue(this.cartTotal.total);
      

    })

  }

  profileDetails(auth_token:any){
    auth_token = this.cryptojsService.decryptLocalAccessToken()
    this.accountservice.profileDetails(auth_token).subscribe(res => {  
      this.details = res;
      this.checkoutForm.controls.firstName.setValue(res.name);
      this.checkoutForm.controls.lastName.setValue(res.surname);
      this.checkoutForm.controls.phoneNumber.setValue(res.phoneNumber);
      this.checkoutForm.controls.email.setValue(res.email);

    })
    
  }

}
