import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Authenticate } from '../modals/authenticate';
import { User,loginUser } from '../modals/user';
import { forgotPassword } from '../modals/forgotpassword';
import { resetPassword } from '../modals/resetPassword';
import { IBC } from '../modals/IBC';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';


@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private userSubject: BehaviorSubject<Authenticate>;
  public user: Observable<Authenticate>;
  public forgortPassword: Observable<Authenticate>;
  public resetpassword: Observable<Authenticate>;
  url = environment.url;

  constructor(private router: Router,
    private http: HttpClient,
    private cryptojsService:CryptojsService
  ) {
    this.userSubject = new BehaviorSubject<Authenticate>(JSON.parse(localStorage.getItem('AcceessToken') || '{}'));
    this.user = this.userSubject.asObservable();
    this.forgortPassword = this.userSubject.asObservable();
    this.resetpassword = this.userSubject.asObservable();
    
  }
  public get userValue(): Authenticate {
    return this.userSubject.value;
  }



  login(userNameOrEmailAddress: any, password: any,): Observable<any> {

    //return this.http.post/*<Authenticate>*/(`${this.url}/api/account/login`, { userNameOrEmailAddress, password })//if rememberMe needed,pass parameter:rememberMe
    //.pipe(map(user => {

    // store user details and jwt token in local storage to keep user logged in between page refreshes
    // localStorage.setItem('user', JSON.stringify(user));
    // localStorage.setItem('username', JSON.stringify(userNameOrEmailAddress));
    // localStorage.setItem('userPassword', JSON.stringify(password));
    //this.userSubject.next(user);
    //return user;
    //}));

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
    };

    let body = new URLSearchParams();
    body.set('grant_type', "password");
    body.set('client_id', "VideoStream_App");
    body.set('client_secret', "1q2w3e*");
    body.set('UserName', userNameOrEmailAddress);
    body.set('Password', password);

    // const body = {grant_type:"password",client_id:"VideoStream_App",client_secret: "1q2w3e*",UserName: userNameOrEmailAddress,Password: password}
    return this.http.post(`${this.url}/connect/token`, body.toString(), options)//if rememberMe needed,pass parameter:rememberMe
      .pipe(map(user => {

        //store user details and jwt token in local storage to keep user logged in between page refreshes
        //localStorage.setItem('user', JSON.stringify(user));
        // this.userSubject.next(user);
        return user;
      }));

  }

  // logout() {
  //   // remove user from local storage and set current user to null
  //   localStorage.removeItem('user');
  //   localStorage.removeItem('access');
  //   this.userSubject.next(null);
  //   this.router.navigate(['/login']);

  loginCall(user: loginUser) {
    return this.http.post(`${this.url}/api/account/login`, user);
  }

  register(user: User) {
    return this.http.post(`${this.url}/api/app/my-account/my-register`, user);
  }

  Ibcregister(formData : IBC) {

    // let options = {
    //       headers: new HttpHeaders({
    //         'Content-Type': 'multipart/form-data',    
    //         'enctype': 'multipart/form-data'        
    //       })
    //     }

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    // headers = headers.append('enctype', 'multipart/form-data');


    return this.http.post(`${this.url}/api/app/i-bC_Registration`, formData);
  }

  forgotPassword(forgotpassword: forgotPassword) {
    
    return this.http.post(`${this.url}/api/account/send-password-reset-code`, forgotpassword)
  }

  resetPassword(resetpassword: resetPassword) {
    return this.http.post(`${this.url}/api/account/reset-password`, resetpassword)
  }



  // changepassword(currentPassword: any, newPassword: any){

  //   let options = {
  //     headers: new HttpHeaders({
  //       'Content-Type':  'application/json',
  //       'Cookie': localStorage.getItem('AcceessToken') || '{}',
  //     })
  //   }

  //   let body = new URLSearchParams();
  //   body.set('currentPassword', currentPassword);
  //   body.set('newPassword', newPassword);
  //   return this.http.post(`${this.url}/api/identity/my-profile/change-password`,body.toString(),options);
  // }

  changepassword(currentPassword: any, newPassword: any) {
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + this.cryptojsService.decryptLocalAccessToken()),
    };


    var data = {
      'currentPassword': currentPassword,
      'newPassword': newPassword
    };
    console.log("get data",data)
    return this.http.post(`${this.url}/api/identity/my-profile/change-password`, data, options);
  }



  editProfile(data: any) {
    return this.http.put(`${this.url}/api/identity/my-profile`, data);
  }

  profileDetails(auth_token: any):Observable<any>{
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`
    })
    return this.http.get(`${this.url}/api/identity/my-profile`, { headers: headers }).pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in getting Profile Details'))),
    );
  }

  getLoginUserDetails(userId:any):Observable<any>{
    return this.http.get(`${this.url}/api/app/identity-user/${userId}`).pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in getting Login User Info Details'))),
    );
  }
  myReview(Id: any):Observable<any>{
    
    let options = {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.cryptojsService.decryptLocalAccessToken()),
    };
    
    return this.http.get(`${this.url}/api/app/comments/client-comments/${Id}`,options).pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in getting reviews'))),
    );
  }

  mySubscription(Id: any):Observable<any>{
    let options = {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.cryptojsService.decryptLocalAccessToken()),
    };
    
    return this.http.get(`${this.url}/api/app/course-by-client/client-wise-courses/${Id}`,options).pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in getting Data'))),
    );
  }


}


