<!-- <body class="bg-white right-sidebar woocommerce-checkout">

  <header class="py-2 py-md-4 main-banner-in1 overlay overlay-primary overlay-70 jarallax" data-jarallax data-speed=".8"
    style="background-image: url(assets/img/covers/cover-19.jpg);">
    <div class="container text-center ">
      <h2 class="fw-semi-bold mb-0 text-white py-1 py-md-3">SHOPPING CART</h2>

    </div>


  </header>


  <div class="container-fluid pb-6 pt-8 pb-xl-8">
    <div class="row">
      <div id="primary" class="content-area">
        <main id="main" class="site-main ">
          <div class="page type-page status-publish hentry">
          
            <div class="entry-content">
              <div class="woocommerce">
                <form class="woocommerce-cart-form table-responsive" action="#" method="post">
                  <h3>Course in Cart :</h3>

           
                  <ng-container *ngIf="cartCourse">
                    <ng-container *ngFor="let cart_Course of cartCourse;let i=index">
                      <ng-container *ngFor="let getCourse of cart_Course.mainCourse;let i=index">
                        <div class="card border shadow p-3">
                          <div class="row gx-0 align-items-center">
                         

                            <a [routerLink]="['/course-details/'+ cartTotal?.course_Id.toString() +'']"
                              class="col-auto d-block">
                              <img src="{{apiURL}}/{{getCourse.bannerImg}}"
                                class="attachment-shop_thumbnail size-shop_thumbnail wp-post-image img-fluid shadow-light-lg  h-90p w-90p h-md-90p o-f-c"
                                alt="">
                            </a>
                    
                            <div class="col">
                              <div class="card-body py-0 px-md-6 px-3">
                                <a href="" class="d-block me-xl-10">
                                  <h6 class="line-clamp-2 mb-0">{{getCourse.name}}</h6>
                                </a>
                                
                                <div class="d-flex flex-row text-dark">
                                  <input type="hidden" #course_id value="{{cart_Course.course_Id}}">
                                  <div (click)="removeFromCart(course_id.value)" class="p-2 ps-0 bd-highlight lh-1"><a
                                      data-purpose="" role="button" tabindex="0">Remove</a></div>
                                
                                  <input type="hidden" #course_id value="{{cart_Course.course_Id}}">
                                  <div (click)="moveToWishlist(course_id.value)" class="p-2 bd-highlight lh-1"><a
                                      data-purpose="" role="button" tabindex="0">Move to Whishlist</a></div>
                                </div>
                              </div>
                            </div>
                            <div class="col-auto d-none pe-xl-6 d-lg-flex w-130p align-self-stretch place-center">
                              <div class="w-100 text-center">
                               
                                <ins class="ms-3">₹ {{Math.round(getCourse.actual_Price)}}</ins>
                              
                              </div>
                            </div>
                          </div>
                          <br>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </form>
              </div>
            </div>
           
            <div class="entry-content mt-6">
              <div class="woocommerce">
                <form class="woocommerce-cart-form table-responsive" action="#" method="post">
                  <h3>Recently wishlisted :</h3>

                
                  <ng-container *ngIf="wishlist">
                    <ng-container *ngFor="let w of wishlist;let i=index">
                      <ng-container *ngFor="let c of w.mainCourse;let i=index">
                        <div class="card border shadow p-3">
                          <div class="row gx-0 align-items-center">
                           

                            <a [routerLink]="['/course-details']" class="col-auto d-block">
                              <img src="{{apiURL}}/{{c.bannerImg}}"
                                class="attachment-shop_thumbnail size-shop_thumbnail wp-post-image img-fluid shadow-light-lg  h-90p w-90p h-md-90p o-f-c"
                                alt="">
                            </a>
                           
                            <div class="col">
                              <div class="card-body py-0 px-md-6 px-3">
                                <a [routerLink]="['/course-details']" class="d-block me-xl-10">
                                  <h6 class="line-clamp-2 mb-0">{{ c.name }}</h6>
                                </a>
                               
                                <div class="d-flex flex-row text-dark">
                                  <input type="hidden" #course_id value="{{w.course_Id}}">
                                  <div (click)="removeToWishlist(course_id.value)" class="p-2 ps-0 bd-highlight lh-1"><a
                                      data-purpose="" role="button" tabindex="0">Remove</a></div>
                                  <input type="hidden" #course_id value="{{w.course_Id}}">
                                  <div (click)="moveToCart(course_id.value)" class="p-2 bd-highlight lh-1"><a
                                      data-purpose="" role="button" tabindex="0">Move to Cart</a></div>
                                </div>
                              </div>
                            </div>

                            <div class="col-auto d-none pe-xl-6 d-lg-flex w-130p align-self-stretch place-center">
                              <div class="w-100 text-center">
                               
                                <ins class="ms-3">₹ {{c.actual_Price}}</ins>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </form>
              </div>
            </div>
          
          </div>
        </main>
      </div>
      <div id="secondary" class="sidebar" role="complementary">
        <div class="cart-collaterals">
          <div class="cart_totals">
            <h2>Cart Total</h2>

            <table class="shop_table shop_table_responsive">
              <tbody>
                <tr>
                  <div class="d-flex align-items-center mb-2">
                   
                  </div>
                </tr>
              
                <tr class="cart-subtotal">
                  <th>Subtotal</th>
                  <td data-title="Subtotal"><span *ngIf="cartTotal?.subTotal"
                      class="woocommerce-Price-amount amount"><span
                        class="woocommerce-Price-currencySymbol">₹</span>{{cartTotal?.subTotal}}</span></td>
                </tr>
                <tr class="cart-gst">
                  <th>GST (18%)</th>
                  <td><span *ngIf="cartTotal?.gst" class="woocommerce-Price-amount amount"><span
                        class="woocommerce-Price-currencySymbol"></span>{{cartTotal?.gst}}</span></td>
                </tr>
                <tr class="cart-subtotal">
                  <th>Discount</th>
                  <td data-title="Subtotal"><span *ngIf="cartTotal?.discount"
                      class="woocommerce-Price-amount amount"><span
                        class="woocommerce-Price-currencySymbol">₹</span>{{cartTotal?.discount}}</span></td>
                </tr>
                <tr class="order-total">
                  <th>Total</th>
                  <td data-title="Total"><strong><span *ngIf="cartTotal?.total"
                        class="woocommerce-Price-currencySymbol">₹</span><span #Price
                        class="woocommerce-Price-amount amount">{{cartTotal?.total}}</span></strong>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="wc-proceed-to-checkout">
              <a [routerLink]="['/checkout']" (click)="storeAmount(Price)" class="btn btn-primary btn-sm btn-block">
                Proceed to Checkout
              </a>

            </div>

            
          </div>

        </div>
      </div>
    </div>
  </div>


  <div class="bubbles_wrap">
    <div class="bubble x1"></div>
    <div class="bubble x2"></div>
    <div class="bubble x3"></div>
    <div class="bubble x4"></div>
    <div class="bubble x5"></div>
    <div class="bubble x6"></div>
    <div class="bubble x7"></div>
    <div class="bubble x8"></div>
    <div class="bubble x9"></div>
    <div class="bubble x10"></div>
  </div>
</body> -->

<div class="rts-bread-crumbarea-1 rts-section-gap bg_image">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
              <div class="breadcrumb-main-wrapper">
                  <h1 class="title">Cart</h1>
                  <!-- breadcrumb pagination area -->
                  <div class="pagination-wrapper">
                      <a href="index.html">Home</a>
                      <i class="fa-regular fa-chevron-right"></i>
                      <a class="active" href="Instructor-2.html">Cart</a>
                  </div>
                  <!-- breadcrumb pagination area end -->
              </div>
          </div>
      </div>
  </div>
</div>

<main class="ms-main">
  <div class="ms-page-content">
      <!--================= Cart Area Start =================-->
      <article id="post-283" class="post-283 page type-page status-publish hentry">
          <div class="ms-default-page container">
              <div class="woocommerce">
                  <div class="woocommerce-notices-wrapper"></div>
                  <div class="ms-woocommerce-cart-form-wrapper">
                      <table class="shop_table shop_table_responsive cart woocommerce-cart-form__contents">
                          <thead>
                              <tr>
                                  <th class="product-remove">Action</th>
                                  <th class="product-thumbnail">Image</th>
                                  <th class="product-name">Product</th>
                                  <th class="product-price">Price</th>
                                  <th class="product-quantity">Quantity</th>
                                  <th class="product-subtotal">Subtotal</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr class="woocommerce-cart-form__cart-item cart_item" *ngFor="let course of cartCousesArray">
                                  <td class="product-remove">
                                      <a (click)="removeCourse(course)" class="remove" aria-label="Remove this item" data-product_id="470" data-product_sku="161056">
                                          <svg viewBox="0 0 200 200" width="18" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z">
                                              </path>
                                          </svg>
                                      </a>
                                  </td>
                                  <td class="product-thumbnail">
                                      <a href="product-details.html">
                                          <img  [src]="apiURL+'/'+course?.image" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="">
                                      </a>
                                  </td>
                                  <td class="product-name" data-title="Product">
                                      <a href="product-details.html">{{course?.name}}</a>
                                  </td>

                                  <td class="product-price" data-title="Price">
                                      <span class="woocommerce-Price-amount amount">
                                              <bdi><span class="woocommerce-Price-currencySymbol">&#8377;</span>{{course?.actual_Price}}</bdi>
                                      </span>
                                  </td>
                                  <td>
                                      <div class="cart-edit">
                                          <div class="quantity-edit">
                                              <button class="button" (click)="decreaseQuantity(course)"><i class="fal fa-minus minus"></i></button>
                                              <input type="text" class="input" [value]="course?.quantity">
                                              <button class="button plus" (click)="increaseQuantity(course)">+<i class="fal fa-plus plus"></i></button>
                                          </div>
                                      </div>
                                  </td>
                                  <td class="product-subtotal" data-title="Subtotal">
                                      <span class="woocommerce-Price-amount amount">
                                              <bdi><span class="woocommerce-Price-currencySymbol">&#8377;</span>{{course?.offer_price}}</bdi>
                                      </span>
                                  </td>
                              </tr>
                              <tr>
                                <td colspan="6" class="actions">
                                    <form class="woocommerce-cart-form" action="#" method="post">

                                        <div class="ms-actions-inner">
                                            <div class="coupon">
                                                <input type="text" name="coupon_code" class="input-text" id="coupon_code" value="" placeholder="Coupon code" required="">
                                                <button type="submit" class="button rts-btn btn-primary" name="apply_coupon" value="Apply coupon" style="white-space: nowrap !important;">Apply coupon</button>
                                            </div>
                                        </div>
                                    </form>
                                </td>
                            </tr>
                             
                              
                          </tbody>
                      </table>
                      <div class="text-center cart-head" *ngIf="cartCousesArray.length == 0;">
                        <h1 style="color: #000 !important;">Your cart is empty!</h1>
                      </div>
                      <div class="row">
                          <div class="col-md-5 offset-md-7">
                              <div class="ms-cart-collaterals cart-collaterals">
                                  <div class="ms-cart-totals cart_totals ">
                                      <h3 class="animated fadeIn cart-total">Cart totals</h3>
                                      <table class="shop_table shop_table_responsive">
                                          <tbody>
                                              <tr class="cart-subtotal">
                                                  <th>Subtotal</th>
                                                  <td data-title="Subtotal">
                                                      <span class="woocommerce-Price-amount amount">
                                                          <bdi><span class="woocommerce-Price-currencySymbol">&#8377;</span>{{cartSubtotalAmt}}</bdi>
                                                      </span>
                                                  </td>
                                              </tr>
                                              <tr class="order-total">
                                                  <th>Total</th>
                                                  <td data-title="Total">
                                                      <strong><span class="woocommerce-Price-amount amount">
                                                          <bdi><span class="woocommerce-Price-currencySymbol">&#8377;</span>{{cartSubtotalAmt}}</bdi></span>
                                                      </strong>
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                      <div class="ms-proceed-to-checkout wc-proceed-to-checkout">
                                          <a (click)="placeOrderPage()" class="rts-btn btn-primary"> Proceed to checkout</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="clearfix"></div>
          </div>
      </article>
      <!--================= Cart Area End =================-->

      <!--================= Services Area Start =================-->
      <!--================= Services Area End =================-->
  </div>
</main>


<div class="cart-bar">
  <div class="cart-header">
      <h3 class="cart-heading">MY CART (3 ITEMS)</h3>
      <div class="close-cart"><i class="fal fa-times"></i></div>
  </div>
  <div class="product-area">
      <div class="product-item">
          <div class="product-detail">
              <div class="product-thumb"><img src="assets/images/course/cart/01.jpg" alt="product-thumb"></div>
              <div class="item-wrapper">
                  <span class="product-name">Construct Map</span>
                  <div class="item-wrapper">
                      <span class="product-variation"><span class="color">Green /</span>
                      <span class="size">XL</span></span>
                  </div>
                  <div class="item-wrapper">
                      <span class="product-qnty">3 ×</span>
                      <span class="product-price">&#8377;198.00</span>
                  </div>
              </div>
          </div>
          <div class="cart-edit">
              <div class="quantity-edit">
                  <button class="button"><i class="fal fa-minus minus"></i></button>
                  <input type="text" class="input" value="3">
                  <button class="button plus">+<i class="fal fa-plus plus"></i></button>
              </div>
              <div class="item-wrapper d-flex mr--5 align-items-center">
                  <a href="#" class="product-edit"><i class="fal fa-edit"></i></a>
                  <a href="#" class="delete-cart"><i class="fal fa-times"></i></a>
              </div>
          </div>
      </div>
      <div class="product-item">
          <div class="product-detail">
              <div class="product-thumb"><img src="assets/images/course/cart/02.jpg" alt="product-thumb"></div>
              <div class="item-wrapper">
                  <span class="product-name"> Bridge product</span>
                  <div class="item-wrapper">
                      <span class="product-variation"><span class="color">Green /</span>
                      <span class="size">XL</span></span>
                  </div>
                  <div class="item-wrapper">
                      <span class="product-qnty">2 ×</span>
                      <span class="product-price">&#8377;88.00</span>
                  </div>
              </div>
          </div>
          <div class="cart-edit">
              <div class="quantity-edit">
                  <button class="button"><i class="fal fa-minus minus"></i></button>
                  <input type="text" class="input" value="2">
                  <button class="button plus">+<i class="fal fa-plus plus"></i></button>
              </div>
              <div class="item-wrapper d-flex mr--5 align-items-center">
                  <a href="#" class="product-edit"><i class="fal fa-edit"></i></a>
                  <a href="#" class="delete-cart"><i class="fal fa-times"></i></a>
              </div>
          </div>
      </div>
      <div class="product-item last-child">
          <div class="product-detail">
              <div class="product-thumb"><img src="assets/images/course/cart/03.jpg" alt="product-thumb"></div>
              <div class="item-wrapper">
                  <span class="product-name">Labour helmet</span>
                  <div class="item-wrapper">
                      <span class="product-variation"><span class="color">Green /</span>
                      <span class="size">XL</span></span>
                  </div>
                  <div class="item-wrapper">
                      <span class="product-qnty">1 ×</span>
                      <span class="product-price">&#8377;289.00</span>
                  </div>
              </div>
          </div>
          <div class="cart-edit">
              <div class="quantity-edit">
                  <button class="button"><i class="fal fa-minus minus"></i></button>
                  <input type="text" class="input" value="2">
                  <button class="button plus">+<i class="fal fa-plus plus"></i></button>
              </div>
              <div class="item-wrapper d-flex mr--5 align-items-center">
                  <a href="#" class="product-edit"><i class="fal fa-edit"></i></a>
                  <a href="#" class="delete-cart"><i class="fal fa-times"></i></a>
              </div>
          </div>
      </div>
  </div>
  <div class="cart-bottom-area">
      <span class="spend-shipping"><i class="fal fa-truck"></i> SPENT <span class="amount">&#8377;199.00</span> MORE
      FOR FREE SHIPPING</span>
      <span class="total-price">TOTAL: <span class="price">&#8377;556</span></span>
      <a href="checkout.html" class="checkout-btn cart-btn">PROCEED TO CHECKOUT</a>
      <a href="cart.html" class="view-btn cart-btn">VIEW CART</a>
  </div>
</div>


<div id="side-bar" class="side-bar header-two">
  <button class="close-icon-menu"><i class="far fa-times"></i></button>
  <!-- inner menu area desktop start -->
  <div class="inner-main-wrapper-desk">
      <div class="thumbnail">
          <img src="assets/images/banner/04.jpg" alt="elevate">
      </div>
      <div class="inner-content">
          <h4 class="title">We Build Building and Great Constructive Homes.</h4>
          <p class="disc">
              We successfully cope with tasks of varying complexity, provide long-term guarantees and regularly master new technologies.
          </p>
          <div class="footer">
              <h4 class="title">Got a project in mind?</h4>
              <a href="contact.html" class="rts-btn btn-primary">Let's talk</a>
          </div>
      </div>
  </div>
  <!-- mobile menu area start -->
  <div class="mobile-menu-main">
      <nav class="nav-main mainmenu-nav mt--30">
          <ul class="mainmenu metismenu" id="mobile-menu-active">
              <li >
                  <a href="index.html" class="main">Home</a>
              </li>
              <li >
                  <a href="about.html" class="main">About Us</a>
              </li>
              <li >
                  <a href="course-one.html" class="main">Course</a>
                  
              </li>
              <li >
                  <a href="contact.html" class="main">Contact Us</a>
              
              </li>
              <li >
                  <a href="FAQ.html" class="main">FAQ</a>
              
              </li>
          </ul>
      </nav>

      <div class="buttons-area">
          <a href="log-in.html" class="rts-btn btn-border">Log In</a>
          <a href="registration.html" class="rts-btn btn-primary">Sign Up</a>
      </div>

      <div class="rts-social-style-one pl--20 mt--50">
          <ul>
              <li>
                  <a href="#">
                      <i class="fa-brands fa-facebook-f"></i>
                  </a>
              </li>
              <li>
                  <a href="#">
                      <i class="fa-brands fa-twitter"></i>
                  </a>
              </li>
              <li>
                  <a href="#">
                      <i class="fa-brands fa-youtube"></i>
                  </a>
              </li>
              <li>
                  <a href="#">
                      <i class="fa-brands fa-linkedin-in"></i>
                  </a>
              </li>
          </ul>
      </div>
  </div>
  <!-- mobile menu area end -->
</div>

<div id="myModal-1" class="modal fade" role="dialog">
  <div class="modal-dialog bg_image">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" data-bs-dismiss="modal"><i class="fa-light fa-x"></i></button>
          </div>
          <div class="modal-body text-center">
              <div class="inner-content">
                  <div class="title-area">
                      <span class="pre">Get Our Courses Free</span>
                      <h4 class="title">Wonderful for Learning</h4>
                  </div>
                  <form action="#">
                      <input type="text" placeholder="Your Mail.." required>
                      <button>Download Now</button>
                      <span>Your information will never be shared with any third party</span>
                  </form>
              </div>
          </div>
      </div>
  </div>
</div>
<div class="progress-wrap">
  <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
      <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" style="transition: stroke-dashoffset 10ms linear 0s; stroke-dasharray: 307.919, 307.919; stroke-dashoffset: 307.919;"></path>
  </svg>
</div>

<div class="search-input-area">
  <div class="container">
      <div class="search-input-inner">
          <div class="input-div">
              <input class="search-input autocomplete" type="text" placeholder="Search by keyword or #">
              <button><i class="far fa-search"></i></button>
          </div>
      </div>
  </div>
  <div id="close" class="search-close-icon"><i class="far fa-times"></i></div>
</div>