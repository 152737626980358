import { Component, DoCheck, OnInit, Pipe, PipeTransform } from '@angular/core';
import { DatePipe, formatDate, } from '@angular/common';
import { CourseService } from 'src/app/courses/services/course.service'
import { IndexService } from 'src/app/shared/services/index.service'
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Course } from 'src/app/courses/modals/course';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CartService } from 'src/app/shared/services/cart.service';
import { TimeConvertorPipe } from 'src/app/shared/time-convertor.pipe'
import { cart } from 'src/app/shared/modals/cart';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';

@Component({
  selector: 'app-couse-single',
  templateUrl: './course-single.component.html',
  styleUrls: ['./course-single.component.scss']
})

export class CourseSingleComponent implements OnInit {
  Latestcourses: any;
  viewCourse = 0;
  relatedcourse: any;
  Id: any;
  apiURL = environment.url
  viewData!: Course;
  instructor!: any;
  lessondata!: any;
  viewRelateData: any;

  current_Date!: any;
  course_Date!: any;
  Days!: any;
  addreviewsForm!: FormGroup;
  courseComments: any;
  CourseTestimonials: any;
  CourseFaqs!: any;
  productList: any;
  client: any;
  course: any;
  storeId: any;
  duration: any;
  videoDetail: any;
  cartCourse!: cart[];
  cartTotal!: cart;
  logged_user = this.cryptojsService.decryptLocalAccessToken();
  userid = this.cryptojsService.decryptLocalUserId()
  btnname: any;

  isShown: boolean = true; //bydefault not show

  // flag!: boolean;
  detailsOfCourse:any;
  detailsOfCourseInfo:any;
  detailsOfCourseAuthorWiseStudentsCount:any;
  detailsOfCourseExpert:any;
  alerdyAddedToCart = false;
  constructor(private courseService: CourseService,
    private indexService: IndexService, private activateRoute: ActivatedRoute,
    private toastr: ToastrService, private formBuilder: FormBuilder, private router: Router,
    private cartService: CartService,
    private cryptojsService:CryptojsService
  ) { }

  ngOnInit(): void {
   
    this.getCourseDetails()
    this.checkCoursePresentOrNot()
    // this.getLatestCourses();
    // this.CourseWiseComments()
    // this.CourseTestimonial();
    // this.CourseFaq();
    // this.courseService.cartCourse(this.userid).subscribe((res) => {
    //   this.cartCourse = res;
    //   console.log(this.cartCourse, 'nnnnn');

    //   this.cartCourse.forEach(obj => {

    //     if (
    //       obj.client_Id == this.userid?.toUpperCase() &&
    //       obj.course_Id == this.viewCourse.toString().toUpperCase()
    //     ) {
    //       this.btnname = "GO TO CART";
    //     } else {
    //       this.btnname = "ADD TO CART";
    //     }

    //   });
    // });

    // this.activateRoute.params.subscribe(data => {
    //   this.viewCourse = data.Id.toString()
    //   // console.log(this.viewCourse);
    //   this.addreviewsForm = this.formBuilder.group({
    //     review: ['', Validators.required],
    //     star: ['', Validators.required],
    //   });

    // })

    // this.courseService.VideoWiseDetails(this.viewCourse, '').subscribe(res => {
    //   this.videoDetail = res;

    //   // console.log("videoDetail:", this.videoDetail);
    // })
    // this.courseService.ViewCourse(this.viewCourse).subscribe(view => {
    //   this.viewData = view[0];

    //   this.course_Date = new Date(view[0].offerValid_Tilldate)
    //   //console.log(this.course_Date)

    //   const course_Date = formatDate(this.course_Date, 'MM/dd/yyyy', 'en-US');
    //   this.course_Date = course_Date;

    //   let c_date = new Date()
    //   const current_Date = formatDate(c_date, 'MM/dd/yyyy', 'en-US');
    //   this.current_Date = current_Date

    //   let Time = new Date((this.course_Date)).getTime() - new Date((this.current_Date)).getTime()

    //   this.Days = Time / (1000 * 3600 * 24);
    //   // console.log(this.Days,'days')

    // })
    // // Related Courses
    // this.courseService.ViewRelatedCourse(this.viewCourse).subscribe(view => {
    //   //console.log(view,'relate');
    //   this.viewRelateData = view;
    //   //console.log("one relatecourse:", this.viewRelateData);

    // })

    // // Course Instructor 
    // this.courseService.courseInstructor(this.viewCourse).subscribe(res => {
    //   this.instructor = res;
    //   // console.log("instructor:", this.instructor)
    // })

    // this.courseService.refreshneeded
    //   .subscribe(() => {
    //     this.courseService.cartCourse(this.userid).subscribe((res) => {
    //       this.cartCourse = res;
    //       console.log(this.cartCourse, 'nnnnn');
    
    //       this.cartCourse.forEach(obj => {
    
    //         if (
    //           obj.client_Id == this.userid?.toUpperCase() &&
    //           obj.course_Id == this.viewCourse.toString().toUpperCase()
    //         ) {
    //           this.btnname = "GO TO CART";
    //         } else {
    //           this.btnname = "ADD TO CART";
    //         }
    
    //       });
    //     });
    //   });

  }


  getCourseDetails(){
    let id:any=this.activateRoute.snapshot.paramMap.get('id')
    this.cartService.checkCourseInsideCart({id:id})
    this.courseService.ViewMainCourse(id).subscribe((resViewMainCourse:any)=>{
      if(resViewMainCourse){
        resViewMainCourse.quantity=1;
        this.detailsOfCourse=resViewMainCourse  
      
        
        let authorId=this.detailsOfCourse?.authorId
        this.indexService.getExpert(authorId).subscribe((resExpert:any)=>{
          if(resExpert){
            this.detailsOfCourseExpert=resExpert
          }
        })
        
      }
    }) 

    this.courseService.ViewCourse(id).subscribe((resViewCourse:any)=>{
      if(resViewCourse.length > 0){
        this.detailsOfCourseInfo=resViewCourse[0]
        console.log("get data detailsOfCourseInfo=======================>",this.detailsOfCourseInfo);
      }
    })

    this.courseService.courseInstructor(id).subscribe((resInstructor:any)=>{
      if(resInstructor.length > 0){
        this.detailsOfCourseAuthorWiseStudentsCount=resInstructor[0]
        console.log("get data detailsOfCourseAuthorWiseStudentsCount=======================>",this.detailsOfCourseAuthorWiseStudentsCount);
      }
    })

   
  }


  checkCoursePresentOrNot(){
    this.cartService.coursePresentSignal$.subscribe((resCoursePresentOrNot:any)=>{
      this.alerdyAddedToCart=resCoursePresentOrNot
    })
  }
 


  buynow() {
    let user_info =this.cryptojsService.decryptLocalUserId()
    let access_token = this.cryptojsService.decryptLocalAccessToken()
    // console.log(user_info, access_token, 'user');

    if (user_info && access_token) {
      this.router.navigate(['/checkout']);
    } else {
      this.router.navigate(['/login']);
    }

  }

  playVideo(id: any) {
    this.courseService.LessonDetails(this.viewCourse).subscribe(res => {
      this.lessondata = res;
      // console.log(res);
      // console.log("lessondatas:", this.lessondata);
    })
  }

  getLatestCourses() {
    this.Latestcourses = [];
    this.courseService.getLatest().subscribe(response => {
      //console.log(response)
      this.Latestcourses = response
    }, err => {

    })
  }

  // Related Courses
  getRelatedCourses(course_id: any) {
    this.Latestcourses = [];
    this.courseService.getRelatedCourse(this.viewCourse).subscribe(response => {
      //console.log(response)
      this.Latestcourses = response
    }, err => {

    })
  }

  // Add TO Cart
  addToCart(course:any) {
    this.cartService.cartService(course)
   
  }

 

  // Review for course
  addReviewToCourse() {
    if (this.addreviewsForm.invalid) {
      return;
    }
    let approvalFlag = true
    let client_Id = this.cryptojsService.decryptLocalUserId()
    let course_Id = this.activateRoute.snapshot.paramMap.get('Id');
    //console.log(course_Id, client_Id)

    this.courseService.addReviewToCourse(client_Id, course_Id, this.addreviewsForm.value.review, this.addreviewsForm.value.star, approvalFlag)
      .subscribe(result => {
        //console.log(result)
        this.toastr.success("Review Added.")
      });
  }

  // Add course to wishlist
  addToWishlist() {
    let client_Id = this.cryptojsService.decryptLocalUserId()
    let course_Id = this.activateRoute.snapshot.paramMap.get('Id');
    //console.log(course_Id, client_Id)

    this.courseService.addToWishlist(client_Id, course_Id)
      .subscribe(results => {
        this.toastr.success("Course added successfully to Wishlist")
      }, err => {
        if (err.status === 409) {
          this.toastr.error("Course already in Wishlist.");
        }
      });
  }

  //Course Related Comments
  CourseWiseComments() {
    let Courseid = this.activateRoute.snapshot.paramMap.get('Id');
    this.courseService.CourseComments(Courseid).subscribe(res => {
      this.courseComments = res;
      // console.log("Coursecomments:", this.courseComments);
    })
  }
  //Course Related Comments
  CourseTestimonial() {
    let Courseid = this.activateRoute.snapshot.paramMap.get('Id');
    this.courseService.CourseTestimonial(Courseid).subscribe(res => {
      this.CourseTestimonials = res;
      // console.log("CourseTestimonials:", this.CourseTestimonials);
    })
  }

  //Course Related FAQ's
  CourseFaq() {
    let Courseid = this.activateRoute.snapshot.paramMap.get('Id');
    this.courseService.CourseRelatedFaq(Courseid).subscribe(res => {
      this.CourseFaqs = res;
      // console.log("CourseRelatedFaq:", this.CourseFaqs);
    })

  }


  goViewCart(){
    this.cryptojsService.userLoginSignal$.subscribe((status:any)=>{
      if(status && this.cartService.cartCoursePresent()){
        this.router.navigateByUrl('/view-cart')
      }else{
        this.toastr.warning("Please add at least one course to the cart!")
      }
    })
    
  }

}
