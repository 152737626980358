<!-- PAGE TITLE
    ================================================== -->
<!-- <header class="py-2 py-md-4 main-banner-in1 overlay overlay-primary overlay-70 jarallax" data-jarallax
        data-speed=".8" style="background-image: url(assets/img/covers/cover-19.jpg);">
        <div class="container-fluid text-center ">
            <h2 class="fw-semi-bold mb-0 text-white py-1 py-md-3">CONTACT US</h2>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-scroll justify-content-center py-1 ">
                    <li class="breadcrumb-item">
                        <a class="text-white" href="#">
                            Home
                        </a>
                    </li>
                    <li class="breadcrumb-item text-white active" aria-current="page">
                        Contact us
                    </li>
                </ol>
            </nav>
        </div>
    </header> -->


<!-- CONTACT
    ================================================== -->
<!-- <div class="container-fluid  main-banner-in1">
        <div class="row row-cols-md-2 mb-8 mb-lg-8 mt-1 mt-md-8">
            <div class="col-md-12  col-lg-6 cust-contact-bg1 main-banner-in" data-aos="fade-right" style="">

                <div class="text-center">
                    <img src="assets/img/contact.png" class="img-fluid rounded-lg floating" data-wow-delay=".14s" alt="..." >
                </div>
                              

               
            </div>

            <div class="col-md-12 col-lg-6" data-aos="fade-left">

                <div class="row row-cols-12 row-cols-xl-21">
                    <h2 class="mb-4 mt-4">Contact us</h2>
                    <div class="col-12 mb-6 col-md-6">

                       

                        <div class="media d-flex text-dark">
                            <div class="me-5">
                              
                                <i class="fas fa-route fa-lg "></i>

                            </div>
                            <div class="media-body flex-shrink-1 text-dark">
                                <h5 class="mb-4 text-dark">Find Us</h5>
                                <address class="mb-0">
                                  Bhaskar Colony, Naupada, 301, Maa Bhavani Apartment, Thane, Maharashtra 400603, 
                                    India
                                </address>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 mb-6 px-lg-6">
                        <div class="media d-flex text-dark">
                            <div class="me-5">
                              
                                <i class="fas fa-phone-alt fa-lg"></i>
                            </div>
                            <div class="media-body flex-shrink-1">
                                <h5 class="mb-4 text-dark">Phone</h5>
                                <a href="tel:3159052321" class="text-dark d-block">+(315) 905-2321</a>
                               
                            </div>
                        </div>
                    </div>



                    <div class="col-12 col-md-6 mb-6 px-lg-6">
                        <div class="media d-flex text-dark">
                            <div class="me-5">
                               
                                <i class="fas fa-envelope fa-lg"></i>

                            </div>
                            <div class="media-body flex-shrink-1">
                                <h5 class="mb-4 text-dark">Write to Us</h5>
                                <a href="mailto:info@udyami.com" class="text-dark">info@udyami.com</a>
                                
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mb-6 col-md-6">
                        <div class="media d-flex text-dark">
                            <div class="me-5">
                              
                                <i class="fas fa-share-alt"></i>

                            </div>
                            <div class="media-body flex-shrink-1">
                                <h5 class="mb-4 text-dark">Follow Us</h5>
                               
                                <ul class="list-unstyled list-inline list-social mb-4 mb-md-0 mx-n4">
                                    <li class="list-inline-item list-social-item">
                                        <a href="https://www.facebook.com/udyamii/" target="_blank"
                                            class="text-dark font-size-sm w-36 h-36 shadow-dark-hover d-flex align-items-center justify-content-center rounded-circle border-hover">
                                            <i class="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li class="list-inline-item list-social-item">
                                        <a href="#" target="_blank"
                                            class="text-dark font-size-sm w-36 h-36 shadow-dark-hover d-flex align-items-center justify-content-center rounded-circle border-hover">
                                            <i class="fab fa-twitter"></i>
                                        </a>
                                    </li>
                                    <li class="list-inline-item list-social-item">
                                        <a href="#" target="_blank"
                                            class="text-dark font-size-sm w-36 h-36 shadow-dark-hover d-flex align-items-center justify-content-center rounded-circle border-hover">
                                            <i class="fab fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li class="list-inline-item list-social-item">
                                        <a href="https://in.linkedin.com/company/udyami-maharashtra" target="_blank"
                                            class="text-dark font-size-sm w-36 h-36 shadow-dark-hover d-flex align-items-center justify-content-center rounded-circle border-hover">
                                            <i class="fab fa-linkedin-in"></i>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 class="mb-4 mt-4">Get In Touch</h2>

                <form [formGroup]="contactUsForm" (ngSubmit)="contactUs()">
                    <div class="form-group mb-3">
                       
                        <label for="exampleInputTitle1">Name</label>
                        <input type="text" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" formControlName="name" class="form-control placeholder-1" id="name" placeholder="Enter Name">
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Name is required</div>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <label for="cardEmail">Email</label>
                        <input type="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email" class="form-control placeholder-1" id="Email" placeholder="Email"email="true">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors?.email">Email must be a valid email address</div>
                        </div> 
                    </div>
                    <div class="form-group mb-3">
                        <label for="cardEmail">Mobile no</label>
                        <input type="number" [ngClass]="{ 'is-invalid': submitted && f.phone_Number.errors }" formControlName="phone_Number" class="form-control placeholder-1" id="phone_Number" placeholder="Mobile no">
                        <div *ngIf="submitted && f.phone_Number.errors" class="invalid-feedback">
                            <div *ngIf="f.phone_Number.errors.required">Phone Number is required</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="exampleFormControlTextarea1">Message</label>
                        <textarea class="form-control form-control-sm "
                            id="message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }" formControlName="message" rows="2" placeholder="Content"></textarea>
                            <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                <div *ngIf="f.message.errors.required">Message is required</div>
                            </div>
                    </div>
                    <div class="col-12 mt-6">
                        <button type="submit" class="btn btn-primary btn-sm btn-block mw-md-300p">SEND</button>
                    </div>
                </form>

            </div>
        </div>

        <div class="mb-8 mb-md-8 rounded overflow-hidden grayscal-hover" data-aos="fade-up">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30145.285270319273!2d72.966896!3d19.188184!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b8e1d6e03c7f%3A0x4f32fb04176deb53!2sBhaskar%20Colony%2C%20Shivaji%20Nagar%2C%20Thane%20West%2C%20Thane%2C%20Maharashtra%20400602%2C%20India!5e0!3m2!1sen!2sus!4v1643615412870!5m2!1sen!2sus"
                width="100%" height="350" style="border:0;width: 100%;" allowfullscreen="" aria-hidden="false"
                tabindex="0"></iframe>
               
        </div>
    </div> -->

<div class="rts-bread-crumbarea-1 rts-section-gap bg_image">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="breadcrumb-main-wrapper">
                    <h1 class="title">Contact Us</h1>

                    <div class="pagination-wrapper">
                        <a routerLink="/index">Home</a>
                        <i class="fa-regular fa-chevron-right"></i>
                        <a class="active" href="contact.html">Contact us</a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="rts-contact-area rts-section mt-5 mb-5 rts-section-gap">
    <div class="container">
        <div class="row g-5">
            <div class="col-xl-5">

                <div class="contact-left-area-start">
                    <div class="title-area-left-style">
                        <div class="pre-title">
                            <img src="assets/images/banner/bulb.png" alt="icon">
                            <span>Courses</span>
                        </div>
                        <h2 class="title">Get in touch!
                            <br>
                            <span>Feel free to connect with us</span>
                        </h2>
                    </div>
                    <form [formGroup]="contactUsForm" method="post" class="contact-page-form" id="contact-form"
                        (ngSubmit)="contactUs()">
                        <div class="single-input">
                            <label for="name">Enter Full Name<span class="text-danger">*</span></label>
                            <input id="name" name="name" type="text" placeholder="Enter Full Name"
                                formControlName="name">
                            <div class="text-danger"
                                *ngIf="contactUsForm.controls['name'].invalid && contactUsForm.controls['name'].touched">
                                Full Name is required
                            </div>
                        </div>
                        <div class="single-input">
                            <label for="email">Enter Your Email<span class="text-danger">*</span></label>
                            <input id="email" name="email" type="email" placeholder="Enter Email"
                                formControlName="email">
                            <div *ngIf="contactUsForm.get('email')?.invalid && contactUsForm.get('email')?.touched">
                                <span class="text-danger" *ngIf="contactUsForm.get('email')?.errors?.required">Email is
                                    required.</span>
                                <span class="text-danger" *ngIf="contactUsForm.get('email')?.errors?.pattern">Email must
                                    contain '&#64;' and end with '.com'.</span>
                            </div>
                        </div>
                        <div class="single-input">
                            <label for="mobile">Enter Your Phone Number<span class="text-danger">*</span></label>
                            <input id="mobile" name="mobile" type="text" maxlength="10"
                                placeholder="Enter Mobile Number" formControlName="phone_Number">
                            <div
                                *ngIf="contactUsForm.get('phone_Number')?.invalid && contactUsForm.get('phone_Number')?.touched">
                                <span class="text-danger"
                                    *ngIf="contactUsForm.get('phone_Number')?.errors?.required">Number is
                                    required.</span>
                                <span class="text-danger"
                                    *ngIf="contactUsForm.get('phone_Number')?.errors?.pattern">Only numbers are
                                    allowed.</span>
                                <span class="text-danger"
                                    *ngIf="contactUsForm.get('phone_Number')?.errors?.maxlength">Maximum length is
                                    10.</span>
                                <span class="text-danger"
                                    *ngIf="contactUsForm.get('phone_Number')?.errors?.minlength">Manimum length is
                                    10.</span>
                            </div>
                        </div>
                        <div class="single-input">
                            <label for="message">Message<span class="text-danger">*</span></label>
                            <textarea id="message" name="message" placeholder="Let tell us about your projects"
                                formControlName="message"></textarea>
                            <div class="text-danger"
                                *ngIf="contactUsForm.controls['message'].invalid && contactUsForm.controls['message'].touched">
                                Message is required
                            </div>
                        </div>


                        <div class="button-container">
                            <button type="submit" style="padding: 10px 40px; font-size: 17px ; margin-right: 10px;"
                                class="rts-btn btn-border">Submit</button>
                            <a (click)="handleClick()" 
                            [attr.data-bs-toggle]="attributesAdded ? modalToggle : null" 
                            [attr.data-bs-target]="attributesAdded ? modalTarget : null" 
                            [attr.id]="attributesAdded ? buttonId : null" 
                                class="rts-btn btn-primary">Admission Form</a>
                        </div>

                    </form>
                    <div id="form-messages" class="mt--20"></div>
                </div>

            </div>
            <div class="col-xl-7 pl--50 pl_lg--15 pl_md--15 pl_sm--15 pb_md--100 pb_sm--100">
                <div class="contact-map-area-start">
                    <div class="single-maptop-info">
                        <div class="icon">
                            <img src="assets/images/contact/02.png" alt="contact">
                        </div>
                        <p class="disc">
                            Vile Parle, Mumbai, India
                        </p>
                    </div>
                    <div class="single-maptop-info">
                        <div class="icon">
                            <img src="assets/images/contact/03.png" alt="contact">
                        </div>
                        <a href="tel:+4733378901">+91 9922992299</a> <br>
                        <a href="tel:+4733378901">+91 9922992299</a>
                    </div>
                    <div class="single-maptop-info">
                        <div class="icon">
                            <img src="assets/images/contact/04.png" alt="contact">
                        </div>
                        <p class="disc">
                            All days: 9 AM - 6PM

                        </p>
                    </div>
                </div>
                <div class="map-bottom-area mt--30">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30161.87589165459!2d72.84145665192014!3d19.09736640912719!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9b42151fed3%3A0xac3b84f7db9d9318!2sVile%20Parle%2C%20Mumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1725019948964!5m2!1sen!2sin"
                        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="bubbles_wrap">
    <div class="bubble x1"></div>
    <div class="bubble x2"></div>
    <div class="bubble x3"></div>
    <div class="bubble x4"></div>
    <div class="bubble x5"></div>
    <div class="bubble x6"></div>
    <div class="bubble x7"></div>
    <div class="bubble x8"></div>
    <div class="bubble x9"></div>
    <div class="bubble x10"></div>
</div>