<div style="padding: 50px;" class="rts-students-feedback-area ">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section-title-w-style-center">
                    <h2 class="title">Students Testimonial</h2>
                    <p>Hear What Our Students Are Buzzing About!</p>
                </div>
            </div>
        </div>
        <div class="row mt--50">
            <div class="col-lg-12">
                <div class="swiper-feedback-wrapper-5">
                    <div class="swiper swiper-data" data-swiper='{
                            "spaceBetween":30,
                            "slidesPerView":3,
                            "loop": true,
                            "navigation":{
                                "nextEl":".swiper-button-next",
                                "prevEl":".swiper-button-prev"
                            },
                            "pagination":{
                                "el":".swiper-pagination",
                                "clickable":"true"
                            },
                            "autoplay":{
                                "delay":"2000"
                            },
                            "breakpoints":{"320":{
                                "slidesPerView":1,
                                "spaceBetween":30},
                            "480":{
                                "slidesPerView":1,
                                "spaceBetween":30},
                            "640":{
                                "slidesPerView":2,
                                "spaceBetween":30},
                            "940":{
                                "slidesPerView":2,
                                "spaceBetween":30},
                            "1140":{
                                "slidesPerView":3,
                                "spaceBetween":30}
                            }
                        }'>
                        <div class="swiper-wrapper" >
                            <div class="swiper-slide" *ngFor="let testimonial of studentTestimonial">
                                <!-- single students feedbacka rea start -->
                                <div class="single-students-feedback-5">
                                    <div class="stars">
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-regular fa-star"></i>
                                    </div>
                                    <p class="disc">
                                        {{ truncateReview(testimonial.reviews, 70) }}
                                    </p>
                                    <div class="authore-area">
                                        <img src="assets/images/students-feedback/02.png" alt="feedback">
                                        <div class="author">
                                            <h6 class="title">{{testimonial.clientName}}</h6>
                                            <span>Assistant Teacher</span>
                                        </div>
                                    </div>
                                    <div class="quote">
                                        <img src="assets/images/students-feedback/19.png" alt="feedback">
                                    </div>
                                </div>
                                <!-- single students feedbacka rea end -->
                            </div>
                           
                        </div>
                        <div class="left-align-arrow-btn">
                            <div class="swiper-button-next"><i class="fa-solid fa-chevron-right"></i></div>
                            <div class="swiper-button-prev"><i class="fa-solid fa-chevron-left"></i></div>
                            <div class="swiper-pagination"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>