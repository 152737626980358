import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CourseService } from 'src/app/courses/services/course.service';
import { environment } from 'src/environments/environment';
import { Course } from '../../modals/course';
//import { Course } from 'src/app/shared/modals/course';
import { Expert } from '../../modals/expert';
import { Testimonials } from '../../modals/testimonials';
import { IndexService } from '../../services/index.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from '../../services/account.service';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  courses: any;
  courseDisplay:any;
  experts: any;
  testimonials: any;
  users: any;
  course: Course[] = [];
  expert: Expert[] = [];
  zoom: any;
  data = [];
  apiURL = environment.url;
  exp: any;
  cards: any;
  duration: any;
  contactUsForm!: FormGroup;
  submitted = false;

  constructor(private indexService: IndexService, private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder,
    private toastr: ToastrService,private accountservice: AccountService,private cryptojsService:CryptojsService
  ) { }



  ngOnInit(): void {
    this.contactUsFormFunction()
    this.getAllCourses();
    this.getAllExpert();
    this.getAllTestimonials();
    this.getZoomMeet();
   
    // let expert_Id = this.route.snapshot.paramMap.get('id');
    // console.log(expert_Id,"111111111111111111111111111111111111111111111")
    // this.indexService.getExpert(expert_Id).subscribe(res =>{
    // this.exp = res;
    // console.log(this.exp,'rrrrrrrr')
    // })
  }

  

  contactUsFormFunction() {
    this.contactUsForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required,  Validators.pattern(/^[^@]+@[^@]+\.[a-z]{2,}$/)]],
      phone_Number: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(10), Validators.minLength(10)]],
      message: ['', Validators.required],
    });
  }

  getAllCourses() {
    this.courses = [];
    this.indexService.getCourse().subscribe(response => {
      this.courses = response['items'];
      console.log("get data---->",this.courses);
      if(this.courses.length > 0){
        this.courseDisplay=this.courses[this.courses.length - 1]
        console.log(this.courseDisplay, 'nn');
      }
     

      // for (var a = 0; a < this.courses.length; a++) {

      //   let dur = this.courses[a].total_Duration;

      //   var hms = dur;
      //   var insec = hms.split(':');
      //   var seconds = (insec[1]) * 60 * 60 + (+insec[2]) * 60 + (+insec[3]);
      //   this.duration = seconds / 60
      //   //console.log(this.duration,'nn');
      // }

      // console.log(this.courses,"ccccccccc")
    }, err => {

    })

  }

  getAllExpert() {
    this.indexService.getExpertTeam().subscribe(res => {
      this.experts = res['items'];
      // console.log(this.experts,'kkkkk');
    }, err => {

    })
  }

  // getExpert(Id: any) {

  // }

  displayStyle = "none";
  openDetails() {
    this.displayStyle = "block";
    // this.router.navigate(['', Id]);

    // this.route.paramMap.subscribe(params => {
    // this.expert_Id = params.get("id")
    // console.log(this.expert_Id,"gkjjnnkjnkl")
    // })
  }
  closePopup() {
    this.displayStyle = "none";
  }

  getAllTestimonials() {
    this.indexService.getTestimonials().subscribe(res => {
      this.testimonials = res['items'];
      console.log(this.testimonials, 'll');
    }, err => {

    })

  }

  getZoomMeet() {
    this.zoom = [];
    this.indexService.zoomMeet().subscribe(res => {
      this.zoom = res['items'];
      //console.log(this.zoom,'ZOOM');
    }, err => {

    })

  }

  onActivate() {
    console.log("get call")
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }


  contactUs() {
    this.submitted = true;
    if (this.contactUsForm.invalid) {
      this.contactUsForm.markAllAsTouched()
      return;
    }

    this.indexService.contactUs(this.contactUsForm.value)
      .subscribe(result => {
        //console.log(result)
        this.toastr.success("Form submitted Successfully.")
        this.contactUsForm.reset();
        this.submitted = false;
      });

  }

}
