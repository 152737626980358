  <!-- FOOTER
    ================================================== -->
    <footer class="pt-8 pt-md-8 bg-light">
        <div class="container">
            <div class="row" id="accordionFooter">
                <div class="col-12 col-md-4 col-lg-4">

                    <!-- Brand -->
                    <div class="mx-auto">
                        <img src="assets/images/Antarkar%20Sir_s%20logo%20color.png" alt="Udyami Maharashtra"
                            class="footer-brand img-fluid mb-4 h-130p">
                    </div>
                    <!-- Text -->
                    <p class="text-gray-800 mb-2 font-size-sm-alone">
                        We are passionate about education and dedicated to providing high- quality learning for aspiring JEE, BITSAT, IAT, MHT CET students
                    </p>

                    <div class="mb-2">
                        <a href="mailto:support@udyami.com"
                            class="text-gray-800 font-size-sm-alone">info@antllp.com</a>
                    </div>

                    <!-- Social -->
                    <ul class="list-unstyled list-inline list-social mb-4 mb-md-0">
                        <li class="list-inline-item list-social-item">
                            <a href="https://www.facebook.com/activeneuronstechnology" target="_blank"
                                class="text-dark font-size-sm w-36 h-36 shadow-dark-hover d-flex align-items-center justify-content-center rounded-circle border-hover">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li class="list-inline-item list-social-item">
                            <a href="https://x.com/i/flow/login?redirect_after_login=%2FANTLLP" target="_blank"
                                class="text-dark font-size-sm w-36 h-36 shadow-dark-hover d-flex align-items-center justify-content-center rounded-circle border-hover">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li class="list-inline-item list-social-item">
                            <a href="https://www.instagram.com/ant_llp/" target="_blank"
                                class="text-dark font-size-sm w-36 h-36 shadow-dark-hover d-flex align-items-center justify-content-center rounded-circle border-hover">
                                <i class="fab fa-instagram"></i>
                            </a>
                        </li>
                        <li class="list-inline-item list-social-item">
                            <a href="https://www.linkedin.com/in/active-neurons-technology-llp-270860263/" target="_blank"
                                class="text-dark font-size-sm w-36 h-36 shadow-dark-hover d-flex align-items-center justify-content-center rounded-circle border-hover">
                                <i class="fab fa-linkedin-in"></i>
                            </a>
                        </li>
                    </ul>
                </div>

                <div style="margin-top: 60px;" class="col-12 col-md-2 col-lg-2">
                    <div class="mb-5 mb-xl-0 footer-accordion">

                        <!-- Heading -->
                        <div id="widgetOne">
                            <h5 class="mb-5 ">
                                <button class="text-dark fw-medium footer-accordion-toggle d-flex align-items-center"
                                    type="button" data-bs-toggle="collapse" data-bs-target="#widgetcollapseOne"
                                    aria-expanded="true" aria-controls="widgetcollapseOne">
                                    <h5 class="mb-0"> Explore </h5>
                                    <span class="ms-auto text-dark">
                                        <!-- Icon -->
                                        <i class="fas fa-plus"></i>

                                        <i class="fas fa-minus"></i>

                                    </span>
                                </button>
                            </h5>
                        </div>

                        <div id="widgetcollapseOne" class="collapse show" aria-labelledby="widgetOne"
                            data-parent="#accordionFooter">
                            <!-- List -->
                            <ul class="list-unstyled text-gray-800 font-size-sm-alone mb-6 mb-md-8 mb-lg-0">
                                <li class="mb-3">
                                    <a [routerLink]="['/index']" class="text-reset">
                                        Home
                                    </a>
                                </li>
                                <li class="mb-3">
                                    <a [routerLink]="['/about-us']" class="text-reset">
                                        About Us
                                    </a>
                                </li>
                                <li class="mb-3">
                                    <a [routerLink]="['/course/courses']" class="text-reset">
                                        Course
                                    </a>
                                </li>
                                <li class="mb-3">
                                    <a [routerLink]="['/contact-us']" class="text-reset">
                                        Contact Us
                                    </a>
                                </li>
                                <li class="mb-3">
                                    <a [routerLink]="['/faq']" class="text-reset">
                                        FAQ
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-12 col-md-2 col-lg-2">
                    <div class="mb-5 mb-xl-0 ms-xl-6 footer-accordion">

                       
                        <div id="widgetTwo">
                            <h5 class="mb-5">
                                <button class="text-dark fw-medium footer-accordion-toggle d-flex align-items-center"
                                    type="button" data-bs-toggle="collapse" data-bs-target="#widgetcollapseTwo"
                                    aria-expanded="false" aria-controls="widgetcollapseTwo">
                                    <h5 class="mb-0">Policies </h5>
                                    <span class="ms-auto text-dark">
                                       
                                        <i class="fas fa-plus"></i>

                                        <i class="fas fa-minus"></i>

                                    </span>
                                </button>
                            </h5>
                        </div>

                        <div id="widgetcollapseTwo" class="collapse" aria-labelledby="widgetTwo"
                            data-parent="#accordionFooter">
                          
                            <ul class="list-unstyled text-gray-800 font-size-sm-alone mb-6 mb-md-8 mb-lg-0">
                                <li class="mb-3">
                                    <a href="terms.html" class="text-reset">
                                        Terms of use
                                    </a>
                                </li>
                                <li class="mb-3">
                                    <a href="privacy-policy.html" class="text-reset">
                                        Privacy Policy
                                    </a>
                                </li>
                                <li class="mb-3">
                                    <a href="sitemap.html" class="text-reset">
                                        Sitemap
                                    </a>
                                </li>
                                <li class="mb-3">
                                    <a href="help.html" class="text-reset">
                                        Help
                                    </a>
                                </li>
                                <li class="mb-3">
                                    <a href="#" class="text-reset">
                                        Affiliate Program
                                    </a>
                                </li>
                                <li class="mb-3">
                                    <a href="careers.html" class="text-reset">
                                        Careers
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div> -->

                <div style="margin-top: 60px;"  class="col-12 col-md-4  col-lg-4">
                    <div class="mb-5 mb-xl-0 ms-xl-6 footer-accordion">

                        
                        <!-- <div id="widgetThree">
                            <h5 class="mb-5"> Subscribe Now </h5>
                        </div> -->


                        <p class="mb-5 font-size-sm"><i style="color: #553cdf;" class="fa-regular fa-location-dot"></i> Vile Parle, Mumbai, India</p>
                        <p class="mb-5 font-size-sm"><i style="color: #553cdf;" class="fa-regular fa-phone mt-3"></i> +91 9922992299</p>
                        <!-- <div>
                            <form [formGroup]="form" (ngSubmit)="onSubmit()">

                                <div class="form-group mb-5">
                                    <input type="text" formControlName="sendmail" class="form-control form-control-sm" id="sendmail"
                                        placeholder="Email"email="true">
                                        <div *ngIf="submitted && f.sendmail.errors" class="invalid-feedback">
                                            <div *ngIf="f.sendmail.errors.required">Email is required</div>
                                            <div *ngIf="f.sendmail.errors.email"> Email must be a valid email address</div>
                                        </div>
                                </div>
            
                                <button class="btn btn-block btn-primary btn-sm" type="submit">
                                    Subscribe
                                </button>
                            </form>
                        </div> -->
                    </div>
                </div>

            </div> <!-- / .row -->
        </div> <!-- / .container -->
       
                
                <hr>
               
          
        <div class="container">
            <div class="row">
                <div class="col-12 ">
                    <div
                        class=" pb-5 py-md-4 text-center text-xl-start d-flex flex-column d-md-block d-xl-flex flex-xl-row align-items-center">
                        <p
                            class="text-gray-800 font-size-sm-alone d-block mb-0 mb-md-2 mb-xl-0 order-1 order-md-0 px-9 px-md-0">
                            Copyright © 2024 All Rights Reserved by Antarkar Sir’s Educative Programmes</p>

                        <div
                            class="ms-xl-auto d-flex flex-column flex-md-row align-items-stretch align-items-md-center justify-content-center">


                            <select
                                class="form-select form-select-sm font-size-sm-alone shadow min-width-140 text-left mb-4 mb-md-0"
                                data-choices>
                                <option>English</option>
                                <option>मराठी</option>

                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
   