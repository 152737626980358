import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../services/account.service';
import { ToastrService } from 'ngx-toastr';
import { cart } from '../../modals/cart';
import { environment } from 'src/environments/environment';
import { IndexService } from '../../services/index.service';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  changePasswordForm!: FormGroup;
  editProfile!: FormGroup;
  isSubmitted: boolean = false;
  loading = false;
  details: any;
  phoneNumber: any;
  surname: any;
  email: any;
  name: any;
  auth_token!: any;
  wishlist!: cart[]
  apiURL = environment.url
  Id: any; //localStorage.getItem("UserId")
  reviews!: any;
  subscription!: any;
  submitted = false;

  getPatchResponseValue: any;
  showpassword:boolean=false;
  shownewpassword:boolean=false;
  constructor(private formBuilder: FormBuilder,
    private router: Router, private accountservice: AccountService, private indexservice: IndexService, private route: ActivatedRoute, private toastr: ToastrService,
    private cryptojsService:CryptojsService
  ) { }

  ngOnInit(): void {
    this.editFormFunction()
    this.editPasswordForm()
    this.Id = this.cryptojsService.decryptLocalUserId()
    console.log("get use id", this.Id);
    // this.profileDetails(this.auth_token);
    // this.mySubscription();
    this.userLoginUserData()

  }

  editPasswordForm() {
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
    });
  }

  userLoginUserData() {
    this.accountservice.getLoginUserDetails(this.Id).subscribe((resGetLoginDetails: any) => {
      this.editProfile.controls['userName'].patchValue(resGetLoginDetails.userName)
      this.editProfile.controls['name'].patchValue(resGetLoginDetails.name)
      this.editProfile.controls['surname'].patchValue(resGetLoginDetails.surname)
      this.editProfile.controls['email'].patchValue(resGetLoginDetails.email)
      this.editProfile.controls['phoneNumber'].patchValue(JSON.stringify(resGetLoginDetails.phoneNumber))
      // this.editProfile.patchValue(resGetLoginDetails)

    })
  }

  editFormFunction() {
    this.editProfile = this.formBuilder.group({
      userName: ['', Validators.required],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['',  [Validators.required,  Validators.pattern(/^[^@]+@[^@]+\.[a-z]{2,}$/)]],
      phoneNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(10), Validators.minLength(10)]],
    })

  }

  onSubmit() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }

    this.loading = true;

    this.accountservice.changepassword(this.changePasswordForm.value.currentPassword, this.changePasswordForm.value.newPassword)
      .subscribe(_result => {
        this.toastr.success('Password updated successfully..');
        this.changePasswordForm.reset()
      }, error => {
        if (error) {
          this.toastr.error(error.error.error.message);
        }
      });
  }

  EditProfile() {
    console.log("get data", this.editProfile)
    this.submitted = true;
    if (this.editProfile.invalid) {
      this.editProfile.markAllAsTouched()
      return;
    }

    this.loading = true;
    this.accountservice.editProfile(this.editProfile.value)
      .subscribe(result => {
        this.toastr.success('Profile updated successfully.');
        this.editProfile.reset()
      }, error => {
        if (error) {
          this.toastr.error(error.error.error.message);
        }
      });
  }

  profileDetails(auth_token: any) {
    auth_token = this.cryptojsService.decryptLocalAccessToken()
    this.accountservice.profileDetails(auth_token).subscribe(res => {
      console.log("get res====>", res);

      this.details = res;
      this.editProfile.controls.name.setValue(res.name);
      this.editProfile.controls.surname.setValue(res.surname);
      this.editProfile.controls.phoneNumber.setValue(res.phoneNumber);
      this.editProfile.controls.email.setValue(res.email);

    })

  }

  myWishlist() {
    this.indexservice.myWishlist(this.Id).subscribe(res => {
      this.wishlist = res;
      // console.log("wishlist:", this.wishlist);
    })
  }

  myReviews() {
    this.accountservice.myReview(this.Id).subscribe(response => {
      this.reviews = response
      //console.log("reviews:", this.reviews);
    })
  }

  mySubscription() {
    this.accountservice.mySubscription(this.Id).subscribe(response => {
      this.subscription = response
      console.log("subscription:", this.subscription);
    })
  }

  displayPassWord(){
    this.showpassword = !this.showpassword;
  }

  displayNewPassWord(){
    this.shownewpassword = !this.shownewpassword;
  }
}


