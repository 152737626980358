import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IndexService } from 'src/app/shared/services/index.service';
import { environment } from 'src/environments/environment';
import { CourseService } from '../services/course.service';
import { TimeConvertorPipe } from 'src/app/shared/time-convertor.pipe'
import { CryptojsService } from 'src/app/core/services/cryptojs.service';
@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {
  courses: any=[];
  apiURL = environment.url;
  star: number = 50;
  Math: any;
  course_Date: any;
  current_Date: any;
  Days: any;
  id: any;
  price: any;
  courseCategory:any=[]
  constructor(private indexService: IndexService, private ActivateRoute: ActivatedRoute,
    private courseService: CourseService, private toastr: ToastrService,
    private cryptojsService:CryptojsService
  ) { }

  ngOnInit(): void {
    this.getAllCourseCategory();
    this.getAllCourses();
    this.Math = Math;
  }

  getAllCourseCategory(){
    this.indexService.getCategory().subscribe((resCategory:any)=>{
      if(resCategory.items){
        this.courseCategory=resCategory.items
        // console.log("get value",this.courseCategory);
      }
    })
  }

  getAllCourses() {
    this.courses = [];
    this.indexService.getCourse().subscribe(response => {
      // console.log(response, 'coursedata');

      this.courses = response['items'];
      // console.log("get courses",this.courses);
      
      for (var offerValid of this.courses) {
        let ofr = offerValid.offerValid_Tilldate;
        this.star = offerValid.star;

        let star = this.star;
        this.course_Date = new Date(ofr)

        // console.log(this.course_Date, "sdgfuyhdsgfdhfchj")

        const course_Date = formatDate(this.course_Date, 'MM/dd/yyyy', 'en-US');
        this.course_Date = course_Date
        // console.log(this.course_Date, "dhfchj")

        let c_date = new Date()
        const current_D = formatDate(c_date, 'MM/dd/yyyy', 'en-US');
        this.current_Date = current_D

        let Time =new Date((this.course_Date)).getTime() - new Date((this.current_Date)).getTime(); 

        this.Days = Time / (1000 * 3600 * 24);
        // console.log(this.Days, 'days')

      }
      //console.log(this.courses);  
    }, err => {

    })
  }

  addToWishlist() {
    let client_Id = this.cryptojsService.decryptLocalUserId()
    let course_Id = this.id;
    console.log(course_Id, client_Id)

    this.courseService.addToWishlist(client_Id, course_Id)
      .subscribe(results => {
        //console.log(results)
        this.toastr.success("Course added successfully to Wishlist")
      });
  }

  courseId(id: any) {
    this.id = id;
    let client_Id = this.cryptojsService.decryptLocalUserId()
    let course_Id = this.id;
   // console.log(course_Id, client_Id,'text')

    this.courseService.addToWishlist(client_Id, course_Id)
      .subscribe(results => {
        //console.log(results)
        this.toastr.success("Course added successfully to Wishlist")
      });
    
  }

  changeCategory(event:any){
    console.log("get data",event.target.value);
    
  }
}
