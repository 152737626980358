import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { cart } from 'src/app/shared/modals/cart';
import { ViewCartComponent } from 'src/app/cart/view-cart/view-cart.component'
//import { User } from '../../modals/user';
//import { AccountService } from '../../services/account.service';
import { IndexService } from '../../services/index.service';
import { HttpClient } from '@angular/common/http';
import { CartService } from '../../services/cart.service';
import { CourseService } from 'src/app/courses/services/course.service';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  admissionForm!:FormGroup;
  cartCourse!: cart[];
  apiURL = environment.url
  count!: number
  jQuery: any;

  searchcourse: any = [];
  name: any;
  logged_user:boolean= false;
  Id = this.cryptojsService.decryptLocalUserId()
  cartTotal!: cart;
  totalItem: number = 0;
  cartCousesArray:any=[]
  cartSubtotalAmt:number=0;

  searchCourseList:any=[]
  searchInput:any=''

  constructor(public router: Router, private toastr: ToastrService, private indexService: IndexService, private course: CourseService, private http: HttpClient, private cartService: CartService
    ,private cryptojsService:CryptojsService,private formBuilder:FormBuilder
  ) {
    this.cartCourses()
  }
  ngOnInit(): void {
    this.createAdmissionForm()
    this.checkUserLoginOrNot()
    let Id = this.cryptojsService.decryptLocalUserId()
    this.userCartCourses()
  }

  createAdmissionForm(){
    this.admissionForm=this.formBuilder.group({
      name:['',Validators.required],
      fathername:['',Validators.required],
      surname:['',Validators.required],
      birthdate:['',Validators.required],
      guardianmobileno:['',[Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(10), Validators.minLength(10)]],
      yearofappearingforjee:['',Validators.required],
      studenttype:['',Validators.required],
      howdidyoucometoknowaboutthiscourse:['',Validators.required],

    })
  }

  userCartCourses(){
    this.cartService.getCartCourse().subscribe((cart:any) => {
      if(cart.length > 0){
        this.cartCousesArray=cart
        this.cartSubtotalAmt=this.cartCousesArray.reduce((acc:any,curr:any)=>{return acc+(curr.offer_price * curr.quantity)},0)
      }else{
        this.cartCousesArray=[]
        this.cartSubtotalAmt=0;
      }
    });
  }

  checkUserLoginOrNot(){
    this.cryptojsService.userLoginSignal$.subscribe((status:any)=>{
      this.logged_user=status
    })
  }

  logout() {
    localStorage.clear()
    this.router.navigate(['login'])
    this.cryptojsService.checkUserLoginOrNot()
    this.cartService.getCartCourseCount()
    this.toastr.success("Successfully Logout")
  }

  cartCourses() {
   this.cartService.getCartCourse().subscribe((resCartItems:any)=>{
    if(resCartItems.length > 0){
      this.totalItem=resCartItems.length
    }else{
      this.totalItem=0
    }
   })
  }

  removeCourse(course:any){
    this.cartService.removeCourse(course)
  }


  onSubmitAdmission(){
    console.log("get values",this.admissionForm);
    if (this.admissionForm.valid) {
      
      this.admissionForm.reset()
      document.getElementById('admissionFormCloseId')?.click()
      // handle form submission
    } else {
      this.admissionForm.markAllAsTouched(); // Mark all fields as touched to show validation messages
    }
  }


  goViewCart(){
    this.cryptojsService.userLoginSignal$.subscribe((status:any)=>{
      if(status && this.cartService.cartCoursePresent()){
        this.router.navigateByUrl('/view-cart')
      }else{
        this.toastr.warning("Please add at least one course to the cart!")
      }
    })
  }


  onKeyup(event:any){
    console.log("get event target value",event.target.value);
    this.searchInput=event.target.value
    if(this.searchInput!= null || this.searchInput!= '' || this.searchInput!= undefined){
     this.callSearchCourse()
    }else{
      this.searchCourseList=[]
    
    }
   
  }


  callSearchCourse(){
    this.course.searchCourseInstructor(this.searchInput).subscribe((resSearchCourse:any)=>{
      if(resSearchCourse.length > 0){
        this.searchCourseList=resSearchCourse
      }else{
        this.searchCourseList=[]
      }
    },
    (error:any)=>{
      this.searchCourseList=[]
    }
  )
  }
  

  callCourseDetails(course:any){
    if(course.id){
      this.router.navigateByUrl('/course/course-details/'+course.id)
      document.getElementById('closeSearchBar')?.click()
    }else{
      this.toastr.warning("Course not found!")
    }
  }

}
