<!-- MODALS
    ================================================== -->
<!-- Modal Sidebar account -->
<div class="modal fade" id="modalExample" tabindex="-1" role="dialog" aria-labelledby="modalExampleTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">

        <!-- Close -->
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>

        <!-- Heading -->
        <h2 class="fw-bold text-center mb-1" id="modalExampleTitle">
          Schedule a demo with us
        </h2>

        <!-- Text -->
        <p class="font-size-lg text-center text-muted mb-6 mb-md-8">
          We can help you solve company communication.
        </p>

        <!-- Form -->
        <form>
          <div class="row">
            <div class="col-12 col-md-6">

              <!-- First name -->
              <div class="form-label-group">
                <input type="text" class="form-control form-control-flush" id="registrationFirstNameModal"
                  placeholder="First name">
                <label for="registrationFirstNameModal">First name</label>
              </div>

            </div>
            <div class="col-12 col-md-6">

              <!-- Last name -->
              <div class="form-label-group">
                <input type="text" class="form-control form-control-flush" id="registrationLastNameModal"
                  placeholder="Last name">
                <label for="registrationLastNameModal">Last name</label>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">

              <!-- Email -->
              <div class="form-label-group">
                <input type="email" class="form-control form-control-flush" id="registrationEmailModal"
                  placeholder="Email">
                <label for="registrationEmailModal">Email</label>
              </div>

            </div>
            <div class="col-12 col-md-6">

              <!-- Password -->
              <div class="form-label-group">
                <input type="password" class="form-control form-control-flush" id="registrationPasswordModal"
                  placeholder="Password">
                <label for="registrationPasswordModal">Password</label>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-12">

              <!-- Submit -->
              <button class="btn btn-block btn-primary mt-3 lift">
                Request a demo
              </button>

            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>
<!-- Modal Sidebar cart -->
<div class="modal modal-sidebar left fade-left fade" id="cartModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header mb-4 shop-cart">
        <h5 style="font-size: 20px;" class="modal-title">Your Shopping Cart</h5>
        <button type="button" class="close text-primary" data-bs-dismiss="modal" aria-label="Close">

          <i class="fas fa-lg fa-times"></i>

        </button>
      </div>

      <div class="modal-body">
        <ul class="list-group list-group-flush mb-5">
          
            <ng-container *ngFor="let getCourse of cartCousesArray;let i=index">
              <li class="list-group-item border-bottom py-0">
                <div class="d-flex py-5">
                  <img class="bg-gray-200 w-60p h-60p rounded-circle overflow-hidden"
                    src="{{apiURL}}/{{getCourse.image}}" alt="...">
                  <!-- <div class="bg-gray-200 w-60p h-60p rounded-circle overflow-hidden"></div> -->
                  <!-- {{apiURL}}/{{viewData?.bannerImg}} -->

                  <div class="flex-grow-1 mt-1 ms-4">
                    <h6 class="fw-normal mb-0">{{getCourse.name}}</h6>
                    <div class="font-size-sm">₹{{getCourse.offer_price}}</div>
                  </div>

                  <!-- <input type="hidden" #course_id value="{{cartCourse.course_Id}}"> -->
                  <div class="d-inline-flex text-secondary" (click)="removeCourse(getCourse)">
                    <!-- Icon -->
                    <i  class="far fa-sm fa-trash-alt" style="cursor: pointer;"></i>
                  </div>
                </div>
              </li>
            </ng-container>

            <ng-container *ngIf="cartCousesArray.length == 0">
              <li class="list-group-item border-bottom py-0">
                <div class="d-flex py-5 cart-head">
                  <h1>Your cart is empty!</h1>
                </div>
              </li>
            </ng-container>

   

        </ul>

      
      </div>

      <div class="modal-footer d-flex justify-content-between">
        <button 
          class="btn btn-primary rts-btn btn-sm-wide me-auto" 
          (click)="goViewCart()"
          data-bs-dismiss="modal">
          VIEW CART
        </button>
        
        <div>
          Order Subtotal : ₹<b>{{cartSubtotalAmt}}</b>
      </div>
      </div>
    </div>
  </div>
</div>


<!-- NAVBAR
    ================================================== -->

<!-- <div class="container-fluid d-none d-sm-block bg-dark top-bar">
  <div class="d-flex align-items-center border-bottom border-white-20 pt-2 pb-2">
    <ul class="nav mx-n3">
      <li class="nav-item px-4 px-xl-1">
        <span class="font-size-sm text-white">उद्यमी महाराष्ट्र | उद्योग प्रशिक्षण आणि व्यवसाय</span>
      </li>

    </ul>

    <ul class="nav ms-auto me-n0 font-size-sm">
      <li class="nav-item px-3">
        <a href="https://www.facebook.com/udyamii/" target="_blank" class="nav-link p-0 text-white">
          <i class="fab fa-facebook-f"></i>
        </a>
      </li>
      <li class="nav-item px-3">
        <a href="#" target="_blank" class="nav-link p-0 text-white">
          <i class="fab fa-twitter"></i>
        </a>
      </li>

      <li class="nav-item px-3">
        <a href="https://in.linkedin.com/company/udyami-maharashtra" target="_blank" class="nav-link p-0 text-white">
          <i class="fab fa-linkedin-in"></i>
        </a>
      </li>
      <li class="nav-item px-2">
        <a class="nav-link p-0" href="index.html">
          <img src="assets/img/marathi.png" class="cust-lang" alt="Marathi">
        </a>
      </li>
      <li class="nav-item px-2">
        <a class="nav-link p-0" href="index.html">
          <img src="assets/img/english.png" class="cust-lang" alt="English">
        </a>
      </li>

    </ul>

  </div>
</div> -->

<header class="navbar site-header navbar-expand-xl navbar-light py-1 ">
  <div class="container">

    <!-- Brand -->
    <a class="navbar-brand me-0 mt-lg-0" routerLink="/index">
      <img src="assets/images/Antarkar%20Sir_s%20logo%20color.png" class="navbar-brand-img" alt="Udyami Maharashtra Wholesale Import and Exportl">
    </a>



    <!-- Collapse -->
    <div class="collapse navbar-collapse z-index-lg" id="navbarCollapse">

      <!-- Toggler -->
      <button class="navbar-toggler outline-0 text-primary" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
        aria-label="Toggle navigation">
        <!-- Icon -->
        <i class="fas fa-lg fa-times"></i>

      </button>

      <!-- Navigation -->
      <ul class="navbar-nav ms-auto d-flex justify-content-end gap-4">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/index']">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/about-us']">About us</a>
        </li>
        <!-- Uncomment this section if needed -->
        <!-- <li class="nav-item">
          <a class="nav-link" [routerLink]="['/why-us']">Why us</a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/course']">Course</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/faq']">FAQ</a>
        </li>
        <!-- Uncomment this section if needed -->
        <!-- <li class="nav-item">
          <a class="nav-link" [routerLink]="['/webinar']">Webinar</a>
        </li> -->
        <li class="nav-item pe-3">
          <a class="nav-link" [routerLink]="['/contact-us']">Contact us</a>
        </li>
      </ul>
      
    </div>

    <!-- Search, Account & Cart -->
    <div class="dropdown ms-auto mt-3" *ngIf="logged_user">
      <button class="btn1 btn-xs1 btn-primary1 dropdown-toggle" type="button" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
        <i class="fa fa-lg fa-user-circle"></i>
      </button>
      <div class="dropdown-menu dropdown-menu-xs collapse p-4" id="collapseExample">
        <a class="dropdown-item text-primary" [routerLink]="['/my-profile']">Edit Profile</a>
        <a class="dropdown-item text-primary" (click)="logout()">Log out</a>

      </div>
    </div>
    <div class="dropdown ms-auto" *ngIf="!logged_user">
      <button style="font-size: 18px; font-weight: 500; color: #390f00; margin-top: 17px ;" class="btn1 btn-xs1 btn-primary1 dropdown-toggle gap-3" type="button" aria-haspopup="true" aria-expanded="false" [routerLink]="['/login']">
        Login
      </button>

    </div>
    <!-- Search, Account & Cart -->
    <ul class="navbar-nav flex-row ms-auto1 ms-xl-0 me-n2 me-md-n4">


      <!-- <li class="nav-item border-0 px-0 d-370-block  dropdown profile">
                     
                    <a href="login.html" class="nav-link d-flex px-3 px-md-4 text-secondary icon-xs">
                        <i class="fa fa-lg fa-user-circle"></i>
                      
                    </a>
                    <ul class="dropdown-menu border-xl shadow-none" aria-labelledby="navbarShop">
                        <li class="dropdown-item">
                            <a class="dropdown-link" href="/edit-profile.html">
                               Edit Profile
                            </a>
                        </li>
                        <li class="dropdown-item">
                            <a class="dropdown-link" href="/login.html">
                               Log out
                            </a>
                        </li>
                      
                    </ul>

                </li> -->


      <li class="nav-item border-0 px-0">
        <!-- Button trigger cart modal -->
        <a href="#" class="nav-link d-flex px-3 px-md-4 position-relative text-secondary icon-xs" data-bs-toggle="modal"
          data-bs-target="#cartModal">

          <!-- Icon -->
          <i class="fa fa-lg fa-shopping-cart"></i>
          <span class="badge badge-primary rounded-circle fw-bold  mt-n1 ms-n2 px-0 w-16"
            style="font-size: 8px;">{{totalItem}}</span>
        </a>
      </li>
      <li class="nav-item border-0 px-0 d-370-block ">
        <a class="nav-link d-flex px-3 px-md-4 search-mobile text-secondary icon-xs" data-bs-toggle="collapse"
          href="#collapseSearchMobile" role="button" aria-expanded="false" id="closeSearchBar" aria-controls="collapseSearchMobile">
          <i class="fa fa-lg fa-search"></i>


          <!-- Icon -->
          <i class="fas fa-lg fa-times"></i>


        </a>
        <div class="collapse position-absolute right-0 left-0" id="collapseSearchMobile">
          <div style="margin-top: 46px;" class="card card-body p-4  shadow-sm border-0">
            <!-- Search Form -->
           
              <div class="input-group rounded border shadow-sm overflow-hidden">
                <!-- Search Icon within Input Field -->
                <span class="input-group-text bg-white border-0">
                  <i class="fa fa-search text-muted"></i>
                </span>
                <input 
                  class="form-control form-control-sm border-0" 
                  type="text" 
                  #box 
                  id="filter" 
                  name="filter" 
                  placeholder="What do you want to learn?" 
                  aria-label="Search"
                  style="padding-left: 0; box-shadow: none;" (keyup)="onKeyup($event)" >
                
                <!-- Submit Button -->
                <button class="rts-btn btn-primary btn-sm px-4" type="button" (click)="callSearchCourse()">
                  Search
                </button>
              </div>
              <div class="card" *ngIf="searchCourseList.length > 0">
                <div class="card-body" *ngFor="let course of searchCourseList">
                  <p class="customlink" (click)="callCourseDetails(course)">{{course?.name}}</p>
                  <hr>
                </div>
              </div>
             
          
          </div>
          
        </div>
      </li>
    </ul>

    <!-- Toggler -->
    <button
      class="navbar-toggler ms-4 ms-md-5 shadow-none bg-teal text-white icon-xs p-0 outline-0 h-40p w-40p d-flex d-xl-none place-flex-center"
      type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse"
      aria-expanded="false" aria-label="Toggle navigation">
      <!-- Icon -->
      <i class="fas fa-bars"></i>

    </button>
  </div>
</header>



<div class="modal fade" id="admission-form-modal" tabindex="-1"
aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog modal-xl">
    <div class="modal-content">
        <span class="close-btn btn-close" data-bs-dismiss="modal" aria-label="Close" id="admissionFormCloseId"></span>
        <h2>Admission Form</h2>
        <form [formGroup]="admissionForm" (ngSubmit)="onSubmitAdmission()">
          <div class="form-container">
            <div class="form-group">
              <label for="name">Name<span class="text-danger">*</span></label>
              <input type="text" id="name" formControlName="name" placeholder="Enter your name">
              <span class="text-danger" *ngIf="admissionForm.get('name')?.invalid && admissionForm.get('name')?.touched">
                Please enter a valid name (2-50 characters).
              </span>
            </div>
        
            <div class="form-group">
              <label for="father_name">Father's Name<span class="text-danger">*</span></label>
              <input type="text" id="father_name" formControlName="fathername" placeholder="Enter your father's name">
              <span class="text-danger" *ngIf="admissionForm.get('fathername')?.invalid && admissionForm.get('fathername')?.touched">
                Please enter a valid father's name (2-50 characters).
              </span>
            </div>
        
            <div class="form-group">
              <label for="surname">Surname<span class="text-danger">*</span></label>
              <input type="text" id="surname" formControlName="surname" placeholder="Enter your surname">
              <span class="text-danger" *ngIf="admissionForm.get('surname')?.invalid && admissionForm.get('surname')?.touched">
                Please enter a valid surname (2-50 characters).
              </span>
            </div>
        
            <div class="form-group">
              <label for="birth_date">Birth Date<span class="text-danger">*</span></label>
              <input type="date" id="birth_date" formControlName="birthdate">
              <span class="text-danger" *ngIf="admissionForm.get('birthdate')?.invalid && admissionForm.get('birthdate')?.touched">
                Please enter a valid birth date.
              </span>
            </div>
        
            <div class="form-group">
              <label for="contact_no">Guardian Mobile No<span class="text-danger">*</span></label>
              <input type="tel" id="contact_no" formControlName="guardianmobileno" placeholder="Enter your contact number">
              <span class="text-danger" *ngIf="admissionForm.get('guardianmobileno')?.invalid && admissionForm.get('guardianmobileno')?.touched">
                Please enter a valid 10-digit mobile number.
              </span>
            </div>
        
            <div class="form-group">
              <label for="jee_year">Year of Appearing for JEE<span class="text-danger">*</span></label>
              <select id="jee_year" formControlName="yearofappearingforjee">
                <option value="">Select year</option>
                <option value="2000">2000</option>
                <option value="2001">2001</option>
                <option value="2002">2002</option>
                <!-- Add other years here -->
                <option value="2030">2030</option>
              </select>
              <span class="text-danger" *ngIf="admissionForm.get('yearofappearingforjee')?.invalid && admissionForm.get('yearofappearingforjee')?.touched">
                Please select a valid year.
              </span>
            </div>
        
            <div class="form-group">
              <label for="student_type">Student Type (New/Renewal)<span class="text-danger">*</span></label>
              <select id="student_type" formControlName="studenttype">
                <option value="">Select</option>
                <option value="new">New</option>
                <option value="renewal">Renewal</option>
              </select>
              <span class="text-danger" *ngIf="admissionForm.get('studenttype')?.invalid && admissionForm.get('studenttype')?.touched">
                Please select a student type.
              </span>
            </div>
        
            <div class="form-group">
              <label for="course_source">How did you come to know about this course?<span class="text-danger">*</span></label>
              <input type="text" id="course_source" formControlName="howdidyoucometoknowaboutthiscourse" placeholder="Enter how you found out about the course">
              <span class="text-danger" *ngIf="admissionForm.get('howdidyoucometoknowaboutthiscourse')?.invalid && admissionForm.get('howdidyoucometoknowaboutthiscourse')?.touched">
                Please provide how you found out about the course (min. 3 characters).
              </span>
            </div>
          </div>
        
          <button type="submit" class="rts-btn btn-primary right-align">Submit</button>
        </form>
        


    </div>
</div>

</div>
