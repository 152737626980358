import { Component, OnInit } from '@angular/core';
import { IndexService } from '../../services/index.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  apiUrl:any=environment.url
  expertTeamArray:any=[]

  constructor(private indexService:IndexService) { }

  ngOnInit(): void {
    this.getAllExpertTeam()
  }

  getAllExpertTeam(){
    this.indexService.getExpertTeam().subscribe((resAllExpertTeam:any)=>{
      if(resAllExpertTeam.items){
        this.expertTeamArray=resAllExpertTeam.items
      }
    })
  }

}
