<!-- <div class=" pb-9 pt-9 pt-md-9 jarallax overlay overlay-secondary overlay-70 course-overlay" data-jarallax
  data-speed=".8" style="background-image: url(assets/img/covers/cover-24.jpg)">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6">
        <ng-container *ngIf="videoDetail">
          <ng-container *ngFor="let lesson of videoDetail[0].lessons">
            <ng-container *ngFor="let video of lesson.videoRow">

              <video width="100%" class="d-block sk-thumbnail1 rounded mb-3" controls="controls">
                <source src="https://videostream.projectnimbus.co.in/PlayVideo?Id={{video.id}}" type="video/webm">
              </video>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="col-lg-6 mb-5 mb-lg-0 position-relative">
        <h1 class="me-8 text-white">
          {{viewData?.name}}
        </h1>
        <div class="me-xl-8 mb-5 text-white">
          <p [innerHTML]="viewData?.description"></p>
        </div>
        <a class="badge badge-lg badge-rounded-circle font-size-base badge-float badge-float-inside top-0 text-white">
          <i (click)="addToWishlist()" class="far fa-heart" style="cursor: pointer;"></i>
        </a>

       
        <div class="d-flex align-items-center mb-2">
          <ng-container *ngIf="Days < 1">
            <ins class="h2 mb-0 text-white">₹{{viewData?.actual_Price}}</ins>
          </ng-container>
          <ng-container *ngIf="Days > 0">
            <ins class="h2 mb-0 text-white">₹{{viewData?.offer_Price}}</ins>
            <del class="ms-3">₹{{viewData?.actual_Price}}</del>
          </ng-container>
          <div class="badge badge-lg badge-primary text-white ms-auto fw-normal">{{viewData?.offer_Percentage}}% Off
          </div>
        </div>

        <div class="d-flex align-items-center mb-6 ">
        
          <ng-container *ngIf="Days > 1">
            <i class="far fa-bell fa-lg fa-inverse"></i>
            <span class="ms-2 text-white">{{Days}} days left at this price!</span>
          </ng-container>
          <ng-container *ngIf="Days == 1">
            <i class="far fa-bell fa-lg fa-inverse"></i>
            <span class="ms-2 text-white">{{Days}} day left at this price!</span>
          </ng-container>
        </div>

        <ul class="list-group list-group-flush">
          <li class="list-group-item d-flex align-items-center py-3 text-white bg-transparent border-white-10">
            <div class="text-white d-flex icon-uxs">
            
              <i class="far fa-clock "></i>

            </div>
            <h6 class="mb-0 ms-3 me-auto text-white">Duration</h6>
          
            <span>{{viewData?.total_Duration |timeConvertor }}</span>
          </li>
          <li class="list-group-item d-flex align-items-center py-3 text-white bg-transparent border-white-10">
            <div class="text-white d-flex icon-uxs">
             
              <i class="fas fa-book-open"></i>

            </div>
            <h6 class="mb-0 ms-3 me-auto text-white">Lectures</h6>
            <span>{{viewData?.videosCount}}</span>
          </li>
          <li class="list-group-item d-flex align-items-center py-3 text-white bg-transparent border-white-10">
            <div class="text-white d-flex icon-uxs">
           
              <i class="fas fa-book-reader"></i>

            </div>
            <h6 class="mb-0 ms-3 me-auto text-white">Enrolled</h6>
            <span>{{viewData?.courseByClientCount}} Students</span>
          </li>
          <li class="list-group-item d-flex align-items-center py-3 text-white bg-transparent border-white-10">
            <div class="text-white d-flex icon-uxs">
          
              <i class="fas fa-language"></i>

            </div>
            <h6 class="mb-0 ms-3 me-auto text-white">Language</h6>
            <span>English / मराठी</span>
          </li>
          <li class="list-group-item d-flex align-items-center py-3 text-white bg-transparent border-white-10">
            <div class="text-white d-flex icon-uxs">
           
              <i class="fas fa-sliders-h"></i>

            </div>
            <h6 class="mb-0 ms-3 me-auto text-white">Skill level</h6>
            <span>{{viewData?.skillLevel}}</span>
          </li>
       
          <li class="list-group-item d-flex align-items-center py-3 text-white bg-transparent border-white-10">
            <div class="text-white d-flex icon-uxs">
            
              <i class="fas fa-certificate"></i>
            </div>
            <h6 class="mb-0 ms-3 me-auto text-white">Certificate</h6>
            <span>Yes</span>
          </li>
        </ul>
        <br />
        <div class="text-center">

          <a (click)="addToCart()" *ngIf='btnname != "ADD TO CART" && btnname != "GO TO CART" '
            class="btn text-white mb-4 mb-md-0 ms-md-3 flex-grow-1 btn-block mw-md-300p enroll" data-aos="zoom-in"
            data-wow-delay=".1s">ADD TO CART</a>
          <a (click)="addToCart()" *ngIf='btnname == "ADD TO CART"'
            class="btn text-white mb-4 mb-md-0 ms-md-3 flex-grow-1 btn-block mw-md-300p enroll" data-aos="zoom-in"
            data-wow-delay=".1s">ADD TO CART</a>
          <a [routerLink]="['/view-cart']" *ngIf='btnname == "GO TO CART"'
            class="btn text-white mb-4 mb-md-0 ms-md-3 flex-grow-1 btn-block mw-md-300p enroll" data-aos="zoom-in"
            data-wow-delay=".1s">GO TO CART</a>
         

          <a href='/lesson-details/{{viewCourse}}'
            class="btn text-white mb-4 mb-md-0 ms-md-3 flex-grow-1 btn-block mw-md-300p enroll" data-aos="zoom-in"
            data-wow-delay=".1s">BUY NOW</a>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="position-relative overlay overlay-secondary1 overlay-60 jarallax" data-jarallax data-speed=".8">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 order-md-1 py-8 py-md-9 ">
        <h3 class="font-size-xxl fw-bold mb-4 ">उद्यमी महाराष्ट्र नक्की काय आहे?</h3>
        <p>“जीवनात ठरवलेले ध्येय साध्य करण्यासाठी कौशल्य आणि संयम असावा लागतो. या दोहोंचा संगम करून त्याला
          अपार मेहनतीची साथ देत उद्योग क्षेत्रात एक यशस्वी मराठी व्यावसायिक म्हणून ज्यांनी आपल्या कक्षा
          रुंदावल्या असे प्रोत्साहनात्मक व्यक्तिमत्व म्हणजे ठाणे येथील डॉ. ओमकार हरी माळी होय.</p>
        <p>ठाणे येथे जन्मलेल्या-वाढलेल्या डॉ. ॐकार हरी माळी यांनी आपले उच्चशिक्षण लंडन येथून पूर्ण केले.
          लंडन येथील (IHMES International Business School) ‘एमबीए’ केल्यानंतर त्यांनी ‘इंटरनॅशनल बिझनेस’
          या विषयातून डॉक्टरेट पदवी प्राप्त केली. उच्चविद्याविभूषित असूनही परदेशातच न थांबता ते आपल्या
          भूमीत परतले. मात्र महाराष्ट्रात येताना त्यांनी एकच ध्येय उराशी बाळगले होते.
        </p>
        <p>ते म्हणजे “आपल्या महाराष्ट्रातील नागरिकांना स्वतःचा व्यवसाय करण्यासाठी प्रोत्साहित करणं.”
          इम्पोर्ट एक्स्पोर्ट उद्योगात आपला किर्तीदायक जम बसवलेले डॉ. ॐकार हरी माळी हे मेडिकल कोलॅबोरेटर
          म्हणून आज जगातील दुबई, बांगलादेश, उझबेकिस्तान, कझाकिस्तान आणि युक्रेन यासारख्या इतर एकूण सात
          देशांमध्ये उद्योग करत आहेत. तर महाराष्ट्रातील विविध शहरांमध्ये ते ‘ई-कॉमर्स’ क्षेत्रात कार्यरत
          आहेत. कॉर्पोरेट जगतासह नाशिक जिल्ह्यातील शेतकऱ्यांच्या शेतमालाला योग्य भाव मिळावा यासाठी आपल्या
          इम्पोर्ट एक्सपोर्टच्या व्यवसाय व ज्ञानाची साथ देत ते शेतकऱ्यांना सहकार्य करत आहेत.</p>
        <p class="collapse mb-6 line-height-md" id="readcollapseExample"> इतकेच नव्हे तर ‘इव्हेन्ट
          मॅनेजमेंट’ क्षेत्रातही ते यशस्वी उद्योजक म्हणून कार्यरत आहेत. केवळ एका व्यवसायापुरतेच मर्यादित न
          राहता निरनिराळ्या क्षेत्रात आपल्या उद्योजकतेच्या कक्षा रुंदावून एक मराठी यशस्वी उद्योजक म्हणून
          डॉ. ॐकार हरी माळी यांनी आपला ठसा उमटवलेला आहे. मात्र इतक्या साऱ्या वर्षांचा विविध व्यवसाय
          क्षेत्रातील अनुभव त्यांनी स्वतःजवळच ठेवला नाही. तर त्याचा उपयोग हा मराठी तरुण तरुणींना
          उद्योगासाठी आवश्यक प्रशिक्षण आणि व्यवसाय यांचे मार्गदर्शन करून महाराष्ट्राच्या सर्वांगीण
          वाढीसाठी करण्याचे ठरवले आणि याच उद्देशाने डॉ. ॐकार हरी माळी यांनी १ मे २०२० रोजी ‘उद्यमी
          महाराष्ट्र’ या उपक्रमाची निर्मिती केली.
          <br /><br />
          राष्ट्रवादी काँग्रेस पक्ष प्रमुख आदरणीय शरदचंद्रजी पवार साहेबांच्या आदेशानुसार आणि
          मार्गदर्शनाखाली राष्ट्रवादी युवक काँग्रेस महाराष्ट्र प्रदेश सरचिटणीस व रोजगार/स्वयंरोजगार
          महाराष्ट्र प्रदेशाध्यक्ष पदावर आपली सक्रिय भूमिका बजावणारे डॉ. ओमकार हरी माळी हे ‘मराठी माणूस
          व्यवसाय करू शकत नाही’ या प्रचलित वाक्याला खोटे ठरवून ‘उद्यमी महाराष्ट्र’ या मोहिमेअंतर्गत सध्या
          अनेक यशस्वी मराठी उद्योजक घडवत आहेत. पुढच्या वर्षभरात ५००० उद्यमींना प्रशिक्षण देऊन त्यांना
          व्यवसायाच्या मुख्य प्रवाहात आणण्याचे ध्येय डॉ. ओमकार हरी माळी यांनी उराशी बाळगलेले आहे.
        </p>
        <a class=" read-more h6 d-inline-block" data-bs-toggle="collapse" href="#readcollapseExample" role="button"
          aria-expanded="false" aria-controls="readcollapseExample">
          <span class="d-inline-flex align-items-center more">
            Read More
            <span class="d-flex align-items-center justify-content-center bg-teal rounded-circle ms-2 p-2 w-26p">
              <i class="fas fa-plus font-size-10 text-white"></i>
            </span>
          </span>
          <span class="d-inline-flex align-items-center less">
            Read Less
            <span class="d-flex align-items-center justify-content-center bg-teal rounded-circle ms-2 p-2 w-26p">
              <i class="fas fa-minus font-size-10 text-white"></i>
            </span>
          </span>
        </a>
      </div>
    </div>
  </div>

</section>



<div class="container-fluid">
  <div class="row pt-4">
    <div class="col-lg-12 mb-8 mb-lg-" data-aos="fade-right">
     
      <ul id="pills-tab" class="nav course-tab-v1 border-bottom h4 my-8 pt-1 mt-1" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="pills-overview-tab" data-bs-toggle="pill" href="#pills-overview" role="tab"
            aria-controls="pills-overview" aria-selected="true">About Course</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="pills-curriculum-tab" data-bs-toggle="pill" href="#pills-curriculum" role="tab"
            aria-controls="pills-curriculum" aria-selected="false">Curriculum</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="pills-instructor-tab" data-bs-toggle="pill" href="#pills-instructor" role="tab"
            aria-controls="pills-instructor" aria-selected="false">Instructor</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="pills-reviews-tab" data-bs-toggle="pill" href="#pills-reviews" role="tab"
            aria-controls="pills-reviews" aria-selected="false">Reviews</a>
        </li>
      </ul>

      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-overview" role="tabpanel" aria-labelledby="pills-overview-tab">
          <h3 class="">Course Description</h3>
          <p [innerHTML]="viewData?.description"></p>
          
          </div> 
          <div class="text-center">
            <a (click)="buynow()"
              class="btn text-white mb-4 mb-md-0 ms-md-3 flex-grow-1  btn-block mw-md-300p floating enroll"
              data-aos="zoom-in" data-wow-delay=".1s">ENROLL NOW</a>
          </div>
        </div>

        <div class="tab-pane fade" id="pills-curriculum" role="tabpanel" aria-labelledby="pills-curriculum-tab">
          <app-lesson [lessonData]="lessondata"></app-lesson>
        </div>

        <div class="tab-pane fade" id="pills-instructor" role="tabpanel" aria-labelledby="pills-instructor-tab">
          <h3 class="mb-6">About the instructor</h3>

          <div class="d-flex align-items-center mb-6">
            <div class="d-inline-block rounded-circle border me-6 p-2">
              <div class="avatar avatar-size-120">
                <img src="{{apiURL}}/{{viewData?.authorPhoto}}" alt="..." class="avatar-img rounded-circle">
              </div>
            </div>
            <div class="media-body">
              <h4 class="mb-0">{{viewData?.authorName}}</h4>
              <span>{{viewData?.designation}}</span>
            </div>
          </div>

          <div class="row mx-xl-n5 mb-6">
            <div class="col-12 col-md-auto mb-3 mb-md-0 px-xl-5">
              <div class="d-flex align-items-center">
                <div class="me-3 d-flex text-secondary icon-uxs">
              
                  <i class="fas fa-star fa-lg fa-1x"></i>

                </div>
                {{viewData?.star}} Instructor rating
              </div>
            </div>

            <div class="col-12 col-md-auto mb-3 mb-md-0 px-xl-5">
              <div class="d-flex align-items-center">
                <div class="me-3 d-flex text-secondary icon-uxs">
                  
                  <i class="fas fa-comment-dots fa-lg fa-1x"></i>

                </div>
                {{viewData?.review}} reviews
              </div>
            </div>

            <div class="col-12 col-md-auto mb-3 mb-md-0 px-xl-5">
              <div class="d-flex align-items-center">
                <div class="me-3 d-flex text-secondary icon-uxs">
                  <i class="fas fa-users fa-lg fa-1x"></i>

                </div>
                {{instructor[0].studentCount}} students
              </div>
            </div>

            <div class="col-12 col-md-auto mb-3 mb-md-0 px-xl-5">
              <div class="d-flex align-items-center">
                <div class="me-3 d-flex text-secondary icon-uxs">
                 
                  <i class="fas fa-wallet fa-lg fa-1x"></i>
                </div>
                {{instructor[0].courseCount}} courses
              </div>
            </div>
          </div>

          <p class="mb-6 line-height-md" [innerHTML]="viewData?.authorDescription"></p>
        </div>

        <div class="tab-pane fade" id="pills-reviews" role="tabpanel" aria-labelledby="pills-reviews-tab">
          <h3 class="mb-6">Student feedback</h3>
          <div class="row align-items-center mb-8">
            <div class="col-md-auto mb-5 mb-md-0">
              <div class="border rounded shadow d-flex align-items-center justify-content-center px-9 py-8">
                <div class="m-2 text-center">
                  <h1 class="display-2 mb-0 fw-medium mb-n1">{{viewData?.star}}</h1>
                  <h5 class="mb-0">Course rating</h5>
                  <div class="star-rating" *ngIf="viewData?.star">
                    <div class="rating" [style.width.%]="(viewData.star/5)*100"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md">
              <div class="d-md-flex align-items-center my-3 my-md-4">
                <div class="bg-gray-200 position-relative rounded-pill flex-grow-1 me-md-5 mb-2 mb-md-0 mw-md-260p"
                  style="height: 10px;">
                  <div class="bg-teal rounded-pill position-absolute top-0 left-0 bottom-0" style="width: 90%;"></div>
                </div>

                <div class="d-flex align-items-center">
                  <div class="star-rating star-rating-lg secondary me-4">
                    <div class="rating" style="width:90%;"></div>
                  </div>
                  <span>4132</span>
                </div>
              </div>

              <div class="d-md-flex align-items-center my-3 my-md-4">
                <div class="bg-gray-200 position-relative rounded-pill flex-grow-1 me-md-5 mb-2 mb-md-0 mw-md-260p"
                  style="height: 10px;">
                  <div class="bg-teal rounded-pill position-absolute top-0 left-0 bottom-0" style="width: 60%;"></div>
                </div>

                <div class="d-flex align-items-center">
                  <div class="star-rating star-rating-lg secondary me-4">
                    <div class="rating" style="width:60%;"></div>
                  </div>
                  <span>150</span>
                </div>
              </div>

              <div class="d-md-flex align-items-center my-3 my-md-4">
                <div class="bg-gray-200 position-relative rounded-pill flex-grow-1 me-md-5 mb-2 mb-md-0 mw-md-260p"
                  style="height: 10px;">
                  <div class="bg-teal rounded-pill position-absolute top-0 left-0 bottom-0" style="width: 50%;"></div>
                </div>

                <div class="d-flex align-items-center">
                  <div class="star-rating star-rating-lg secondary me-4">
                    <div class="rating" style="width:50%;"></div>
                  </div>
                  <span>50</span>
                </div>
              </div>

              <div class="d-md-flex align-items-center my-3 my-md-4">
                <div class="bg-gray-200 position-relative rounded-pill flex-grow-1 me-md-5 mb-2 mb-md-0 mw-md-260p"
                  style="height: 10px;">
                  <div class="bg-teal rounded-pill position-absolute top-0 left-0 bottom-0" style="width: 35%;"></div>
                </div>

                <div class="d-flex align-items-center">
                  <div class="star-rating star-rating-lg secondary me-4">
                    <div class="rating" style="width:35%;"></div>
                  </div>
                  <span>32</span>
                </div>
              </div>

              <div class="d-md-flex align-items-center my-3 my-md-4">
                <div class="bg-gray-200 position-relative rounded-pill flex-grow-1 me-md-5 mb-2 mb-md-0 mw-md-260p"
                  style="height: 10px;">
                  <div class="bg-teal rounded-pill position-absolute top-0 left-0 bottom-0" style="width: 15%;"></div>
                </div>

                <div class="d-flex align-items-center">
                  <div class="star-rating star-rating-lg secondary me-4">
                    <div class="rating" style="width:15%;"></div>
                  </div>
                  <span>1</span>
                </div>
              </div>
            </div>
          </div>

          <ul class="list-unstyled pt-2">
            <ng-container *ngFor="let comments of courseComments;let i=index">
              <li class="media d-flex">
                
                <div class="media-body flex-grow-1">
                  <div class="d-md-flex align-items-center mb-5">
                    <div class="me-auto mb-4 mb-md-0">
                      <h5 class="mb-0">{{comments.clientName}}</h5>
                    </div>
                    <div class="star-rating">
                      <div class="rating" [style.width.%]="(comments.star/5)*100"></div>
                    </div>
                  </div>
                  <p class="mb-6 line-height-md">{{comments.review}}</p>
                </div>
              </li>
              <hr>
            </ng-container>
          </ul>

          <div class="border shadow rounded p-6 p-md-9">
            <h3 class="mb-2">Add Reviews & Rate</h3>
            <div class="">What is it like to Course?</div>
            <form [formGroup]="addreviewsForm" (ngSubmit)="addReviewToCourse()">
              <div class="clearfix">
                <fieldset class="slect-rating mb-3">
                  <input type="radio" id="star5" formControlName="star" name="star" value="5" />
                  <label class="full" for="star5" title="Awesome - 5 stars"></label>

                  <input type="radio" id="star4half" formControlName="star" name="star" value="4.5" />
                  <label class="half" for="star4half" title="Pretty good - 4.5 stars"></label>

                  <input type="radio" id="star4" formControlName="star" name="star" value="4" />
                  <label class="full" for="star4" title="Pretty good - 4 stars"></label>

                  <input type="radio" id="star3half" formControlName="star" name="star" value="3.5" />
                  <label class="half" for="star3half" title="Meh - 3.5 stars"></label>

                  <input type="radio" id="star3" formControlName="star" name="star" value="3" />
                  <label class="full" for="star3" title="Meh - 3 stars"></label>

                  <input type="radio" id="star2half" formControlName="star" name="star" value="2.5" />
                  <label class="half" for="star2half" title="Kinda bad - 2.5 stars"></label>

                  <input type="radio" id="star2" formControlName="star" name="star" value="2" />
                  <label class="full" for="star2" title="Kinda bad - 2 stars"></label>

                  <input type="radio" id="star1half" formControlName="star" name="star" value="1.5" />
                  <label class="half" for="star1half" title="Meh - 1.5 stars"></label>

                  <input type="radio" id="star1" formControlName="star" name="star" value="1" />
                  <label class="full" for="star1" title="Sucks big time - 1 star"></label>

                  <input type="radio" id="starhalf" formControlName="star" name="star" value="0.5" />
                  <label class="half" for="starhalf" title="Sucks big time - 0.5 stars"></label>
                </fieldset>
              </div>
              

              <div class="form-group mb-6">
                <label for="exampleFormControlTextarea1">Review Content</label>
                <textarea class="form-control placeholder-1" formControlName="review" name="review"
                  id="exampleFormControlTextarea1" rows="3" placeholder="Content"></textarea>
              </div>

              <button type="submit" class="btn btn-primary btn-sm btn-block mw-md-300p">SUBMIT
                REVIEW</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<section class="pt-5 pb-5 py-md-8 bg-light1 text-center">
  <div class="container">
    <div class="mb-md-8 mb-4" data-aos="fade-up">
      <h1>Certificate of Completion</h1>

    </div>

    <div class="row">
      <div class="col-lg-2 col-12 mb-4 mb-md-0" data-aos="fade-up">

      </div>

      <div class="col-lg-8 col-12 mb-4 mb-md-0 text-center" data-aos="fade-up">
        <img src="/assets/img/certificate.png" alt="" class="img-fluid rounded-lg w-100px" alt="..."
          data-aos="fade-left">

      </div>

      <div class="col-lg-2 col-12 mb-4 mb-md-0" data-aos="fade-up">

      </div>

    </div>
    <div class="text-center">
      <br />
      <a (click)="buynow()"
        class="btn text-white mb-4 mb-md-0 ms-md-3 flex-grow-1  btn-block mw-md-300p floating enroll" data-aos="zoom-in"
        data-wow-delay=".1s">ENROLL NOW</a>


    </div>
  </div>
</section>


<div class="container-fluid">

  <div class="text-center mb-5 mb-md-8 main-banner-in mt-8" data-aos="fade-up">
    <h1>Related Courses</h1>
    <p class="font-size-lg text-capitalize">Discover your perfect program in our courses.</p>
  </div>
  <div class="row row-cols-md-2 row-cols-xl-3 row-cols-wd-4 row-cols-lg-3 mb-6 mb-xl-3"
    data-flickity='{"pageDots": true, "prevNextButtons": false, "cellAlign": "left", "wrapAround": true, "imagesLoaded": true}'>
    <ng-container *ngFor="let v of viewRelateData;let i=index">
      <div class="col-md pb-4 pb-md-7" data-aos="fade-in" style="padding-right:15px;padding-left:15px;">

       
        <div class="card border shadow p-2 sk-fade">
          
          <div class="card-zoom position-relative">
            <div class="badge-float sk-fade-top top-0 right-0 mt-4 me-4">
              <a [routerLink]="['/course-details/'+ v.id.toString() +'']"
                class="btn btn-xs btn-dark text-white rounded-circle lift opacity-dot-7 me-1 p-2 d-inline-flex justify-content-center align-items-center w-36 h-36">
              
                <i (click)="addToWishlist()" class="far fa-heart"></i>
              </a>
         
            </div>

            <a [routerLink]="['/course-details/'+ v.id.toString() +'']" class="card-img sk-thumbnail d-block">
              <img class="rounded shadow-light-lg" src="{{apiURL}}/{{v.bannerImg}}" alt="...">
            </a>

            <span class="badge sk-fade-bottom badge-lg badge-orange badge-pill badge-float bottom-0 left-0 mb-4 ms-4">
              <span class="text-white text-uppercase fw-bold font-size-xs">BEST SELLER</span>
            </span>
          </div>

 
          <div class="card-footer px-2 pb-2 mb-1 pt-4 position-relative">
            <a href="#" class="d-block">
              <div
                class="avatar avatar-xl badge-float position-absolute top-0 right-0 mt-n6 me-5 rounded-circle shadow border border-white border-w-lg">
                <img src="{{apiURL}}/{{viewData?.authorPhoto}}" alt="..." class="avatar-img rounded-circle">
              </div>
            </a>

        
            <a [routerLink]="['/course-details/'+ v.id.toString() +'']"><span
                class="mb-1 d-inline-block text-gray-800">{{v.categoryName}}</span></a>


      
            <div class="position-relative">
              <a [routerLink]="['/course-details/'+ v.id.toString() +'']" class="d-block stretched-link">
                <h4 class="line-clamp-2 h-md-48 h-lg-58 me-md-6 me-lg-10 me-xl-4 mb-2">{{v.name}}</h4>
              </a>

              <div class="d-lg-flex align-items-end flex-wrap mb-n1">
                <div class="star-rating mb-2 mb-lg-0 me-lg-3">
                  <div class="rating" style="width:50%;"></div>
                </div>

                <div class="font-size-sm">
                  <span>{{v.star}} ({{v.review}}+ reviews)</span>
                </div>
              </div>

              <div class="row mx-n2 align-items-end mh-50">
                <div class="col px-2">
                  <ul class="nav mx-n3">
                    <li class="nav-item px-3">
                      <div class="d-flex align-items-center">
                        <div class="me-2 d-flex icon-uxs text-secondary">
                        
                          <i class="fas fa-book-reader"></i>

                        </div>
                        <div class="font-size-sm">{{v.lessonsCount}} lessons</div>
                      </div>
                    </li>
                    <li class="nav-item px-3">
                      <div class="d-flex align-items-center">
                        <div class="me-2 d-flex icon-uxs text-secondary">
                         
                          <i class="far fa-clock"></i>
                        </div>
                        <div class="font-size-sm">{{v.total_Duration |timeConvertor}}</div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="col-auto px-2 text-right">
                  <ins class="font-size-sm">₹{{v.actual_Price}}</ins>
                  <ins class="h4 mb-0 d-block mb-lg-n1">₹{{v.offer_Price}}</ins>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div> -->

<div class="course-details-breadcrumb-2 bg_image rts-section-gap">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="single-course-left-align-wrapper">
          <div class="meta-area">
            <a routerLink="/index">Home</a>
            <i class="fa-solid fa-chevron-right"></i>
            <a class="active">Course Details</a>
          </div>
          <h1 class="title">
            {{detailsOfCourse?.name ? detailsOfCourse?.name : 'NA'}}
          </h1>
          <div class="rating-area">
            <div class="stars-area">
              <span>4.5</span>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-regular fa-star"></i>
            </div>
            <div class="students">
              <i class="fa-thin fa-users"></i>
              <span>{{detailsOfCourseAuthorWiseStudentsCount?.studentCount ? detailsOfCourseAuthorWiseStudentsCount?.studentCount : 'NA'}}
                Students</span>
            </div>
            <div class="calender-area-stars">
              <i class="fa-light fa-calendar-lines-pen"></i>
              <span>Last updated 12/2024</span>
            </div>
          </div>
          <div class="author-area">
            <div class="author">
              <img class="rounded-circle" style="width: 40px; height: 40px;"
                [src]="apiURL+'/'+detailsOfCourseInfo?.authorPhoto" alt="breadcrumb">
              <h6 class="name"><span>By</span> {{detailsOfCourseInfo?.authorName ? detailsOfCourseInfo?.authorName :'NA'}}
              </h6>
            </div>
            <p> <span>Categories: </span> {{detailsOfCourseInfo?.categoryName ? detailsOfCourseInfo?.categoryName :'NA'}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="course-details-wrapper-2 rts-section-gap">
  <div class="container">
    <div class="row g-5">
      <div class="col-lg-8 order-cl-1 order-lg-1 order-md-2 order-sm-2 order-2">

        <div class="thumbnail mb--30" style="position: relative;">
          <img [src]="apiURL+'/'+detailsOfCourse?.bannerImg" alt="" style="width: 100%;">
          <div class="vedio-icone">
            <a class="video-play-button play-video popup-video" href="https://www.youtube.com/watch?v=WiLErmsTizs">
              <span></span>
            </a>
            <div class="video-overlay">
              <a class="video-overlay-close">×</a>
            </div>
          </div>
        </div>
        <div class="course-content-wrapper">
          <h5 class="title">About Course</h5>
          <p class="disc">
            If filmmaking is your passion but you never went to film school you’ve come to the right place. Here, you
            will get hands-on
            experience and acquire skills that you never would’ve elsewhere like learning how to make feature films on
            your own,
            making do with any equipment, and doing it all faster and better.
          </p>
          <h5 class="title">Description</h5>
          <p class="disc">
            Expression of Mathematics since ancient times has been through numbers. Hence the study of JEE Mathematics
            must have at least a revision of numbers and their elementary properties.

          </p>
          <p class="disc">
            Indices are an integral component of not only arithmetic but also algebra. Hence an exposure to their
            properties is in order in a JEE foundation programme.

          </p>
          <p class="disc">
            Remainder theorem deals with division of one polynomial by another. This knowledge comes in handy in many
            parts of JEE Mathematics later and hence a module is created in the JEE foundation programme on it.


          </p>
          <p class="disc">
            Inequalities are learnt only on a peripheral basis in school curriculum and hence an attempt is made to
            offer an exposure to very basic Inequalities in this module for JEE foundation programme.


          </p>
          <p class="disc">
            Absolute value function appears in collusion with several other concepts in competitive Mathematics. Hence a
            basic video on it has been included in the JEE foundation programme.


          </p>
          <p class="disc">
            Equations are essentially vehicles to solving Mathematical problems of every kind and hence introduction to
            basic techniques of solving them is integral to this JEE foundation programme.


          </p>
          <p class="disc">
            All advanced techniques of dealing with plane and solid figures are manifestations of Pure Geometry. Hence a
            revision of the more important areas of it are included in this JEE foundation programme.


          </p>
          <p class="disc">
            Precise and concise symbolic expressions of Mathematical statements is a prerequisite for solving a problem
            correctly. Logic serves this end and hence finds itself present in this JEE foundation programme.


          </p>
          <p class="disc">
            Logarithms and their algebraic properties connect several dots in subsequent study of JEE mathematics .
            Hence a module on it in the JEE foundation programme is included.


          </p>
          <div class="module-wrapper">
            <h6 class="title">What Will You Learn?</h6>
            <div class="inner-content">
              <div class="single-wrapper">
                <div class="single-codule">
                  <i class="fa-regular fa-check"></i>
                  <p>Learn the basics of computer programming</p>
                </div>
                <div class="single-codule">
                  <i class="fa-regular fa-check"></i>
                  <p>Differences between ads, trailers, vlogs, etc</p>
                </div>
                <div class="single-codule">
                  <i class="fa-regular fa-check"></i>
                  <p>Learn JavaScript</p>
                </div>
              </div>
              <div class="single-wrapper">
                <div class="single-codule">
                  <i class="fa-regular fa-check"></i>
                  <p>Learn CSS</p>
                </div>
                <div class="single-codule">
                  <i class="fa-regular fa-check"></i>
                  <p>Tools you need for best results</p>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="course-content-wrapper-main mt--40">
          <h5 class="title">Course Content</h5>

          <!-- course content accordion area -->
          <div class="accordion mt--30" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                  aria-expanded="true" aria-controls="collapseOne">
                  <span>Elementary Logic</span>
                  <span>3 Lectures . 9 min</span>
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Introduction to the course</span>
                    </div>
                    <div class="right">
                      <span class="play">Preview</span>
                      <span>9 min</span>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Introduction to the course</span>
                    </div>
                    <div class="right">
                      <span class="play">Preview</span>
                      <span>9 min</span>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Introduction to the course</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  <span>Number Systems</span>
                  <span>7 Lectures . 120 min</span>
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Become a storyboard artist</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>storyboard artist</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Introduction PHP</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>LEarning Fundamentsl Elementor</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Enter to the course</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Main Part of the course</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Function About PHP</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  <span>Exponents </span>
                  <span>7 Lectures . 83 min</span>
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Become a storyboard artist</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>storyboard artist</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Introduction PHP</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>LEarning Fundamentsl Elementor</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Enter to the course</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Main Part of the course</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Function About PHP</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  <span>Ratio Proportion </span>
                  <span>7 Lectures . 72 min</span>
                </button>
              </h2>
              <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Become a storyboard artist</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>storyboard artist</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Introduction PHP</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>LEarning Fundamentsl Elementor</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Enter to the course</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Main Part of the course</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Function About PHP</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFive">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  <span>Remainder Theorem</span>
                  <span>7 Lectures . 90 min</span>
                </button>
              </h2>
              <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Become a storyboard artist</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>storyboard artist</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Introduction PHP</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>LEarning Fundamentsl Elementor</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Enter to the course</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Main Part of the course</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Function About PHP</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                  <span>Surds</span>
                  <span>7 Lectures . 90 min</span>
                </button>
              </h2>
              <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Become a storyboard artist</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>storyboard artist</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Introduction PHP</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>LEarning Fundamentsl Elementor</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Enter to the course</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Main Part of the course</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Function About PHP</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingseven">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                  <span>Inequalities</span>
                  <span>7 Lectures . 90 min</span>
                </button>
              </h2>
              <div id="collapseseven" class="accordion-collapse collapse" aria-labelledby="headingseven"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Become a storyboard artist</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>storyboard artist</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Introduction PHP</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>LEarning Fundamentsl Elementor</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Enter to the course</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Main Part of the course</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Function About PHP</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingEight">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                  <span>Theory of Equations </span>
                  <span>7 Lectures . 90 min</span>
                </button>
              </h2>
              <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Become a storyboard artist</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>storyboard artist</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Introduction PHP</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>LEarning Fundamentsl Elementor</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Enter to the course</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Main Part of the course</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Function About PHP</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingNine">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                  <span>Pure Geometry</span>
                  <span>7 Lectures . 90 min</span>
                </button>
              </h2>
              <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Become a storyboard artist</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>storyboard artist</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Introduction PHP</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>LEarning Fundamentsl Elementor</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Enter to the course</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Main Part of the course</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Function About PHP</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTen">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                  <span>Logarithms</span>
                  <span>7 Lectures . 90 min</span>
                </button>
              </h2>
              <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Become a storyboard artist</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>storyboard artist</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Introduction PHP</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>LEarning Fundamentsl Elementor</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Enter to the course</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Main Part of the course</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                  <!-- play single area start -->
                  <a href="#" class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span>Function About PHP</span>
                    </div>
                    <div class="right">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                  </a>
                  <!-- play single area end -->
                </div>
              </div>
            </div>
          </div>
          <!-- course content accordion area end -->
        </div>
        <div class="single-instructor-area-details mt--40">
          <a class="thumbnail">
            <img [src]="apiURL +'/'+ detailsOfCourseExpert?.photo" alt="instructor">
          </a>
          <div class="inner-instrustor-area">
            <h5 class="title">{{detailsOfCourseExpert?.name ? detailsOfCourseExpert?.name : 'NA'}}</h5>
            <div class="deg">
                <span >{{detailsOfCourseExpert?.designation ? detailsOfCourseExpert?.designation : 'NA'}}</span>
            </div>
            
            <div class="stars-area-wrapper">
              <div class="stars-area">
                <span>4.5</span>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-regular fa-star"></i>
              </div>
              <div class="users-area">
                <i class="fa-light fa-users me-2"></i>
                <span>1350 Students</span>
              </div>
              <div class="users-area">
                <i class="fa-light fa-video me-2"></i>
                <span>26 Courses</span>
              </div>
            </div>
            <p class="disc">
              William U. Peña, MBA, CISSP No. 349867, is a former college professor and the lead instructor at Dion
              Training Solutions.
            </p>
            <div class="follow-us">
              <span>Follow</span>
              <ul>
                <li><a href="#"><i class="fa-brands fa-facebook"></i></a></li>
                <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                <li><a href="#"><i class="fa-brands fa-linkedin"></i></a></li>
                <li><a href="#"><i class="fa-brands fa-pinterest"></i></a></li>
                <li><a href="#"><i class="fa-brands fa-youtube"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="rating-main-wrapper">
          <!-- single-top-rating -->
          <div class="rating-top-main-wrapper">
            <!-- rating area start -->
            <div class="rating-area-main-wrapper">
              <h2 class="title">4.5</h2>
              <div class="stars-wrapper">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-regular fa-star"></i>
              </div>
              <span>Total 2 Ratings</span>
            </div>
            <!-- rating area end -->
            <div class="progress-wrapper-main">
              <div class="single-progress-area-h" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                <div class="progress-top">
                  <i class="fa-regular fa-star"></i>
                  <span class="parcent">
                    5
                  </span>
                </div>
                <div class="progress">
                  <div class="progress-bar wow fadeInLeft bg--primary" role="progressbar" style="width: 100%"
                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
                <div class="end">
                  <span>25 Rating</span>
                </div>
              </div>
              <div class="single-progress-area-h" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                <div class="progress-top">
                  <i class="fa-regular fa-star"></i>
                  <span class="parcent">
                    4
                  </span>
                </div>
                <div class="progress">
                  <div class="progress-bar wow fadeInLeft bg--primary" role="progressbar" style="width: 80%"
                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
                <div class="end">
                  <span>20 Rating</span>
                </div>
              </div>
              <div class="single-progress-area-h" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                <div class="progress-top">
                  <i class="fa-regular fa-star"></i>
                  <span class="parcent">
                    3
                  </span>
                </div>
                <div class="progress">
                  <div class="progress-bar wow fadeInLeft bg--primary" role="progressbar" style="width: 60%"
                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
                <div class="end">
                  <span>5 Rating</span>
                </div>
              </div>
              <div class="single-progress-area-h" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                <div class="progress-top">
                  <i class="fa-regular fa-star"></i>
                  <span class="parcent">
                    2
                  </span>
                </div>
                <div class="progress">
                  <div class="progress-bar wow fadeInLeft bg--primary" role="progressbar" style="width: 40%"
                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
                <div class="end">
                  <span>2 Rating</span>
                </div>
              </div>
              <div class="single-progress-area-h" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                <div class="progress-top">
                  <i class="fa-regular fa-star"></i>
                  <span class="parcent">
                    1
                  </span>
                </div>
                <div class="progress">
                  <div class="progress-bar wow fadeInLeft bg--primary" role="progressbar" style="width: 20%"
                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
                <div class="end">
                  <span>1 Rating</span>
                </div>
              </div>
            </div>
          </div>
          <!-- single-top-rating end-->
          <!-- person indevidual rating area  -->
          <div class="indevidual-rating-area">
            <!-- author-area -->
            <div class="author-area">
              <img src="assets/images/instructor/12.jpg" alt="instructor">
              <div class="information">
                <span>William U.</span>
                <div class="stars">
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-regular fa-star"></i>
                  <span class="ml--30">a week ago</span>
                </div>
              </div>
            </div>
            <!-- author-area end -->
            <p class="disc">
              I still have a lot of studying to do using this course and the other practice exams, but so far it's been
              great! I have not taken my Security+ exam as well, so I'll update this at a later time.
            </p>
            <div class="like-love-area">
              <a href="#">
                <i class="fa-sharp fa-light fa-thumbs-up"></i>
              </a>
              <a href="#">
                <i class="fa-regular fa-heart"></i>
              </a>
            </div>
          </div>
          <!-- person indevidual rating area end -->
          <!-- person indevidual rating area  -->
          <div class="indevidual-rating-area">
            <!-- author-area -->
            <div class="author-area">
              <img src="assets/images/instructor/13.jpg" alt="instructor">
              <div class="information">
                <span>William U.</span>
                <div class="stars">
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-regular fa-star"></i>
                  <span class="ml--30">a week ago</span>
                </div>
              </div>
            </div>
            <!-- author-area end -->
            <p class="disc">
              I still have a lot of studying to do using this course and the other practice exams, but so far it's been
              great! I have not taken my Security+ exam as well, so I'll update this at a later time.
            </p>
            <div class="like-love-area">
              <a href="#">
                <i class="fa-sharp fa-light fa-thumbs-up"></i>
              </a>
              <a href="#">
                <i class="fa-regular fa-heart"></i>
              </a>
            </div>
          </div>
          <!-- person indevidual rating area end -->
        </div>
      </div>
      <div class="col-lg-4 order-cl-2 order-lg-2 order-md-1 order-sm-1 order-1 rts-sticky-column-item">
        <!-- right- sticky bar area -->
        <div class="right-course-details mt--0">
          <!-- single course-sidebar -->
          <div class="course-side-bar">
            <div class="price-area">
              <h3 class="title">&#8377;{{detailsOfCourse?.offer_Price ? detailsOfCourse?.offer_Price :'NA'}}</h3>
              <h4 class="none">&#8377;{{detailsOfCourse?.actual_Price ? detailsOfCourse?.actual_Price : 'NA'}}</h4>
              <span
                class="discount">{{detailsOfCourse?.offer_Percentage  ? detailsOfCourse?.offer_Percentage  : 'NA'}}</span>
            </div>
            <div class="clock-area">
              <i class="fa-light fa-clock"></i>
              <span>2 Day left at this price!</span>
            </div>
            <a class="rts-btn btn-primary" (click)="addToCart(detailsOfCourse)" *ngIf="!alerdyAddedToCart">Add To Cart</a>
            <a class="rts-btn btn-primary" *ngIf="alerdyAddedToCart">Added To Cart</a>
            <a class="rts-btn btn-border" (click)="goViewCart()">Buy Now</a>

            <div class="what-includes">
              <span class="m">30-Day Money-Back Guarantee</span>
              <h5 class="title">This course includes: </h5>
              <div class="single-include">
                <div class="left">
                  <i class="fa-light fa-chart-bar"></i>
                  <span>Levels</span>
                </div>
                <div class="right">
                  <span>{{detailsOfCourseInfo?.skillLevel ? detailsOfCourseInfo?.skillLevel : 'NA'}}</span>
                </div>
              </div>
              <div class="single-include">
                <div class="left">
                  <i class="fa-light fa-timer"></i>
                  <span>Duration</span>
                </div>
                <div class="right">
                  <span>6 hours 56 minutes</span>
                </div>
              </div>
              <div class="single-include">
                <div class="left">
                  <i class="fa-regular fa-floppy-disk"></i>
                  <span>Subject</span>
                </div>
                <div class="right">
                  <span>{{detailsOfCourseInfo?.categoryName ? detailsOfCourseInfo?.categoryName : 'NA'}}</span>
                </div>
              </div>
              <div class="single-include">
                <div class="left">
                  <i class="fa-regular fa-pen-to-square"></i>
                  <span>Update</span>
                </div>
                <div class="right">
                  <span>29 October, 2023 Last Update</span>
                </div>
              </div>
              <div class="single-include">
                <div class="left">
                  <i class="fa-sharp fa-light fa-file-certificate"></i>
                  <span>Certificate</span>
                </div>
                <div class="right">
                  <span>Certificate of completion </span>
                </div>
              </div>
            </div>
          </div>
          <!-- single course-sidebar end -->
        </div>
        <!-- right- sticky bar area end -->
        <!-- right- sticky bar area -->
        <div class="right-course-details mt--30">
          <!-- single course-sidebar -->
          <div class="course-side-bar">
            <!-- course single sidebar -->
            <div class="course-single-information">
              <h5 class="title">A course by</h5>
              <div class="body">
                <div class="author">
                  <img class="rounded-circle" style="width: 40px; height: 40px;"
                    [src]="apiURL + '/' + detailsOfCourseInfo?.authorPhoto" alt="">
                  <span>{{detailsOfCourseInfo?.authorName ? detailsOfCourseInfo?.authorName : 'NA'}}</span>
                </div>
              </div>
            </div>
            <!-- course single sidebar end-->
            <!-- course single sidebar -->
            <div class="course-single-information">
              <h5 class="title">Material Includes</h5>
              <div class="body">
                <!-- ingle check -->
                <div class="single-check">
                  <i class="fa-light fa-circle-check"></i>
                  Flexible Deadlines
                </div>
                <!-- ingle check end -->
                <!-- ingle check -->
                <div class="single-check">
                  <i class="fa-light fa-circle-check"></i>
                  Hours of live- demo
                </div>
                <!-- ingle check end -->
                <!-- ingle check -->
                <div class="single-check">
                  <i class="fa-light fa-circle-check"></i>
                  Hours of live- demo
                </div>
                <!-- ingle check end -->
                <!-- ingle check -->
                <div class="single-check">
                  <i class="fa-light fa-circle-check"></i>
                  200+ downloadable resoursces
                </div>
                <!-- ingle check end -->
              </div>
            </div>
            <!-- course single sidebar end-->
            <!-- course single sidebar -->
            <div class="course-single-information">
              <h5 class="title">Requirements</h5>
              <div class="body">
                <!-- ingle check -->
                <div class="single-check">
                  <i class="fa-light fa-circle-check"></i>
                  Access to Adobe Premiere Pro
                </div>
                <!-- ingle check end -->
                <!-- ingle check -->
                <div class="single-check">
                  <i class="fa-light fa-circle-check"></i>
                  Familiarity with computers and other devices
                </div>
                <!-- ingle check end -->
              </div>
            </div>
            <!-- course single sidebar end-->
            <!-- course single sidebar -->
            <div class="course-single-information">
              <h5 class="title">Tags</h5>
              <div class="body">
                <div class="tags-wrapper">
                  <!-- single tags -->
                  <span>Course</span>
                  <span>Design</span>
                  <span>Web development</span>
                  <span>Business</span>
                  <span>UI/UX</span>
                  <span>Financial</span>
                  <!-- single tags end -->
                </div>
              </div>
            </div>
            <!-- course single sidebar end-->
            <!-- course single sidebar -->
            <div class="course-single-information">
              <h5 class="title">Share</h5>
              <div class="body">
                <!-- social-share-course-sidebar -->
                <div class="social-share-course-side-bar">
                  <ul>
                    <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                    <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                    <li><a href="#"><i class="fa-brands fa-linkedin"></i></a></li>
                    <li><a href="#"><i class="fa-brands fa-pinterest"></i></a></li>
                    <li><a href="#"><i class="fa-brands fa-youtube"></i></a></li>
                  </ul>
                </div>
                <!-- social-share-course-sidebar end -->
              </div>
            </div>
            <!-- course single sidebar end-->

            <!-- course single sidebar -->
            <div class="course-single-information last">
              <h5 class="title">Audience</h5>
              <div class="body">
                <!-- ingle check -->
                <div class="single-check">
                  <i class="fa-light fa-circle-check"></i>
                  Suitable for {{detailsOfCourseInfo?.skillLevel ? detailsOfCourseInfo?.skillLevel : 'NA'}}
                </div>
                <!-- ingle check end -->
              </div>
            </div>
            <!-- course single sidebar end-->
          </div>
          <!-- single course-sidebar end -->
        </div>
        <!-- right- sticky bar area end -->
      </div>
    </div>
  </div>
</div>



<div class="cart-bar">
  <div class="cart-header">
    <h3 class="cart-heading">MY CART (3 ITEMS)</h3>
    <div class="close-cart"><i class="fal fa-times"></i></div>
  </div>
  <div class="product-area">
    <div class="product-item">
      <div class="product-detail">
        <div class="product-thumb"><img src="assets/images/course/cart/01.jpg" alt="product-thumb"></div>
        <div class="item-wrapper">
          <span class="product-name">Construct Map</span>
          <div class="item-wrapper">
            <span class="product-variation"><span class="color">Green /</span>
              <span class="size">XL</span></span>
          </div>
          <div class="item-wrapper">
            <span class="product-qnty">3 ×</span>
            <span class="product-price">&#8377;198.00</span>
          </div>
        </div>
      </div>
      <div class="cart-edit">
        <div class="quantity-edit">
          <button class="button"><i class="fal fa-minus minus"></i></button>
          <input type="text" class="input" value="3">
          <button class="button plus">+<i class="fal fa-plus plus"></i></button>
        </div>
        <div class="item-wrapper d-flex mr--5 align-items-center">
          <a href="#" class="product-edit"><i class="fal fa-edit"></i></a>
          <a href="#" class="delete-cart"><i class="fal fa-times"></i></a>
        </div>
      </div>
    </div>
    <div class="product-item">
      <div class="product-detail">
        <div class="product-thumb"><img src="assets/images/course/cart/02.jpg" alt="product-thumb"></div>
        <div class="item-wrapper">
          <span class="product-name"> Bridge product</span>
          <div class="item-wrapper">
            <span class="product-variation"><span class="color">Green /</span>
              <span class="size">XL</span></span>
          </div>
          <div class="item-wrapper">
            <span class="product-qnty">2 ×</span>
            <span class="product-price">&#8377;88.00</span>
          </div>
        </div>
      </div>
      <div class="cart-edit">
        <div class="quantity-edit">
          <button class="button"><i class="fal fa-minus minus"></i></button>
          <input type="text" class="input" value="2">
          <button class="button plus">+<i class="fal fa-plus plus"></i></button>
        </div>
        <div class="item-wrapper d-flex mr--5 align-items-center">
          <a href="#" class="product-edit"><i class="fal fa-edit"></i></a>
          <a href="#" class="delete-cart"><i class="fal fa-times"></i></a>
        </div>
      </div>
    </div>
    <div class="product-item last-child">
      <div class="product-detail">
        <div class="product-thumb"><img src="assets/images/course/cart/03.jpg" alt="product-thumb"></div>
        <div class="item-wrapper">
          <span class="product-name">Labour helmet</span>
          <div class="item-wrapper">
            <span class="product-variation"><span class="color">Green /</span>
              <span class="size">XL</span></span>
          </div>
          <div class="item-wrapper">
            <span class="product-qnty">1 ×</span>
            <span class="product-price">&#8377;289.00</span>
          </div>
        </div>
      </div>
      <div class="cart-edit">
        <div class="quantity-edit">
          <button class="button"><i class="fal fa-minus minus"></i></button>
          <input type="text" class="input" value="2">
          <button class="button plus">+<i class="fal fa-plus plus"></i></button>
        </div>
        <div class="item-wrapper d-flex mr--5 align-items-center">
          <a href="#" class="product-edit"><i class="fal fa-edit"></i></a>
          <a href="#" class="delete-cart"><i class="fal fa-times"></i></a>
        </div>
      </div>
    </div>
  </div>
  <div class="cart-bottom-area">
    <span class="spend-shipping"><i class="fal fa-truck"></i> SPENT <span class="amount">&#8377;199.00</span> MORE
      FOR FREE SHIPPING</span>
    <span class="total-price">TOTAL: <span class="price">&#8377;556</span></span>
    <a href="checkout.html" class="checkout-btn cart-btn">PROCEED TO CHECKOUT</a>
    <a href="cart.html" class="view-btn cart-btn">VIEW CART</a>
  </div>
</div>

<div id="side-bar" class="side-bar header-two">
  <button class="close-icon-menu"><i class="far fa-times"></i></button>
  <!-- inner menu area desktop start -->
  <div class="inner-main-wrapper-desk">
    <div class="thumbnail">
      <img src="assets/images/banner/04.jpg" alt="elevate">
    </div>
    <div class="inner-content">
      <h4 class="title">We Build Building and Great Constructive Homes.</h4>
      <p class="disc">
        We successfully cope with tasks of varying complexity, provide long-term guarantees and regularly master new
        technologies.
      </p>
      <div class="footer">
        <h4 class="title">Got a project in mind?</h4>
        <a href="contact.html" class="rts-btn btn-primary">Let's talk</a>
      </div>
    </div>
  </div>
  <!-- mobile menu area start -->
  <div class="mobile-menu-main">
    <nav class="nav-main mainmenu-nav mt--30">
      <ul class="mainmenu metismenu" id="mobile-menu-active">
        <li>
          <a href="index.html" class="main">Home</a>
        </li>
        <li>
          <a href="about.html" class="main">About Us</a>
        </li>
        <li>
          <a href="course-one.html" class="main">Course</a>

        </li>
        <li>
          <a href="contact.html" class="main">Contact Us</a>

        </li>
        <li>
          <a href="FAQ.html" class="main">FAQ</a>

        </li>
      </ul>
    </nav>

    <div class="buttons-area">
      <a href="log-in.html" class="rts-btn btn-border">Log In</a>
      <a href="registration.html" class="rts-btn btn-primary">Sign Up</a>
    </div>

    <div class="rts-social-style-one pl--20 mt--50">
      <ul>
        <li>
          <a href="#">
            <i class="fa-brands fa-facebook-f"></i>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa-brands fa-twitter"></i>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa-brands fa-youtube"></i>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa-brands fa-linkedin-in"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- mobile menu area end -->
</div>
<div id="myModal-1" class="modal fade" role="dialog">
  <div class="modal-dialog bg_image">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-bs-dismiss="modal"><i class="fa-light fa-x"></i></button>
      </div>
      <div class="modal-body text-center">
        <div class="inner-content">
          <div class="title-area">
            <span class="pre">Get Our Courses Free</span>
            <h4 class="title">Wonderful for Learning</h4>
          </div>
          <form action="#">
            <input type="text" placeholder="Your Mail.." required>
            <button>Download Now</button>
            <span>Your information will never be shared with any third party</span>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="progress-wrap">
  <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
      style="transition: stroke-dashoffset 10ms linear 0s; stroke-dasharray: 307.919, 307.919; stroke-dashoffset: 307.919;">
    </path>
  </svg>
</div>
<div class="search-input-area">
  <div class="container">
    <div class="search-input-inner">
      <div class="input-div">
        <input class="search-input autocomplete" type="text" placeholder="Search by keyword or #">
        <button><i class="far fa-search"></i></button>
      </div>
    </div>
  </div>
  <div id="close" class="search-close-icon"><i class="far fa-times"></i></div>
</div>




<div class="bubbles_wrap">
  <div class="bubble x1"></div>
  <div class="bubble x2"></div>
  <div class="bubble x3"></div>
  <div class="bubble x4"></div>
  <div class="bubble x5"></div>
  <div class="bubble x6"></div>
  <div class="bubble x7"></div>
  <div class="bubble x8"></div>
  <div class="bubble x9"></div>
  <div class="bubble x10"></div>
</div>

<a href="#main-banner" class="scroll-top" id="scroll-to-top">
  <i class="fa fa-arrow-up" aria-hidden="true"></i>
</a>
