<div *ngIf="this.url[1] !== 'ibc' && this.url[1] !== 'webinar'">
  <app-header></app-header>
</div>
  <router-outlet (activate)="onActivate()"></router-outlet>
  <!-- <app-index></app-index> -->
  <div *ngIf="this.url[1] !== 'ibc' && this.url[1] !== 'webinar'">
  <app-footer></app-footer>

  
  <!-- <div class="ud-header">
    <h2 class="text-center">Public Chat</h2>
  </div>
  <div class="ud-body">
    <div class="msg-box">
      <ul>
        <li *ngFor="let mObj of msgInboxArray" [ngClass]="mObj.user === msgDto.user ? 'in-msg' : 'ex-msg'">{{mObj.user}} :<br /><span class="msg-italic-style">{{mObj.msgText}}</span></li>
      </ul>
    </div>
    <div class="ud-flex-row">
      <input type="text" placeholder="Type message here" class="msg-input" [(ngModel)]="msgDto.msgText"  />
      <button class="send-btn" (click)= "send()"><i class="fa fa-paper-plane"></i></button>
    </div>
    <input type="text" placeholder="Your name" class="name-box" [(ngModel)]="msgDto.user" />
  </div> --> 
  
  
</div>
