

  
    <!-- <header class="py-2 py-md-4 main-banner-in1 overlay overlay-primary overlay-70 jarallax" data-jarallax
        data-speed=".8" style="background-image: url(assets/img/covers/cover-19.jpg);">
        <div class="container-fluid text-center ">
            <h2 class="fw-semi-bold mb-0 text-white py-1 py-md-3">COURSES</h2>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-scroll justify-content-center py-1 ">
                    <li class="breadcrumb-item">
                        <a class="text-white" href="#">
                            Home
                        </a>
                    </li>
                    <li class="breadcrumb-item text-white active" aria-current="page">
                        Courses
                    </li>
                </ol>
            </nav>
        </div>
    

    </header> -->

    <div class="rts-bread-crumbarea-1 rts-section-gap bg_image">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main-wrapper">
                        <h1 class="title">Our Programmes</h1>
                        <div class="pagination-wrapper">
                            <a routerLink="/index">Home</a>
                            <i class="fa-regular fa-chevron-right"></i>
                            <a class="active" href="course-one.html">All Course</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="rts-course-default-area rts-section-gap">
        <div class="container">
            <div class="row ">
                <div class="col-12">
                    <div class="filter-small-top-full">
                        <div class="left-filter">
                            <span>Short By</span>
                            <select class="nice-select" name="price" (change)="changeCategory($event)">
                                <option *ngFor="let category of courseCategory"  [value]="category.categoryName">{{category.categoryName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div class="row g-5 mt--10">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12" *ngFor="let cou of courses">
                                    <div class="rts-single-course sticky-content">
                                        <a routerLink="/course/course-details/{{cou.id}}" class="thumbnail">
                                            <img [src]="apiURL + cou.bannerImg" alt="course">
                                        </a>
                                        <div class="save-icon" data-bs-toggle="modal" data-bs-target="#exampleModal-login">
                                            <i class="fa-sharp fa-light fa-bookmark"></i>
                                        </div>
                                        <div class="tags-area-wrapper">
                                            <div class="single-tag">
                                                <span>{{cou.name}}</span>
                                            </div>
                                        </div>
                                        <div class="lesson-studente">
                                            <div class="lesson">
                                                <i class="fa-light fa-calendar-lines-pen"></i>
                                                <span>25 Lessons</span>
                                            </div>
                                            <div class="lesson">
                                                <i class="fa-light fa-user-group"></i>
                                                <span>54 Students</span>
                                            </div>
                                        </div>
                                        <a href="single-course-two.html">
                                            <h5 class="title">{{cou.categoryName}}</h5>
                                        </a>
                                        <p class="teacher">{{cou.authorName}}</p>
                                        <div class="rating-and-price">
                                            <div class="rating-area">
                                                <span>4.5</span>
                                                <div class="stars">
                                                    <ul>
                                                        <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                        <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                        <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                        <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                        <li><i class="fa-sharp fa-regular fa-star"></i></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="price-area">
                                                <div class="not price">
                                                    &#8377;12000
                                                </div>
                                                <div class="price">
                                                    &#8377;6000
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                           
                            </div>
                         
                        </div>
                        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <div class="row g-5 mt--30">
                                <div class="col-lg-12">
                                  
                                    <div class="rts-single-course course-list">
                                        <a href="single-course-two.html" class="thumbnail">
                                            <img src="assets/images/course/11.jpg" alt="course">
                                        </a>
                                        <div class="information-inner">
                                            <div class="tags-area-wrapper">
                                                <div class="single-tag">
                                                    <span>Web Development</span>
                                                </div>
                                            </div>
                                            <div class="lesson-studente">
                                                <div class="lesson">
                                                    <i class="fa-light fa-calendar-lines-pen"></i>
                                                    <span>25 Lessons</span>
                                                </div>
                                                <div class="lesson">
                                                    <i class="fa-light fa-user-group"></i>
                                                    <span>54 Students</span>
                                                </div>
                                            </div>
                                            <a href="single-course-two.html">
                                                <h5 class="title">The Complete Web Developer in
                                                    2023: Zero to Mastery</h5>
                                            </a>
                                            <p class="disc">Discover a world of knowledge and learning opportunities</p>
                                            <p class="teacher">Dr. Angela Yu</p>
                                            <div class="rating-and-price">
                                                <div class="rating-area">
                                                    <span>4.5</span>
                                                    <div class="stars">
                                                        <ul>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-regular fa-star"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="price-area">
                                                    <div class="not price">
                                                        &#8377;79.99
                                                    </div>
                                                    <div class="price">
                                                        &#8377;79.99
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                 
                                    <div class="rts-single-course course-list">
                                        <a href="single-course-two.html" class="thumbnail">
                                            <img src="assets/images/course/12.jpg" alt="course">
                                        </a>
                                        <div class="information-inner">
                                            <div class="tags-area-wrapper">
                                                <div class="single-tag">
                                                    <span>Web Development</span>
                                                </div>
                                            </div>
                                            <div class="lesson-studente">
                                                <div class="lesson">
                                                    <i class="fa-light fa-calendar-lines-pen"></i>
                                                    <span>25 Lessons</span>
                                                </div>
                                                <div class="lesson">
                                                    <i class="fa-light fa-user-group"></i>
                                                    <span>54 Students</span>
                                                </div>
                                            </div>
                                            <a href="single-course-two.html">
                                                <h5 class="title">The Complete Web Developer in
                                                    2023: Zero to Mastery</h5>
                                            </a>
                                            <p class="disc">Discover a world of knowledge and learning opportunities</p>
                                            <p class="teacher">Dr. Angela Yu</p>
                                            <div class="rating-and-price">
                                                <div class="rating-area">
                                                    <span>4.5</span>
                                                    <div class="stars">
                                                        <ul>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-regular fa-star"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="price-area">
                                                    <div class="not price">
                                                        &#8377;79.99
                                                    </div>
                                                    <div class="price">
                                                        &#8377;79.99
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    
                                    <div class="rts-single-course course-list">
                                        <a href="single-course-two.html" class="thumbnail">
                                            <img src="assets/images/course/13.jpg" alt="course">
                                        </a>
                                        <div class="information-inner">
                                            <div class="tags-area-wrapper">
                                                <div class="single-tag">
                                                    <span>Web Development</span>
                                                </div>
                                            </div>
                                            <div class="lesson-studente">
                                                <div class="lesson">
                                                    <i class="fa-light fa-calendar-lines-pen"></i>
                                                    <span>25 Lessons</span>
                                                </div>
                                                <div class="lesson">
                                                    <i class="fa-light fa-user-group"></i>
                                                    <span>54 Students</span>
                                                </div>
                                            </div>
                                            <a href="single-course-two.html">
                                                <h5 class="title">The Complete Web Developer in
                                                    2023: Zero to Mastery</h5>
                                            </a>
                                            <p class="disc">Discover a world of knowledge and learning opportunities</p>
                                            <p class="teacher">Dr. Angela Yu</p>
                                            <div class="rating-and-price">
                                                <div class="rating-area">
                                                    <span>4.5</span>
                                                    <div class="stars">
                                                        <ul>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-regular fa-star"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="price-area">
                                                    <div class="not price">
                                                        &#8377;79.99
                                                    </div>
                                                    <div class="price">
                                                        &#8377;79.99
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                   
                                    <div class="rts-single-course course-list">
                                        <a href="single-course-two.html" class="thumbnail">
                                            <img src="assets/images/course/14.jpg" alt="course">
                                        </a>
                                        <div class="information-inner">
                                            <div class="tags-area-wrapper">
                                                <div class="single-tag">
                                                    <span>Web Development</span>
                                                </div>
                                            </div>
                                            <div class="lesson-studente">
                                                <div class="lesson">
                                                    <i class="fa-light fa-calendar-lines-pen"></i>
                                                    <span>25 Lessons</span>
                                                </div>
                                                <div class="lesson">
                                                    <i class="fa-light fa-user-group"></i>
                                                    <span>54 Students</span>
                                                </div>
                                            </div>
                                            <a href="single-course-two.html">
                                                <h5 class="title">The Complete Web Developer in
                                                    2023: Zero to Mastery</h5>
                                            </a>
                                            <p class="disc">Discover a world of knowledge and learning opportunities</p>
                                            <p class="teacher">Dr. Angela Yu</p>
                                            <div class="rating-and-price">
                                                <div class="rating-area">
                                                    <span>4.5</span>
                                                    <div class="stars">
                                                        <ul>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-regular fa-star"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="price-area">
                                                    <div class="not price">
                                                        &#8377;79.99
                                                    </div>
                                                    <div class="price">
                                                        &#8377;79.99
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    
                                    <div class="rts-single-course course-list">
                                        <a href="single-course-two.html" class="thumbnail">
                                            <img src="assets/images/course/15.jpg" alt="course">
                                        </a>
                                        <div class="information-inner">
                                            <div class="tags-area-wrapper">
                                                <div class="single-tag">
                                                    <span>Web Development</span>
                                                </div>
                                            </div>
                                            <div class="lesson-studente">
                                                <div class="lesson">
                                                    <i class="fa-light fa-calendar-lines-pen"></i>
                                                    <span>25 Lessons</span>
                                                </div>
                                                <div class="lesson">
                                                    <i class="fa-light fa-user-group"></i>
                                                    <span>54 Students</span>
                                                </div>
                                            </div>
                                            <a href="single-course-two.html">
                                                <h5 class="title">The Complete Web Developer in
                                                    2023: Zero to Mastery</h5>
                                            </a>
                                            <p class="disc">Discover a world of knowledge and learning opportunities</p>
                                            <p class="teacher">Dr. Angela Yu</p>
                                            <div class="rating-and-price">
                                                <div class="rating-area">
                                                    <span>4.5</span>
                                                    <div class="stars">
                                                        <ul>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-regular fa-star"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="price-area">
                                                    <div class="not price">
                                                        &#8377;79.99
                                                    </div>
                                                    <div class="price">
                                                        &#8377;79.99
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    
                                    <div class="rts-single-course course-list">
                                        <a href="single-course-two.html" class="thumbnail">
                                            <img src="assets/images/course/16.jpg" alt="course">
                                        </a>
                                        <div class="information-inner">
                                            <div class="tags-area-wrapper">
                                                <div class="single-tag">
                                                    <span>Web Development</span>
                                                </div>
                                            </div>
                                            <div class="lesson-studente">
                                                <div class="lesson">
                                                    <i class="fa-light fa-calendar-lines-pen"></i>
                                                    <span>25 Lessons</span>
                                                </div>
                                                <div class="lesson">
                                                    <i class="fa-light fa-user-group"></i>
                                                    <span>54 Students</span>
                                                </div>
                                            </div>
                                            <a href="single-course-two.html">
                                                <h5 class="title">The Complete Web Developer in
                                                    2023: Zero to Mastery</h5>
                                            </a>
                                            <p class="disc">Discover a world of knowledge and learning opportunities</p>
                                            <p class="teacher">Dr. Angela Yu</p>
                                            <div class="rating-and-price">
                                                <div class="rating-area">
                                                    <span>4.5</span>
                                                    <div class="stars">
                                                        <ul>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-regular fa-star"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="price-area">
                                                    <div class="not price">
                                                        &#8377;79.99
                                                    </div>
                                                    <div class="price">
                                                        &#8377;79.99
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                   
                                    <div class="rts-single-course course-list">
                                        <a href="single-course-two.html" class="thumbnail">
                                            <img src="assets/images/course/17.jpg" alt="course">
                                        </a>
                                        <div class="information-inner">
                                            <div class="tags-area-wrapper">
                                                <div class="single-tag">
                                                    <span>Web Development</span>
                                                </div>
                                            </div>
                                            <div class="lesson-studente">
                                                <div class="lesson">
                                                    <i class="fa-light fa-calendar-lines-pen"></i>
                                                    <span>25 Lessons</span>
                                                </div>
                                                <div class="lesson">
                                                    <i class="fa-light fa-user-group"></i>
                                                    <span>54 Students</span>
                                                </div>
                                            </div>
                                            <a href="single-course-two.html">
                                                <h5 class="title">The Complete Web Developer in
                                                    2023: Zero to Mastery</h5>
                                            </a>
                                            <p class="disc">Discover a world of knowledge and learning opportunities</p>
                                            <p class="teacher">Dr. Angela Yu</p>
                                            <div class="rating-and-price">
                                                <div class="rating-area">
                                                    <span>4.5</span>
                                                    <div class="stars">
                                                        <ul>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-regular fa-star"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="price-area">
                                                    <div class="not price">
                                                        &#8377;79.99
                                                    </div>
                                                    <div class="price">
                                                        &#8377;79.99
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                  
                                    <div class="rts-single-course course-list">
                                        <a href="single-course-two.html" class="thumbnail">
                                            <img src="assets/images/course/18.jpg" alt="course">
                                        </a>
                                        <div class="information-inner">
                                            <div class="tags-area-wrapper">
                                                <div class="single-tag">
                                                    <span>Web Development</span>
                                                </div>
                                            </div>
                                            <div class="lesson-studente">
                                                <div class="lesson">
                                                    <i class="fa-light fa-calendar-lines-pen"></i>
                                                    <span>25 Lessons</span>
                                                </div>
                                                <div class="lesson">
                                                    <i class="fa-light fa-user-group"></i>
                                                    <span>54 Students</span>
                                                </div>
                                            </div>
                                            <a href="single-course-two.html">
                                                <h5 class="title">The Complete Web Developer in
                                                    2023: Zero to Mastery</h5>
                                            </a>
                                            <p class="disc">Discover a world of knowledge and learning opportunities</p>
                                            <p class="teacher">Dr. Angela Yu</p>
                                            <div class="rating-and-price">
                                                <div class="rating-area">
                                                    <span>4.5</span>
                                                    <div class="stars">
                                                        <ul>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                                            <li><i class="fa-sharp fa-regular fa-star"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="price-area">
                                                    <div class="not price">
                                                        &#8377;79.99
                                                    </div>
                                                    <div class="price">
                                                        &#8377;79.99
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                   
                                </div>
                            </div>
                            <div class="row mt--10">
                                <div class="col-lg-12">
                                   
                                    <div class="rts-pagination-area-2">
                                        <ul>
                                            <li><i class="fa-solid fa-chevron-left"></i></li>
                                            <li class="active">1</li>
                                            <li>2</li>
                                            <li>3</li>
                                            <li>4</li>
                                            <li><i class="fa-solid fa-chevron-right"></i></li>
                                        </ul>
                                    </div>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
      

    <!-- <div class="container-fluid py-5 py-xl-8">
        <div class="row row-cols-md-2 row-cols-xl-3 row-cols-wd-4 row-cols-lg-3 mb-6 mb-xl-3">
            <ng-container *ngFor="let c of courses;let i=index"> 
            <div class="col-md pb-4 pb-md-7" data-aos="fade-in">
              
                <div class="card border shadow p-2 lift sk-fade">
                 
                    <div class="card-zoom position-relative">
                        <div class="badge-float sk-fade-top top-0 right-0 mt-4 me-4">
                            <a [routerLink]="['/course-details/'+ c.id.toString() +'']" class="btn btn-xs btn-dark text-white rounded-circle lift opacity-dot-7 me-1 p-2 d-inline-flex justify-content-center align-items-center w-36 h-36">
                             
                               <i class="far fa-eye"></i>
                            </a>
                            <input type="hidden" #course_id value="{{c.id}}">
                            <button  class="btn btn-xs btn-dark text-white rounded-circle lift opacity-dot-7 p-2 d-inline-flex justify-content-center align-items-center w-36 h-36">
                           
                                <i (click)="courseId(course_id.value)" class="far fa-heart"></i>
                            </button>
                        </div>

                        <a [routerLink]="['/course-details/'+ c.id.toString() +'']" class="card-img sk-thumbnail d-block">
                            <img class="rounded shadow-light-lg" src="{{apiURL}}/{{c.bannerImg}}" alt="...">
                        </a>

                        <span class="badge sk-fade-bottom badge-lg badge-primary badge-pill badge-float bottom-0 left-0 mb-4 ms-4">
                            <span class="text-white text-uppercase fw-bold font-size-xs">BEST SELLER</span>
                        </span>
                    </div>

                    
                    <div class="card-footer px-2 pb-2 mb-1 pt-4 position-relative">
                        <a  class="d-block">
                            <div class="avatar sk-fade-right avatar-xl badge-float position-absolute top-0 right-0 mt-n6 me-5 rounded-circle shadow border border-white border-w-lg">
                                <img src="{{apiURL}}/{{c.authorPhoto}}" alt="..." class="avatar-img rounded-circle">
                            </div>
                        </a>

                       
                        <a [routerLink]="['/course-details/'+ c.id.toString() +'']"><span class="mb-1 d-inline-block text-gray-800">{{c.categoryName}}</span></a>

                  
                        <div class="position-relative">
                            <a [routerLink]="['/course-details/'+ c.id.toString() +'']" class="d-block stretched-link"><h4 class="line-clamp-2 h-md-48 h-lg-58 me-md-6 me-lg-10 me-xl-4 mb-2">{{c.name}}</h4></a>

                            <div class="d-lg-flex align-items-end flex-wrap mb-n1">
                                <div class="star-rating mb-2 mb-lg-0 me-lg-3">
                                    <div class="rating" [style.width.%]="(c.star/5)*100"></div>
                                </div>

                                <div class="font-size-sm">
                                    <span>{{c.star}} ({{c.review}}+ reviews)</span>
                                </div>
                            </div>

                            <div class="row mx-n2 align-items-end">
                                <div class="col px-2">
                                    <ul class="nav mx-n3">
                                        <li class="nav-item px-3">
                                            <div class="d-flex align-items-center">
                                                <div class="me-2 d-flex icon-uxs text-secondary">
                                                   
                                                   <i class="fas fa-book-reader"></i>
                                                </div>
                                                <div class="font-size-sm">{{c.lessonsCount}} lessons</div>
                                            </div>
                                        </li>
                                        <li class="nav-item px-3">
                                            <div class="d-flex align-items-center">
                                                <div class="me-2 d-flex icon-uxs text-secondary">
                                                 
                                                   <i class="far fa-clock"></i>
                                                </div>
                                                 
                                                <div class="font-size-sm">{{c.total_Duration | timeConvertor}}</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-auto px-2 text-right">
                                  
                                        <del class="font-size-sm">₹ {{c.actual_Price}}</del>
                                        <ins class="h4 mb-0 d-block mb-lg-n1">₹{{c.offer_Price}}</ins>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>      
            </ng-container>      
        </div>

        <div id="loadcollapseExample" class="collapse row row-cols-md-2 row-cols-xl-3 row-cols-lg-4 row-cols-lg-3 mb-6 mb-xl-3">
            <div class="col-md pb-4 pb-md-7" data-aos="fade-in">
              
                <div class="card border shadow p-2 lift sk-fade">
                  
                    <div class="card-zoom position-relative">
                        <div class="badge-float sk-fade-top top-0 right-0 mt-4 me-4">
                            <a href="course-single.html" class="btn btn-xs btn-dark text-white rounded-circle lift opacity-dot-7 me-1 p-2 d-inline-flex justify-content-center align-items-center w-36 h-36">
                               
                               <i class="far fa-eye"></i>

                            </a>
                            <a href="course-single.html" class="btn btn-xs btn-dark text-white rounded-circle lift opacity-dot-7 p-2 d-inline-flex justify-content-center align-items-center w-36 h-36">
                               
                                <i class="far fa-heart"></i>

                            </a>
                        </div>

                        <a href="course-single.html" class="card-img sk-thumbnail d-block">
                            <img class="rounded shadow-light-lg" src="assets/img/products/product-1.jpg" alt="...">
                        </a>

                        <span class="badge sk-fade-bottom badge-lg badge-primary badge-pill badge-float bottom-0 left-0 mb-4 ms-4">
                            <span class="text-white text-uppercase fw-bold font-size-xs">BEST SELLER</span>
                        </span>
                    </div>

                   
                </div>
            </div>            
        </div>
       
    </div> -->

    <div class="bubbles_wrap">
        <div class="bubble x1"></div>
        <div class="bubble x2"></div>
        <div class="bubble x3"></div>
        <div class="bubble x4"></div>
        <div class="bubble x5"></div>
        <div class="bubble x6"></div>
        <div class="bubble x7"></div>
        <div class="bubble x8"></div>
        <div class="bubble x9"></div>
        <div class="bubble x10"></div>
    </div>
    <!-- Scroll To Top Start -->
    <a href="#main-banner" class="scroll-top" id="scroll-to-top">
        <i class="fa fa-arrow-up" aria-hidden="true"></i>
    </a>
