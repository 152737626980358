<div class="rts-bread-crumbarea-1 rts-section-gap bg_image">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="breadcrumb-main-wrapper">
                    <h1 class="title">FAQ</h1>
                   
                    <div class="pagination-wrapper">
                        <a routerLink="/index">Home</a>
                        <i class="fa-regular fa-chevron-right"></i>
                        <a class="active" href="contact.html">FAQ</a>
                    </div>
                  
                </div>
            </div>
        </div>
    </div>
</div>

<section>
    <div class="container rts-section-gap">
        <div class="row">
            <div class="col-12 gq">
                <h2>General Questions</h2>
            </div>
            <div class="col-12 col-lg-8">
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#first" aria-expanded="true" aria-controls="first">
                            What is the course content?
                        </button>
                    </h2>
                    <div id="first" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                        <p>The course covers Mathematics topics typically included in the Grade IX and Grade X curriculum across all educational boards.
                        </p>
                        </div>
                    </div>
                    </div>
                    <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#second" aria-expanded="false" aria-controls="second">
                            Who is this course best suited for?
                        </button>
                    </h2>
                    <div id="second" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p>This course is ideally suited for students who have just entered Grade X and are serious about preparing for competitive entrance exams like JEE, BITSAT, IAT, or MHT CET. It's also a great fit for those who want to strengthen their mathematical foundation and develop the advanced problem-solving skills necessary to excel in these exams.
                            </p>
                        </div>
                    </div>
                    </div>
                    <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#third" aria-expanded="false" aria-controls="third">
                            What is the medium of instruction?
                        </button>
                    </h2>
                    <div id="third" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p>The course is taught in English.</p>
                        </div>
                    </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#four" aria-expanded="false" aria-controls="four">
                            What is the level of difficulty?
                        </button>
                        </h2>
                        <div id="four" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p>The course difficulty is higher than the standard school curriculum, introducing advanced skills necessary for success in competitive exams.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#five" aria-expanded="false" aria-controls="five">
                            Can the sessions be downloaded?
                        </button>
                        </h2>
                        <div id="five" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p> No, the sessions cannot be downloaded but can be viewed multiple times until the subscription expires.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#six" aria-expanded="false" aria-controls="six">
                            Are there any prerequisites for enrolling in this course?
                        </button>
                        </h2>
                        <div id="six" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p>It is recommended that students have a strong understanding of Grade IX Mathematics before starting this course.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#seven" aria-expanded="false" aria-controls="seven">
                            How long is the course duration?
                        </button>
                        </h2>
                        <div id="seven" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p> The course duration is typically [duration], depending on the student's pace and study schedule.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#eight" aria-expanded="false" aria-controls="eight">
                            Will there be any practice tests included in the course?
                        </button>
                        </h2>
                        <div id="eight" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p>Yes, the course includes practice tests to help students assess their understanding and readiness for competitive exams.
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
            
            </div>
            <div class="col-12 col-lg-4 ">
                <div class="que-section sticky-content mb-5">
                    <h4>Have Any More Questions?</h4>
                    <p>If you have any questions or concerns, please don’t hesitate to contact us. We shall get back to you within 24-48 hrs
                    </p>
                    <div>
                        <input type="checkbox" id="exampleCheck1">
                        <label for="exampleCheck1">We are always happy to help!</label>
                    </div>
                    <div>
                        <button class="rts-btn btn-primary mt-3"  routerLink="/contact-us"><a style="color: #ffff;">Contact Us</a></button>
                    </div>
                </div>
            </div>
        </div>

    </div>

 </section>