<!-- PAGE TITLE
    ================================================== -->
<!-- <header class="py-4 py-md-4 main-banner-in">
        <div class="container-fluid text-center py-xl-2">
            <h2 class="fw-semi-bold mb-0">Register</h2>
        </div>
    </header> -->


<!-- LOGIN
    ================================================== -->
<!-- <div class="container-fluid mb-8">
        <div class="row gx-0">
            <div class="col-md-7 col-xl-5 mx-auto">
             
                <form [formGroup]="form" (ngSubmit)="onSubmit()" class="mb-5 bg-white border border-orange p-5">
                    
                    <div class="form-group mb-5">
                        <label for="username">
                            Username
                        </label>
                        <input type="hidden" formControlName="appname" value="Videostream" id="appname">
                        <input type="text" formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" class="form-control form-control-sm" id="username"
                            placeholder="Username">
                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">Username is required</div>
                            </div>
                    </div>

                    
                     <div class="form-group mb-5">
                        <label for="first_name">
                            First Name
                        </label>
                        <input type="text" formControlName="name"  [ngClass]="{ 'is-invalid': submitted && f.name.errors }" class="form-control form-control-sm" id="name"
                            placeholder="First Name">
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">First Name is required</div>
                            </div>
                    </div>

                    
                     <div class="form-group mb-5">
                        <label for="last_name">
                            Last Name
                        </label>
                        <input type="text" formControlName="surname" [ngClass]="{ 'is-invalid': submitted && f.surname.errors }" class="form-control form-control-sm" id="surname"
                            placeholder="Last Name">
                            <div *ngIf="submitted && f.surname.errors" class="invalid-feedback">
                                <div *ngIf="f.surname.errors.required">Last Name is required</div>
                            </div>
                    </div>

                   
                    <div class="form-group mb-5">
                        <label for="email">
                            Email
                        </label>
                        <input type="text" formControlName="emailAddress"  [ngClass]="{ 'is-invalid': submitted && f.emailAddress.errors }" class="form-control form-control-sm" id="emailAddress"
                            placeholder="Email" email="true">
                            <div *ngIf="submitted && f.emailAddress.errors" class="invalid-feedback">
                                <div *ngIf="f.emailAddress.errors.required">Email is required</div>
                                <div *ngIf="f.emailAddress.errors?.email">Email must be a valid email address</div>
                              </div>
                    </div>

                   
                    <div class="form-group mb-5">
                        <label for="password">
                            Password
                        </label>
                        <input type="password" formControlName="password" id="password" class="form-control form-control-sm" 
                        placeholder="Password"  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <span id="toggle_pwdregister" style="cursor: pointer;" class="fa fa-fw fa-eye field-icon"></span>
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            
                            <div *ngIf="f.password.errors.required">Password is required</div>
                            <div *ngIf="f.password.errors.pattern">
                                Password should be atleast 6 characters long
                                and should contain one number,one capital letter and one special
                                character.
                           </div> 
                        </div>
                    </div>
                    
                    <button class="btn btn-block btn-primary btn-sm" type="submit">
                        Sign up
                    </button>
                </form>

               
                <p class="mb-0 font-size-sm text-center">
                    Already have an account? <a class="text-underline forgot" [routerLink]="['/login']">Log In</a>
                </p>
                <p class="mb-0 font-size-sm text-center">
                    Register As a IBC <a class="text-underline forgot" [routerLink]="['/ibc/register']">Register</a>
                  </p>
            </div>
        </div>
    </div> -->

<!-- FOOTER
    ================================================== -->


<div class="login-registration-wrapper">
    <div class="container">
        <div class="row g-0">
            <div class="col-lg-6">
                <div class="login-page-form-area">
                    <h4 class="title">Sign Up to Your Account👋</h4>
                    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                        <div class="single-input-wrapper">
                            <div class="single-input-wrapper">
                                <label for="name">Full Name*</label>
                                <input id="name" type="text" minlength="3" placeholder="Enter Your Full Name" formControlName="name">
                                <div  class="text-danger"
                                    *ngIf="registerForm.controls['name'].invalid && registerForm.controls['name'].touched">
                                    Full Name is required
                                </div>
                            </div>
                            <!-- <div class="single-input-wrapper">
                                <label for="surname">Surname*</label>
                                <input id="surname" type="text" placeholder="Enter Your Surname"
                                    formControlName="surname">
                                <div
                                    *ngIf="registerForm.controls['surname'].invalid && registerForm.controls['surname'].touched">
                                    Surname is required
                                </div>
                            </div> -->
                        </div>

                        <div class="half-input-wrapper">
                            <div class="single-input-wrapper">
                                <label for="username">Mobile Number</label>
                                <input id="username" type="text" maxlength="10" minlength="10" placeholder="Enter Mobile Username"
                                    formControlName="username">
                                <div   class="text-danger"
                                    *ngIf="registerForm.controls['username'].invalid && registerForm.controls['username'].touched">
                                    Mobile Number is required
                                </div>
                            </div>
                            <div class="single-input-wrapper">
                                <label for="email">Email*</label>
                                <input id="email" type="text" placeholder="Enter Your Email"
                                    formControlName="emailAddress">
                                <!-- <div
                                    *ngIf="registerForm.controls['emailAddress'].invalid && registerForm.controls['emailAddress'].touched">
                                    Email is required
                                </div> -->
                                <div *ngIf="registerForm.get('emailAddress')?.invalid && registerForm.get('emailAddress')?.touched">
                                    <span class="text-danger" *ngIf="registerForm.get('emailAddress')?.errors?.required">Email is
                                        required.</span>
                                    <span class="text-danger" *ngIf="registerForm.get('emailAddress')?.errors?.pattern">Email must
                                        contain '&#64;' and end with '.com'.</span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="half-input-wrapper">
                                <div class="single-input-wrapper">
                                    <label for="passwords">Re Password</label>
                                    <input id="passwords" type="password" placeholder="Re Password" required>
                                </div>
                            </div> -->
                        <div class="single-input-wrapper input-icon-wrapper">
                            <label for="password">Your Password</label>
                            <input id="password" [type]="checkpassword ? 'text':'password'" placeholder="Password" formControlName="password">
                            <i class="fa fa-look" [ngClass]="checkpassword ? 'fa-eye-slash' : 'fa-eye'" aria-hidden="true"  (click)="displayPassWord()"></i>
                            <div  class="text-danger"
                                *ngIf="registerForm.controls['password'].invalid && registerForm.controls['password'].touched">
                                Your Password is required
                            </div>
                        </div>
                        <div class="single-checkbox-filter">
                            <div class="check-box">
                                <input type="checkbox" id="type-1" (change)="onCheckboxChange($event)">
                                <label for="type-1">Accept the Terms and Privacy Policy</label><br>
                            </div>
                        </div>
                        <button type="submit" class="rts-btn btn-primary">Submit</button>
                        <p>Don't Have an account? <a routerLink="/login">Login</a></p>
                    </form>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="contact-thumbnail-login-p mt--100">
                    <img src="assets/images/banner/login-bg.png" width="600" height="495" alt="login-form">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="p--100"></div>

<div id="side-bar" class="side-bar header-two">
    <button class="close-icon-menu"><i class="far fa-times"></i></button>

    <div class="inner-main-wrapper-desk">
        <div class="thumbnail">
            <img src="assets/images/banner/04.jpg" alt="elevate">
        </div>
        <div class="inner-content">
            <h4 class="title">We Build Building and Great Constructive Homes.</h4>
            <p class="disc">
                We successfully cope with tasks of varying complexity, provide long-term guarantees and regularly master
                new technologies.
            </p>
            <div class="footer">
                <h4 class="title">Got a project in mind?</h4>
                <a href="contact.html" class="rts-btn btn-primary">Let's talk</a>
            </div>
        </div>
    </div>

    <div class="mobile-menu-main">
        <nav class="nav-main mainmenu-nav mt--30">
            <ul class="mainmenu metismenu" id="mobile-menu-active">
                <li>
                    <a href="index.html" class="main">Home</a>
                </li>
                <li>
                    <a href="about.html" class="main">About Us</a>
                </li>
                <li>
                    <a href="course-one.html" class="main">Course</a>

                </li>
                <li>
                    <a href="contact.html" class="main">Contact Us</a>

                </li>
                <li>
                    <a href="FAQ.html" class="main">FAQ</a>

                </li>
            </ul>
        </nav>

        <div class="buttons-area">
            <a href="log-in.html" class="rts-btn btn-border">Log In</a>
            <a href="registration.html" class="rts-btn btn-primary">Sign Up</a>
        </div>

        <div class="rts-social-style-one pl--20 mt--50">
            <ul>
                <li>
                    <a href="#">
                        <i class="fa-brands fa-facebook-f"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa-brands fa-twitter"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa-brands fa-youtube"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa-brands fa-linkedin-in"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="bubbles_wrap">
    <div class="bubble x1"></div>
    <div class="bubble x2"></div>
    <div class="bubble x3"></div>
    <div class="bubble x4"></div>
    <div class="bubble x5"></div>
    <div class="bubble x6"></div>
    <div class="bubble x7"></div>
    <div class="bubble x8"></div>
    <div class="bubble x9"></div>
    <div class="bubble x10"></div>
</div>
<!-- Scroll To Top Start -->
<a href="#main-banner" class="scroll-top" id="scroll-to-top">
    <i class="fa fa-arrow-up" aria-hidden="true"></i>
</a>
<!-- Scroll To Top End-->
<!-- JAVASCRIPT
    ================================================== -->