   
    <!-- PAGE TITLE
    ================================================== -->
    <header class="py-2 py-md-4 overlay overlay-primary overlay-70 jarallax" data-jarallax
        data-speed=".8" style="background-image: url(assets/img/covers/cover-19.jpg);">
        <div class="container-fluid text-center ">
            <h2 class="fw-semi-bold mb-0 text-white py-1 py-md-3">WHY US</h2>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-scroll justify-content-center py-1 ">
                    <li class="breadcrumb-item">
                        <a class="text-white" href="#">
                            Home
                        </a>
                    </li>
                    <li class="breadcrumb-item text-white active" aria-current="page">
                        Why us
                    </li>
                </ol>
            </nav>
        </div>
        <!-- Img -->

    </header>

    <!-- FEATURE GENERAL
    ================================================== -->
    <section class="py-6 py-md-8">
        <div class="container main-banner-in1">
            <div class="text-center text-capitalize" data-aos="fade-up">
                <h1 class="mb-1" data-aos="fade-top">आम्ही सर्वोत्तम आहोत हे जाणून घ्या</h1>
                <p class="font-size-lg mb-md-7 mb-4"></p>
            </div>

            <ul class="nav mx-md-n5 justify-content-md-center text-capitalize mb-5 mb-md-9">
                <li class="nav-item px-md-5 mb-4 mb-md-0">
                    <a class="nav-link fw-medium p-0" data-aos="fade-up"><span class="font-size-xxl me-3">01</span><span class="font-size-lg">आम्ही उद्योजकतेच्या तत्त्वांबद्दल सर्व गोष्टींवर काम करतो.</span></a>
                </li>
                <li class="nav-item px-md-5 mb-4 mb-md-0">
                    <a class="nav-link fw-medium p-0" data-aos="fade-up"><span class="font-size-xxl me-3">02</span><span class="font-size-lg">वेगवेगळ्या इंडस्ट्रीज एक्सपर्टस कडून संपूर्ण व्यवसाय मार्गदर्शन.</span></a>
                </li>

                <!-- <li class="nav-item px-md-5 mb-4 mb-md-0">
                    <a  class="nav-link fw-medium p-0 " data-aos="fade-up"><span class="font-size-xxl me-3">03</span><span class="font-size-lg">Huge Product Procurement Services From Maharashtra.</span></a>
                </li> -->
            </ul>

            <div class="row align-items-center1">
                <div class="col-lg-6 order-lg-2 mb-6 mb-lg-0  text-center">
                    <!-- Image -->
                    <img src="assets/img/photos/photo-1.jpg" class="img-fluid rounded-lg w-100px" alt="..." data-aos="fade-left">
                </div>

                <div class="col-lg-6 order-lg-1" data-aos="fade-right">
                    <!-- <h3 class="font-size-xxl text-black mb-4">The Prodigious eLearning Courses for you</h3> -->

                    <p class="mb-5 me-lg-6 line-height-lg"></p>

                    <ul class="mb-6 ps-4">
                        <li class="mb-5">मराठी माणसाने मराठी माणसासाठी सुरू केलेली चळवळ. </li>
                        <li class="mb-5">पूर्ण महाराष्ट्र बिझनेस नेटवर्क चा भाग होण्याची संधी.</li>                       
                        <li class="mb-5">संपूर्ण व्यवसाय सपोर्ट आणि मार्गदर्शन.</li>
                        <li class="mb-5">विविध व्यवसाय धोरणे आणि फ्रेमवर्क, जे भारतीय SME ला  
                            यशस्वी व्यवसाय करण्यास मदत करतील.</li>
                        <li class="mb-5">डॉ. ओंकार हरी माळी हे एचआर, मार्केटिंग, सेल्स, टेक्नॉलॉजी इ. क्षेत्रातील तुमच्या सर्व व्यावसायिक समस्यांसाठी वन स्टॉप सोल्युशन आहे. </li>
                    </ul>

                 
                </div>
            </div>
        </div>

        <!-- Shape -->
       
    </section>

  
    
    <!-- COUNTUP
    ================================================== -->
    <section class="py-5 py-md-8 text-center bg-teal1 opacity opacity-70" >
        <div class="container-fluid">
            <div class="text-center mb-md-10 mb-6 text-capitalize" data-aos="fade-up">
                <h1 class= "text-white mb-1">Udyamii Numbers</h1>
                <p class="text-white">Discover your perfect program in our courses.</p>
            </div>

            <div class="row text-center w-xl-80 mx-auto justify-content-between">
                <div class="col-md-3 col-lg-auto mb-6 mb-md-0" data-aos="fade-up">
                    <div class="w-120p h-120p rounded-circle border-dashed-2 d-inline-flex place-flex-center text-white mb-5">
                        <!-- Icon -->
                        <i class="fas fa-gem fa-3x"></i>

                    </div>

                    <div class="h1 mb-0 text-white"><span data-toggle="countup" data-from="0" data-to="757" data-aos="" data-aos-id="countup:in"></span></div>
                    <span class="h5 font-nunito text-white mb-0">Creative Events</span>
                </div>

                <div class="col-md-3 col-lg-auto mb-6 mb-md-0" data-aos="fade-up">
                    <div class="w-120p h-120p rounded-circle border-dashed-2 d-inline-flex place-flex-center text-white mb-5">
                        <!-- Icon -->
                        <i class="fas fa-photo-video fa-3x"></i>

                    </div>
                    <div class="h1  mb-0 text-white"><span data-toggle="countup" data-from="0" data-to="853" data-aos="" data-aos-id="countup:in"></span></div>
                    <span class="h5 font-nunito text-white mb-0">Skilled Tutors</span>
                </div>

                <div class="col-md-3 col-lg-auto mb-6 mb-md-0" data-aos="fade-up">
                    <div class="w-120p h-120p rounded-circle border-dashed-2 d-inline-flex place-flex-center text-white mb-5">
                        <!-- Icon -->
                        <i class="fas fa-user-graduate fa-3x"></i>

                    </div>

                    <div class="h1  mb-0 text-white"><span data-toggle="countup" data-from="0" data-to="200" data-aos="" data-aos-id="countup:in"></span>k+</div>
                    <span class="h5 font-nunito text-white mb-0">Total Student</span>
                </div>

                <div class="col-md-3 col-lg-auto mb-6 mb-md-0" data-aos="fade-up">
                    <div class="w-120p h-120p rounded-circle border-dashed-2 d-inline-flex place-flex-center text-white mb-5">
                        <!-- Icon -->
                        <i class="fas fa-chalkboard-teacher fa-3x"></i>

                    </div>

                    <div class="h1 mb-0 text-white"><span data-toggle="countup" data-from="0" data-to="53" data-aos="" data-aos-id="countup:in"></span>k+</div>
                    <span class="h5 font-nunito text-white mb-0">People Worldwide</span>
                </div>
            </div>
        </div>
    </section>

 
     <!-- EVENTS
    ================================================== -->
  
   
    
   
    <div class="bubbles_wrap">
        <div class="bubble x1"></div>
        <div class="bubble x2"></div>
        <div class="bubble x3"></div>
        <div class="bubble x4"></div>
        <div class="bubble x5"></div>
        <div class="bubble x6"></div>
        <div class="bubble x7"></div>
        <div class="bubble x8"></div>
        <div class="bubble x9"></div>
        <div class="bubble x10"></div>
    </div>
    <!-- Scroll To Top Start -->
    <a href="#main-banner" class="scroll-top" id="scroll-to-top">
        <i class="fa fa-arrow-up" aria-hidden="true"></i>
    </a>